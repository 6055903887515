import api from 'api';
import classNames from 'classnames';
import Badge from 'components/Badge';
import SingleCountry from 'components/CountrySelect/components/SingleCountry';
import Loader from 'components/Loader';
import ProcessingBadge from 'components/ProcessingBadge';
import TagsContainer from 'components/TagButtonContainer';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import useSelect from 'hooks/useSelect';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import { selectCountries } from 'store/selectors/global.selector';
import utilities from 'utilities';
import authEnums from 'utilities/enums/authEnums';
import routesEnum from 'utilities/enums/routesEnum';
import setClassSuffix from 'utilities/services/ClassManager';
import DateManager from 'utilities/services/DateManager';
import BatchAddTagsModal from '../components/BatchAddTagsModal';
import BatchAssignDropdown from '../components/BatchAssignDropdown';
import BatchDeleteButton from '../components/BatchDeleteButton';
import BatchRiskDropdown from '../components/BatchRiskDropdown';
import BatchStatusDropdown from '../components/BatchStatusDropdown';

const EntityTableRow = ({
  rowInfo: {
    countryId,
    type,
    status,
    lastUpdated,
    risk,
    entityId,
    assignedUserId,
    issues,
    name,
    date,
    tags,
    checked,
    nextKYCReview,
  },
  className,
  onRemove,
  updatingRows,
  setUpdatingRows,
  onBatchUpdate,
  onCheckboxClick,
}) => {
  const setSuffix = setClassSuffix(className);
  const statusClasses = classNames({
    [setSuffix('__column__status')]: true,
    [setSuffix('__column__status--pending')]: status === 'Pending',
    [setSuffix('__column__status--rejected')]: status === 'Rejected',
    [setSuffix('__column__status--approved')]: status === 'Approved',
  });

  const countries = useSelect(selectCountries);
  const {
    isOpen: isAttachTagsModalOpen,
    close: closeTagsAttachmentModal,
    open: openTagsAttachmentModal,
  } = useModalHandler();

  const countryToShow = countries.find(country => country.id === countryId);
  const entityProfileLink = useMemo(() => {
    return utilities.routeParams(
      type === 'Legal Entity' ? routesEnum.kyc.LEGAL_ENTITY : routesEnum.kyc.NATURAL_PERSON,
      {
        id: entityId,
      },
    );
  }, [entityId, type]);

  const platformAccessByLicence = useSelect(selectUserAccessByLicense);
  const hasRiskLevelAccess = useMemo(() => {
    return platformAccessByLicence.some(el => el === authEnums.ACCESS_BY_LICENCE.RISK_LEVELS);
  }, [platformAccessByLicence]);

  const handleCheckBoxClick = useCallback(() => {
    onCheckboxClick(entityId);
  }, [entityId, onCheckboxClick]);

  return (
    <tr key={entityId} className={`${className}__row`}>
      {!!updatingRows.length && updatingRows.includes(entityId) && <Loader />}
      <td className={setSuffix('__column__checkbox')}>
        <input data-lpignore="true" type="checkbox" checked={checked} readOnly onChange={handleCheckBoxClick} />
      </td>
      <td className={setSuffix('__column__lastUpdated')}>
        <NavLink to={entityProfileLink}>{lastUpdated ? DateManager.getFullDateLocal(lastUpdated) : ''}</NavLink>
      </td>

      <td className={setSuffix('__column__type')}>
        <NavLink to={entityProfileLink}>{type}</NavLink>
      </td>
      <td className={setSuffix('__column__name')}>
        <NavLink to={entityProfileLink}>{name}</NavLink>
      </td>
      <td className={setSuffix('__column__countryName')}>
        <NavLink to={entityProfileLink}>
          {countryToShow ? <SingleCountry alpha2Code={countryToShow.alpha2Code} name={countryToShow.name} /> : 'N/A'}
        </NavLink>
      </td>
      {hasRiskLevelAccess && (
        <td className={setSuffix('__column__risk')}>
          <BatchRiskDropdown
            assignedUserRisk={risk}
            entityId={entityId}
            onBatchUpdate={onBatchUpdate}
            setUpdating={setUpdatingRows}
            className="ickyc-batch-actions-risk"
          />
        </td>
      )}
      <td className={setSuffix('__column__assignedUserName')}>
        <BatchAssignDropdown
          assignedUserId={assignedUserId}
          entityId={entityId}
          onBatchUpdate={onBatchUpdate}
          setUpdating={setUpdatingRows}
          className="ickyc-batch-actions-assign"
        />
      </td>
      <td className={statusClasses}>
        <BatchStatusDropdown
          assignedUserStatus={status}
          entityId={entityId}
          onBatchUpdate={onBatchUpdate}
          setUpdating={setUpdatingRows}
        />
      </td>
      <td className={setSuffix('__column__nextKYCReview')}>
        <NavLink to={entityProfileLink}>{nextKYCReview ? DateManager.monDayYear(nextKYCReview) : 'N/A'}</NavLink>
      </td>
      <td className={setSuffix('__column__issues')}>
        <div>{issues === null ? <ProcessingBadge /> : <Badge content={issues} red />}</div>
      </td>
      <td className={setSuffix('__column__tags')}>
        <Tooltip
          placement="top"
          trigger={
            <div>
              {isAttachTagsModalOpen && (
                <BatchAddTagsModal
                  initialAttachments={tags}
                  hideModal={closeTagsAttachmentModal}
                  setNewlyAdded={() => {}}
                  entityId={entityId}
                  onBatchUpdate={onBatchUpdate}
                />
              )}
              <TagsContainer
                showMoreAfterNumberOfTags={2}
                withoutRemove
                options={tags?.map(el => ({ name: el, label: el, id: el }))}
                onRemove={() => {}}
                onAdd={openTagsAttachmentModal}
                onClick={entityProfileLink}
              />
            </div>
          }
          content={<span>Attach a Tag to this Entity</span>}
        />
      </td>
      <td className={setSuffix('__column__date')}>
        <NavLink to={entityProfileLink}>{DateManager.monDayYearLocal(date)}</NavLink>
      </td>
      <td className={setSuffix('__column__remove')}>
        <BatchDeleteButton
          entityId={entityId}
          onRemove={onRemove}
          apiRequest={api.kyc.entityManagement.batchDeleteEntities}
        />
      </td>
    </tr>
  );
};

EntityTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    countryId: PropTypes.number,
    date: PropTypes.string,
    entityId: PropTypes.string.isRequired,
    assignedUserId: PropTypes.string,
    name: PropTypes.string,
    risk: PropTypes.number.isRequired,
    status: PropTypes.string,
    issues: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string,
    tags: PropTypes.array,
    checked: PropTypes.bool,
  }),
  className: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onBatchUpdate: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  updatingRows: PropTypes.array,
  setUpdatingRows: PropTypes.func,
};

EntityTableRow.defaultProps = {
  className: '',
  rowInfo: {
    assignedUserId: null,
    countryId: undefined,
    date: null,
    lastUpdated: null,
    status: '',
    name: '',
    tags: [],
  },
  updatingRows: [],
  setUpdatingRows: () => {},
  onCheckboxClick: () => {},
  onBatchUpdate: () => {},
};
export default EntityTableRow;
