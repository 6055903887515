import { Lock } from '@material-ui/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import Tooltip from 'components/Tooltip';
import useSelect from 'hooks/useSelect';
import useSupportingPackageOptions from 'hooks/useSupportingPackageOptions';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { selectDocumentTypes } from 'store/selectors/global.selector';
import { DragContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleOption from '../ModuleOption';
import ModuleOptions from '../ModuleOptions';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import SingleSupportingPackage from '../SingleSupportingPackage';
import './styles.scss';

const isNested = true;

const SupportingDocumentsModule = ({ index, indexToShow, isLEModules, isDropAllowed }) => {
  const className = 'ickyc-table';
  const documentPackages = useSelect(selectDocumentTypes);
  const { documentPackageOptions, clientPackages } = useSupportingPackageOptions(documentPackages);
  const { clientId } = useParams();
  const [dragList, setDragList] = useState([]);
  const [dropCallback, setDropCallback] = useState();

  const { setIsChildDragged, isChildDragged } = useContext(DragContext);

  const {
    mutators: { push, remove },
    change,
  } = useForm();

  const suppDocsNamePreffix = `modules[${index}].packagesDuringOnboarding`;
  const {
    input: { value: suppDocs },
    meta: { error, touched },
  } = useField(suppDocsNamePreffix);

  const {
    input: { value: isEnabled },
  } = useField(`modules[${index}].isEnabled`);

  const removeRow = useCallback(
    ind => {
      remove(suppDocsNamePreffix, ind);
    },
    [remove, suppDocsNamePreffix],
  );

  const addRow = useCallback(() => {
    push(suppDocsNamePreffix, { id: null });
  }, [push, suppDocsNamePreffix]);

  const {
    input: {
      value: { entityDetailsRequireSupportingDocument, addressDetailsRequireSupportingDocument },
    },
  } = useField(`modules[2].kycRequests`);

  useEffect(() => {
    setDragList(suppDocs);
  }, [suppDocs]);

  const dropCallbackProp = useCallback(
    sortedDocs => {
      change(suppDocsNamePreffix, sortedDocs);
      setDragList(sortedDocs);
    },
    [change, suppDocsNamePreffix],
  );

  const suppDocsArrayError = useMemo(() => touched && isEnabled && error, [error, touched, isEnabled]);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed && !isChildDragged}
      className={className}
      withIndexColumn
      index={indexToShow}
      isToggled={isEnabled}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Supporting Document Packages</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            {!isLEModules ? (
              <Field
                preview
                name={`modules[${index}].isEnabled`}
                component={ToggleField}
                showCustomText
                toggleOnText="Enabled"
              />
            ) : (
              <Tooltip
                trigger={
                  <div disabled={!entityDetailsRequireSupportingDocument && !addressDetailsRequireSupportingDocument}>
                    <Field
                      preview
                      name={`modules[${index}].isEnabled`}
                      component={ToggleField}
                      disabled={
                        (entityDetailsRequireSupportingDocument || addressDetailsRequireSupportingDocument) && isEnabled
                      }
                      showCustomText
                      toggleOnText="Enabled"
                    />
                  </div>
                }
                content={
                  <span>
                    The KYC Request Section in the Add Legal Entity module requires documents. It is essential that this
                    module remains activated.
                  </span>
                }
              />
            )}
          </td>
        </>
      }
    >
      <DragContext.Provider
        value={{ dragList, setDragList, dropCallback, setDropCallback, setIsChildDragged, isNested }}
      >
        <ModuleSettings>
          <ModuleTitleSettings index={index} withDescription />
          <ModuleOptions error={suppDocsArrayError} title="Supporting Packages">
            <ModuleOption>
              <div className="ickyc-table__controls">
                <PrimaryButton onClick={addRow}>+ Add Supporting Document Package</PrimaryButton>
              </div>
              {!!suppDocs?.length && (
                <FieldArray
                  name={suppDocsNamePreffix}
                  validate={
                    isEnabled
                      ? validators.required('Please note that at least one Supporting Document Package is required')
                      : undefined
                  }
                >
                  {({ fields }) => {
                    return fields.map((name, ind) => (
                      <SingleSupportingPackage
                        dropCallback={dropCallbackProp}
                        isDraggable
                        lowerBound={ind === 0}
                        upperBound={ind === (suppDocs?.length || 0) - 1}
                        index={ind}
                        handleOnRemove={removeRow}
                        name={name}
                        key={name}
                        documentPackageOptions={documentPackageOptions}
                        documentPackages={clientPackages || documentPackages}
                      />
                    ));
                  }}
                </FieldArray>
              )}
            </ModuleOption>
          </ModuleOptions>
        </ModuleSettings>
      </DragContext.Provider>
    </CollapsibleTableRow>
  );
};
SupportingDocumentsModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
  isLEModules: PropTypes.bool,
  isDropAllowed: PropTypes.bool,
};
SupportingDocumentsModule.defaultProps = {
  isLEModules: false,
  isDropAllowed: false,
};
export default SupportingDocumentsModule;
