import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';

/**
 * Custom range Slider
 *
 * @param {string} label
 * @param {number} min
 * @param {number} max
 * @param {number} initialValue - Initial slide value
 * @param {Function} callback - Handles slider value change
 *
 */
const RangeSlider = ({ label, min, max, step, initialValue, callback, withFrame, disabled }) => {
  const [sliderValue, setSliderValue] = useState(0);
  const inputRef = useRef(null);

  const handleSlider = () => {
    if (callback) {
      callback(+inputRef.current.value);
    }
  };

  useEffect(() => {
    setSliderValue(initialValue);
  }, [setSliderValue, initialValue]);

  const slideLinesClass = classNames({
    'ickyc-range-slider__line': true,
    'ickyc-range-slider__line--framed': withFrame,
  });

  const classes = classNames({
    'ickyc-range-slider': true,
    'ickyc-range-slider--disabled': disabled,
  });

  return (
    <div className={classes}>
      <label>{label}</label>
      <div className="ickyc-range-slider__wrapper">
        <span className={slideLinesClass} />

        <input
          ref={inputRef}
          type="range"
          data-lpignore="true"
          min={min}
          max={max}
          step={step}
          onChange={handleSlider}
          value={sliderValue}
          disabled={disabled}
        />
        <div
          className="ickyc-range-slider__text"
          style={{
            left: `${sliderValue}%`,
            transform: `translateX(${0 - sliderValue}%)`,
          }}
        >
          {sliderValue}
        </div>
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  initialValue: PropTypes.number,
  callback: PropTypes.func,
  withFrame: PropTypes.bool,
  disabled: PropTypes.bool,
};

RangeSlider.defaultProps = {
  label: '',
  min: 0,
  withFrame: false,
  max: 99,
  step: 1,
  initialValue: 0,
  disabled: false,
  callback: () => {},
};
export default RangeSlider;
