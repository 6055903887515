import api from 'api';
import IComplyForm from 'components/Form/IComplyForm';
import InputField from 'components/InputField';
import Loader from 'components/Loader';
import Section from 'components/Section';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import validators from 'utilities/services/validators';

import './styles.scss';

const bundleDiscountsToNumber = values => ({
  bundleTwoDiscount: Number(values.bundleTwoDiscount) || 0,
  bundleThreeDiscount: Number(values.bundleThreeDiscount) || 0,
  bundleFourDiscount: Number(values.bundleFourDiscount) || 0,
});

const bundleDiscountsToString = values => ({
  bundleTwoDiscount: String(values?.bundleTwoDiscount),
  bundleThreeDiscount: String(values?.bundleThreeDiscount),
  bundleFourDiscount: String(values?.bundleFourDiscount),
});

const BundleDiscountsSection = () => {
  const [initialValues, setInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async values => {
    try {
      await api.internal.webProducts.setDiscounts(bundleDiscountsToNumber(values));
      toastr.success('New bundle discounts have been set');
    } catch (err) {
      toastr.error('Error occured while setting bundle discounts');
    }
  };

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.internal.webProducts.getDiscounts();
        setInitialValues(bundleDiscountsToString(data));
      } catch (err) {
        toastr.error('Error occurred while getting bundle discounts');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDiscounts();
  }, []);

  return (
    <IComplyForm className="bundle-discounts-form" onSubmit={onSubmit} initialValues={initialValues} showControls>
      <Section title="Bundle Discounts" className="bundle-discounts">
        {isLoading && <Loader />}
        <div className="bundle-discounts__bundle">
          <span>Bundle 2</span>
          <Field
            component={InputField}
            name={'bundleTwoDiscount'}
            type="number"
            suffix="%"
            validate={validators.required(' ')}
          />
        </div>
        <div className="bundle-discounts__bundle">
          <span>Bundle 3</span>
          <Field
            component={InputField}
            name={'bundleThreeDiscount'}
            type="number"
            suffix="%"
            validate={validators.required(' ')}
          />
        </div>
        <div className="bundle-discounts__bundle">
          <span>Bundle 4</span>
          <Field
            component={InputField}
            name={'bundleFourDiscount'}
            type="number"
            suffix="%"
            validate={validators.required(' ')}
          />
        </div>
      </Section>
    </IComplyForm>
  );
};

export default BundleDiscountsSection;
