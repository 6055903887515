import ArrowRight from '@material-ui/icons/ArrowRight';
import CloseButton from 'components/Buttons/CloseButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputField from 'components/InputField';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TextEditorField from 'components/TextEditorField';
import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import { DragContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import './styles.scss';

const stopPropagation = e => {
  e.stopPropagation();
};

const className = 'ickyc-table';
const baseClass = 'ickyc-supporting-documents-module';

const SingleSupportingPackage = ({
  index,
  handleOnRemove,
  dropCallback,
  isDraggable,
  name: namePreffix,
  documentPackageOptions,
  documentPackages,
}) => {
  const { setDropCallback } = useContext(DragContext);
  const { clientId } = useParams();

  const {
    input: { value: packageId },
  } = useField(`${namePreffix}.id`);
  const { change } = useForm();

  const packageRediterctPath = useMemo(() => `/administration/compliance-settings/supporting-documents/${packageId}`, [
    packageId,
  ]);

  const handleRemoveClick = useCallback(
    e => {
      e.stopPropagation();
      handleOnRemove(index);
    },
    [handleOnRemove, index],
  );

  const prefillFields = useCallback(
    packageId => {
      const selectedPackage = documentPackages?.find(({ id }) => id === packageId);

      change(`${namePreffix}.header`, selectedPackage?.header);
      change(`${namePreffix}.description`, selectedPackage?.description);
    },
    [documentPackages, change, namePreffix],
  );

  useEffect(() => {
    setDropCallback(() => dropCallback);
  }, [dropCallback]);

  return (
    <CollapsibleTableRow
      className={className}
      withIndexColumn
      isDraggable={isDraggable}
      isDropAllowed
      noComponentUnmount
      index={index + 1}
      row={
        <>
          <td className={`${className}__column__type`}>
            <Field
              name={`${namePreffix}.id`}
              component={SelectField}
              options={documentPackageOptions}
              Trigger={<SelectTrigger placeholder="Document Package" />}
              Content={<SelectFilterContent />}
              onClick={stopPropagation}
              onChange={prefillFields}
            />
          </td>
          <td className={`${className}__column__title`}>
            <Field
              name={`${namePreffix}.header`}
              component={InputField}
              validate={validators.required(' ')}
              onClick={stopPropagation}
            />
          </td>

          <td className={`${className}__column__enabled`} onClick={stopPropagation}>
            <Field
              preview
              name={`${namePreffix}.required`}
              component={ToggleField}
              showCustomText
              toggleOnText="Required"
              toggleOffText="Optional"
            />
          </td>

          <td className={`${className}__column__remove`}>
            <CloseButton onClick={handleRemoveClick} />
          </td>
        </>
      }
    >
      <div className={baseClass}>
        <Field name={`${namePreffix}.description`} component={TextEditorField} />
        {/* <ModuleOption>
          <div className={`${baseClass}__doc-type`}>
            <DocumentsAndCustomQuestions packageId={'4b02d5f6-ada6-4bc9-b4dd-1b77487b16bb'} />
          </div>
        </ModuleOption> */}
        {!clientId && (
          <Link to={packageRediterctPath}>
            <PrimaryButton variant="link">Review Package</PrimaryButton>
            <ArrowRight />
          </Link>
        )}
      </div>
    </CollapsibleTableRow>
  );
};

SingleSupportingPackage.propTypes = {
  index: PropTypes.number.isRequired,
  handleOnRemove: PropTypes.func,
  upperBound: PropTypes.bool,
  lowerBound: PropTypes.bool,
};
SingleSupportingPackage.defaultProps = {
  handleOnRemove: () => {},
  upperBound: false,
  lowerBound: false,
};
export default SingleSupportingPackage;
