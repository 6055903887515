import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import api from 'api';
import classNames from 'classnames';
import CloseButton from 'components/Buttons/CloseButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import CountrySelectField from 'components/CountrySelectField';
import DatepickerField from 'components/DatepickerField';
import InputField from 'components/InputField';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import StateProvinceSelectField from 'components/StateProvinceSelectField';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import Constants from 'utilities/constants';
import enums from 'utilities/enums';
import validators from 'utilities/services/validators';
/**
 * @param {string} namePreffix - preffix attached to all field name (e.g if component is used in FieldArray)
 * @param {*} param0
 */

const mappedAddressTypes = Object.entries(enums.ADDRESS_TYPES).map(type => ({
  label: type[0],
  value: type[1],
  id: type[1],
}));

const LegalEntityFields = ({ index, namePreffix, className, entitiesToGetValidated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fetchingStates, setFetchingStates] = useState(false);
  const {
    input: { value: address },
  } = useField(`${namePreffix}.address`);

  const {
    meta: { submitError },
  } = useField(`entities[${index}]`);

  const { countryId, stateProvinceId } = address;

  const toggleView = () => {
    setIsOpen(prev => !prev);
  };
  const { mutators } = useForm();

  const removePerson = useCallback(
    i => {
      mutators.remove('entities', i);
    },
    [mutators],
  );

  const indexClasses = classNames({
    success: submitError?.created,
    error: submitError && !submitError?.created,
  });

  const overlayOverFields = submitError?.created;

  const {
    input: { onChange: onChangeStateProvinceId },
  } = useField(`${namePreffix}.address.stateProvinceId`);

  useEffect(() => {
    const fetchStatesProvinces = () => {
      setFetchingStates(true);
      api.utilities
        .getStateProvincesByCountryIdApi(countryId)
        .then(({ data }) => {
          const provinceId = data.find(({ abbreviation }) => abbreviation === stateProvinceId.split('-')[1])?.id;
          onChangeStateProvinceId(provinceId);
        })
        .finally(() => {
          setFetchingStates(false);
        });
    };

    if (!fetchingStates && stateProvinceId && countryId) {
      fetchStatesProvinces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entitiesToGetValidated?.find(entity => entity === +index) !== undefined) {
      setIsOpen(true);
    }
  }, [entitiesToGetValidated, index]);

  return (
    <div className={className}>
      <h1 className={indexClasses}>{index + 1}</h1>
      <Field
        name={`${namePreffix}.name`}
        autoFocus
        placeholder="Name *"
        required
        component={InputField}
        className={`${className}--w2`}
        validate={validators.composeValidators(
          validators.required(),
          validators.stringMaxLength(Constants.CORP_NAME_MAX_LENGTH),
          validators.containsLetter('Must contain at least one letter'),
        )}
        disabled={overlayOverFields}
        parentSetError={!!submitError?.message}
      />
      <Field
        name={`${namePreffix}.formationDate`}
        placeholder="Formation Date"
        component={DatepickerField}
        className={`${className}--w2`}
        disabled={overlayOverFields}
      />
      <Field
        name={`${namePreffix}.jurisdictionOfIncorporationId`}
        placeholder="Formation Country"
        renderAsPortal
        component={CountrySelectField}
        className={`${className}--w2`}
        disabled={overlayOverFields}
      />
      <Field
        name={`${namePreffix}.companyPhone`}
        placeholder="Phone"
        component={InputField}
        className={`${className}--w2`}
        disabled={overlayOverFields}
        parse={value => value.replace(/[^\d]/g, '')}
      />
      <Field
        name={`${namePreffix}.leiNumber`}
        placeholder="GLEI"
        component={InputField}
        className={`${className}--w2`}
        disabled={overlayOverFields}
        validate={validators.validateLei("'LEI Number' must be 20 characters in length.")}
      />
      <Field
        name={`${namePreffix}.dunsNumber`}
        placeholder="DUNS"
        component={InputField}
        className={`${className}--w2`}
        disabled={overlayOverFields}
        validate={validators.validateDuns("'DUNS Number' must be 9 numbers in length.")}
      />

      <div className={`${className}--show-more`}>
        <span onClick={toggleView}>{isOpen ? <ArrowDropDown /> : <ArrowRight />}</span>
        <PrimaryButton variant="link" onClick={toggleView} disabled={overlayOverFields}>
          {isOpen ? 'Show Less' : 'Show More'}
        </PrimaryButton>
      </div>
      {isOpen && (
        <>
          <Field
            name={`${namePreffix}.doingBusinessAs`}
            placeholder="Doing Business As"
            component={InputField}
            className={`${className}--w6`}
          />
          <Field
            name={`${namePreffix}.businessNumber`}
            placeholder="Business Number"
            component={InputField}
            className={`${className}--w6`}
          />

          {/* TODO: replace with field array [{countryId: :id}] once client approves */}
          <Field
            name={`${namePreffix}.jurisdictionOfTaxResidenceId`}
            placeholder="Tax Reporting Jurisdiction"
            renderAsPortal
            component={CountrySelectField}
            className={`${className}--w6`}
          />
          <Field
            name={`${namePreffix}.einTin`}
            placeholder="EIN / TIN"
            component={InputField}
            className={`${className}--w6`}
          />

          <Field
            name={`${namePreffix}.address.streetAddress`}
            placeholder="Street Address"
            component={InputField}
            className={`${className}--w3`}
          />
          <Field
            name={`${namePreffix}.address.unit`}
            placeholder="Apt/Suite/Bldg/Gate Code, etc."
            component={InputField}
            className={`${className}--w3`}
          />
          <Field
            name={`${namePreffix}.address.city`}
            placeholder="City"
            component={InputField}
            className={`${className}--w3`}
          />

          <Field
            name={`${namePreffix}.address.postalZip`}
            placeholder="Zip / Postal Code"
            component={InputField}
            className={`${className}--w3`}
          />

          <Field
            name={`${namePreffix}.address.countryId`}
            placeholder="Country"
            component={CountrySelectField}
            renderAsPortal
            className={`${className}--w4`}
          />
          <Field
            name={`${namePreffix}.address.stateProvinceId`}
            placeholder="State/ Province"
            countryId={countryId}
            component={StateProvinceSelectField}
            renderAsPortal
            className={`${className}--w4`}
          />
          <Field
            name={`${namePreffix}.address.addressTypes`}
            component={SelectField}
            multipleSelection
            options={mappedAddressTypes}
            Trigger={<SelectTrigger placeholder="Address Type" />}
            Content={<SelectContent />}
            renderAsPortal
            className={`${className}--w4`}
          />
        </>
      )}

      <div className="remove-wrapper">
        <div className={`${className}__remove`}>
          <CloseButton onClick={() => removePerson(index)} />
        </div>
      </div>

      {submitError?.message && <div className={`${className}__error`}>{submitError?.message}</div>}
    </div>
  );
};

LegalEntityFields.propTypes = {
  namePreffix: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.number,
  entitiesToGetValidated: PropTypes.bool,
};

LegalEntityFields.defaultProps = {
  namePreffix: '',
  index: 0,
  className: undefined,
  entitiesToGetValidated: undefined,
};

export default LegalEntityFields;
