import dayjs from 'dayjs';

const termPeriodString = {
  0: 'day',
  1: 'month',
  2: 'year',
};

const calculateLicenseEndDate = (startDate, term, termPeriod) => {
  return dayjs(startDate)
    .add(term, termPeriodString[termPeriod])
    .endOf('day');
};

const calculateTermBasedOnEndDate = (startDate, endDate, termPeriod) => {
  return dayjs(endDate)
    .startOf('day')
    .diff(startDate, termPeriodString[termPeriod]);
};

export default {
  calculateLicenseEndDate,
  calculateTermBasedOnEndDate,
};
