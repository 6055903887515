import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import ToggleField from '../../../../../components/ToggleField';
import history from '../../../../../modules/history';
import enums from '../../../../../utilities/enums';
import './styles.scss';

const CasesReportsModal = ({ hideModal, caseId, searchTerm }) => {
  const [values] = useState({
    includeAcceptedMatchesForCaseTypeReport: false,
    includeOnlyAcceptedListigs: false,
    format: 'pdf',
  });
  const [submitedSuccessfully, setSubmitedSuccessfully] = useState(false);
  const handleSubmit = async configData => {
    if (submitedSuccessfully) {
      history.push(`/administration/files-management/reports`);
      return;
    }
    configData.type = 'CaseReportConfig';
    configData.searchTerm = searchTerm;
    configData.complianceLogId = Number(caseId);
    return api.kyc.caseManagement
      .createCaseReportConfig(caseId, configData)
      .then(() => {
        setSubmitedSuccessfully(true);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <FormModal
      onSubmit={handleSubmit}
      hideModal={hideModal}
      initialValues={values}
      title="Request Report"
      className="ickyc-cases-reports-modal"
      submitButtonText="Request Report"
      showErrorsInConfirmation
      onRejectClick={hideModal}
      showConfirmationForm={submitedSuccessfully}
      confirmButtonText="Go to Reports"
      confirmationText="To download requested report please go to Reports in Administration."
      rejectButtonText="Cancel"
    >
      <Field
        component={SelectField}
        name="format"
        label="Format"
        options={enums.EXPORT_REPORT_TYPES}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
      <Field
        component={ToggleField}
        name="includeAcceptedMatchesForCaseTypeReport"
        label="Include Only Accepted Matches"
      />
      <Field component={ToggleField} name="includeOnlyAcceptedListigs" label="Include Only Accepted Listings" />
    </FormModal>
  );
};
CasesReportsModal.propTypes = {
  hideModal: PropTypes.func,
  caseId: PropTypes.number,
  searchTerm: PropTypes.string,
};

CasesReportsModal.defaultProps = {
  hideModal: null,
  caseId: 0,
  searchTerm: '',
};

export default CasesReportsModal;
