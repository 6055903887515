import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SingleCountry from '../../../../../../../../components/CountrySelect/components/SingleCountry';
import Select from '../../../../../../../../components/Select';
import SelectFilterContent from '../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../components/Select/components/SelectTrigger';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';

const SourceAssignementRow = ({ rowInfo, className, onCheckboxClick, onControlsClick }) => {
  const { id, value: selected, checked, alpha2Code, name, label, isBold } = rowInfo;
  const [selectedValue, setSelectedValue] = useState();

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name: uName }) => ({
      label: uName,
      value: uId,
      id: uId,
    })),
  );

  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const handleCheckBoxClick = useCallback(() => {
    onCheckboxClick(id);
  }, [id, onCheckboxClick]);

  const handleOnChange = useCallback(
    value => {
      onControlsClick(id, value);
    },
    [id, onControlsClick],
  );
  const textClasses = classNames({
    [setColumnSuffix('__text')]: true,
    [setColumnSuffix('__text--bold')]: isBold,
  });

  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__checkbox')}>
        <input data-lpignore="true" type="checkbox" checked={checked} readOnly onChange={handleCheckBoxClick} />
      </td>
      {alpha2Code ? (
        <td className={setColumnSuffix('__country')}>
          <SingleCountry alpha2Code={alpha2Code} name={name} />
        </td>
      ) : (
        <td className={textClasses}>{label}</td>
      )}
      <td className={setColumnSuffix('__controls')}>
        <Select
          Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
          Content={<SelectFilterContent />}
          value={selectedValue}
          onChange={handleOnChange}
          options={usersToAssign}
          autoClose
        />
      </td>
    </tr>
  );
};

SourceAssignementRow.propTypes = {
  rowInfo: PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool,
    alpha2Code: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    isBold: PropTypes.bool,
  }),
  className: PropTypes.string.isRequired,
  onCheckboxClick: PropTypes.func,
  onControlsClick: PropTypes.func,
};

SourceAssignementRow.defaultProps = {
  rowInfo: {
    alpha2Code: '',
    name: '',
    value: '',
    label: '',
    checked: false,
    isBold: false,
  },
  onCheckboxClick: () => {},
  onControlsClick: () => {},
};

export default React.memo(SourceAssignementRow);
