import classNames from 'classnames';
import InputField from 'components/InputField';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import licenceEnums from 'utilities/enums/licenceEnums';
import validators from 'utilities/services/validators';
import LicenceItem from '../LicenceItem';

import './styles.scss';

const SetupSection = ({ namePreffix, hasAddons }) => {
  const classes = classNames('setup-section--with-divider', 'setup-section');

  const { change } = useForm();

  const handleChange = useCallback(
    (fieldName, value) => {
      if (!value) {
        const disabledItemName = fieldName?.split('Enabled')[0];
        change(disabledItemName, 0);
      }

      change(fieldName, value);
    },
    [change],
  );

  const fieldPreffix = namePreffix ? `${namePreffix}.setupAndMaintenance` : 'setupAndMaintenance';

  return (
    <section className={classes}>
      <div className="setup-section__header">
        <h3>Setup & Maintenance</h3>
      </div>

      <div className="setup-section__content">
        <LicenceItem
          text="One-Time Setup Fee"
          handleChange={handleChange}
          fieldName={`${fieldPreffix}.oneTimeSetupFeeEnabled`}
        >
          <Field
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.oneTimeSetupFee`}
            placeholder=". . ."
            type="number"
            validate={validators.requiredNumber}
          />
        </LicenceItem>
        <LicenceItem
          text="Recurring Maintenance Fee"
          handleChange={handleChange}
          fieldName={`${fieldPreffix}.recurringMaintenanceEnabled`}
        >
          <Field
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.recurringMaintenance`}
            placeholder=". . ."
            type="number"
            validate={validators.requiredNumber}
          />
          <Field
            component={SelectField}
            name={`${fieldPreffix}.recurringMaintenancePeriod`}
            options={licenceEnums.BILLING_CYCLE_REDUCED_OPTIONS}
            Trigger={<SelectTrigger placeholder="Billing Cycle" />}
            Content={<SelectContent />}
            validate={validators.required(' ')}
          />
        </LicenceItem>
        <LicenceItem text="SaaS Fee" handleChange={handleChange} fieldName={`${fieldPreffix}.saasFeeEnabled`}>
          <Field
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.saasFee`}
            placeholder=". . ."
            type="number"
            validate={validators.requiredNumber}
          />
          <Field
            component={SelectField}
            name={`${fieldPreffix}.saasFeePeriod`}
            options={licenceEnums.BILLING_CYCLE_REDUCED_OPTIONS}
            Trigger={<SelectTrigger placeholder="Billing Cycle" />}
            Content={<SelectContent />}
            validate={validators.required(' ')}
          />
        </LicenceItem>
        {hasAddons && (
          <LicenceItem text="Add-Ons" handleChange={handleChange} fieldName={`${fieldPreffix}.addonsFeeEnabled`}>
            <Field
              disabled
              component={InputField}
              name={`${fieldPreffix}.addonsFee`}
              preffix="$"
              placeholder=". . ."
              type="number"
              validate={validators.requiredNumber}
            />
            <Field
              component={SelectField}
              name={`${fieldPreffix}.saasFeePeriod`}
              options={licenceEnums.BILLING_CYCLE_REDUCED_OPTIONS}
              Trigger={<SelectTrigger placeholder="Billing Cycle" />}
              Content={<SelectContent />}
              validate={validators.required(' ')}
            />
          </LicenceItem>
        )}
      </div>
    </section>
  );
};

SetupSection.propTypes = {
  namePreffix: PropTypes.string,
  hasAddons: PropTypes.bool,
};

SetupSection.defaultProps = {
  namePreffix: '',
  hasAddons: false,
};

export default SetupSection;
