/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../utilities/services/IconManager';
import { CaseContext } from '../../../../../../../../../utilities/services/contexts';

const DocumentListingRow = props => {
  const {
    rowInfo: { id, url, checked },
    onCheckboxClick,
    onControlsClick,
    className,
  } = props;
  const { isCaseClosed } = useContext(CaseContext);

  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const handleOnChange = useCallback(
    value => {
      onControlsClick(id, value);
    },
    [id, onControlsClick],
  );

  const handleCheckboxChange = useCallback(
    event => {
      if (event.type !== 'change') {
        event.stopPropagation();
      }
      onCheckboxClick(id);
    },
    [id, onCheckboxClick],
  );

  return (
    <tr className={`${className}__row`}>
      <td className={setColumnSuffix('__checkbox')}>
        <input
          data-lpignore="true"
          type="checkbox"
          checked={checked}
          readOnly
          onClick={handleCheckboxChange}
          disabled={isCaseClosed}
        />
      </td>
      <td className={setColumnSuffix('__icon')}>{IconManager.get(IconManager.names.DOCUMENT)}</td>
      <td className={setColumnSuffix('__source')}>
        {url && (
          <a href={url} target="_blank">
            <span>{url}</span>
            {IconManager.get(IconManager.names.LINK)}
          </a>
        )}
      </td>
      {/* {!isCaseClosed && (
        <td className={setColumnSuffix('__controls')}>
          <HitStatusRowControls onClick={handleOnChange} />
        </td>
      )} */}
    </tr>
  );
};

DocumentListingRow.propTypes = {
  rowInfo: PropTypes.shape({
    checked: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    url: PropTypes.string,
  }),
  onCheckboxClick: PropTypes.func,
  onControlsClick: PropTypes.func,
  className: PropTypes.string,
};

DocumentListingRow.defaultProps = {
  rowInfo: {},
  onCheckboxClick: () => {},
  onControlsClick: () => {},
  className: undefined,
};
export default DocumentListingRow;
