import InputField from 'components/InputField';
import React from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';

const Text = () => {
  return (
    <div className="ickyc-custom-document-options">
      <span className="ickyc-custom-document-options--label">Question ID</span>
      <span>
        <Field name="id" component={InputField} preview></Field>
      </span>
      <span className="ickyc-custom-document-options--label">Character Limit</span>
      <span>
        <Field
          name="parameters.characterLimit"
          type="number"
          component={InputField}
          min="1"
          max="255"
          validate={validators.required()}
        ></Field>
      </span>
    </div>
  );
};
export default Text;
