import HelpIcon from '@material-ui/icons/Help';
import api from 'api';
import UndoIcon from 'assets/UndoIcon';
import classNames from 'classnames';
import useModalHandler from 'hooks/useModalHandler';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import enums from 'utilities/enums';
import setClassSuffix from 'utilities/services/ClassManager';
import { EntityContext } from 'utilities/services/contexts';
import { v4 as uuid } from 'uuid';
import IconButton from '../../../../../../../components/Buttons/IconButton';
import Tooltip from '../../../../../../../components/Tooltip';
import ProtectedComponent from '../../../../../../../router/components/ProtectedComponent';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import tooltips from '../../../../../../../utilities/enums/tooltips';
import ConfirmManualRiskLevelModal from './components/ConfirmManualRiskLevelModal';
import RevertRiskLevelModal from './components/RevertRiskLevelModal';
import './styles.scss';

/**
 * Displays level numbers distinguishing the active one.
 * @param {number} active - active risk level
 */

const riskLevelMapping = {
  OneToThree: 3,
  OneToFour: 4,
  OneToFive: 5,
};

const RiskLevelSteps = ({ active, onClick, changing, riskScore, riskLevelCalculationMethod }) => {
  const { riskLevelLabels = {}, riskLevelScale } = useSelector(state => state.global.riskLevels || []);
  const { entityId, riskLevelEnabled, riskLevel } = useContext(EntityContext);

  const [selectedLevel, setSelectedLevel] = useState();
  const {
    close: closeConfirmManualRiskLevelModal,
    isOpen: isOpenConfirmManualRiskLevelModal,
    open: openConfirmManualRiskLevelModal,
  } = useModalHandler();
  const {
    close: closeRevertRiskLevelModal,
    isOpen: isOpenRevertRiskLevelModal,
    open: openRevertRiskLevelModal,
  } = useModalHandler();

  const labels = Array.from({ length: riskLevelMapping[riskLevelScale] }, (_, i) => i + 1);

  const classes = classNames({
    'ickyc-risk-level-steps ': true,
    'ickyc-risk-level-steps--loading': changing,
  });

  const revertRiskLevelToAutomatic = useCallback(async () => {
    try {
      const {
        data: { riskLevel, riskLevelCalculationMethod },
      } = await api.kyc.entityManagement.revertRiskLevelToAutomatic(entityId);
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, { riskLevel, riskLevelCalculationMethod });
      closeRevertRiskLevelModal();
      toastr.success('Successfully reverted risk level to automatic value.');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while reverting risk level to automatic.');
    }
  }, [entityId]);

  const setSuffix = setClassSuffix('ickyc-risk-level-steps');

  const handleManualLevelChange = useCallback(level => {
    setSelectedLevel(level);
    openConfirmManualRiskLevelModal();
  }, []);

  const manualyChangeRiskLevel = useCallback(async () => {
    await onClick(selectedLevel);
    closeConfirmManualRiskLevelModal();
  }, [selectedLevel]);

  const riskLevelInfo = useMemo(() => {
    if (riskLevelCalculationMethod) {
      return riskLevelCalculationMethod === 'Manual' ? (
        <>
          <p>Risk level was set manually</p>
          {riskLevelEnabled && (
            <Tooltip
              trigger={
                <div className={setSuffix('__revert-risk-level-icon')} onClick={openRevertRiskLevelModal}>
                  <UndoIcon />
                </div>
              }
              content={<div>{tooltips.REVERT_RISK_LEVEL}</div>}
            />
          )}
        </>
      ) : (
        <p>Risk level was set by automation</p>
      );
    }
  }, [riskLevelCalculationMethod, openRevertRiskLevelModal]);

  return (
    <div className={classes}>
      <div className={setSuffix('__container')}>
        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.RISK_SCORING}>
          <div className={setSuffix('__risc-level-container')}>
            Risk Score:
            <span>&nbsp;{riskScore}</span>
          </div>
        </ProtectedComponent>
        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.RISK_LEVELS}>
          <div className={setSuffix('__risc-level-container')}>
            Risk Level:
            <span>{!!riskLevel && `${active} ${riskLevelLabels[active - 1]}`}</span>
            <Tooltip trigger={<HelpIcon />} content={<div>{tooltips.RISK_LEVEL}</div>} />
          </div>
        </ProtectedComponent>
      </div>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.RISK_LEVELS}>
        <div className={setSuffix('__risc-level-buttons-container')}>
          {labels.map(level => {
            return (
              <IconButton key={uuid()} active={active === level} onClick={() => handleManualLevelChange(level)}>
                {level}
              </IconButton>
            );
          })}
        </div>
      </ProtectedComponent>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.RISK_LEVELS}>
        <div className={setSuffix('__risk-level-info')}>{riskLevelInfo}</div>
      </ProtectedComponent>

      {isOpenConfirmManualRiskLevelModal && (
        <ConfirmManualRiskLevelModal
          closeModal={closeConfirmManualRiskLevelModal}
          handleSubmit={manualyChangeRiskLevel}
          riskLevelCalculationMethod={riskLevelCalculationMethod}
        />
      )}

      {isOpenRevertRiskLevelModal && (
        <RevertRiskLevelModal closeModal={closeRevertRiskLevelModal} handleSubmit={revertRiskLevelToAutomatic} />
      )}
    </div>
  );
};

RiskLevelSteps.propTypes = {
  active: PropTypes.number,
  onClick: PropTypes.func,
  changing: PropTypes.bool,
  riskScore: PropTypes.number,
  riskLevelCalculationMethod: PropTypes.bool,
};

RiskLevelSteps.defaultProps = {
  active: 0,
  onClick: () => {},
  changing: false,
  riskScore: 0,
  riskLevelCalculationMethod: undefined,
};

export default RiskLevelSteps;
