import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import React from 'react';
import 'tippy.js/dist/tippy.css';

const Tooltip = ({ content, trigger, placement }) => {
  return (
    <Tippy className="ickyc-tooltip" animation="fade" hideOnClick interactive content={content} placement={placement}>
      {trigger}
    </Tippy>
  );
};

Tooltip.propTypes = {
  trigger: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  placement: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: 'right',
};

export default Tooltip;
