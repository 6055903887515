const prepareItems = packageItems => {
  let answersDTO;
  const { answers, ...items } = packageItems || {};

  if (answers) {
    answersDTO = Object.entries(answers).map(([itemId, valueWithKeyType]) => {
      return Object.entries(valueWithKeyType).map(([itemType, nestedValue]) => {
        return {
          itemId,
          answer: nestedValue,
          type: itemType,
        };
      })[0];
    });
  }

  return { answersDTO, itemsDTO: items };
};

const utils = { prepareItems };
export default prepareItems;
