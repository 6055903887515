import api from 'api';
import Spinner from 'components/Spinner';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DocumentDataContext, EntityContext, HistoryRecordContext } from 'utilities/services/contexts';
import enums from '../../../../../../../utilities/enums';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import HistoryRecordControls from '../../../HistoryRecordControls';
import DocumentPackage from './components/DocumentPackage';
import DocumentDetails from './components/DocumentsDetails';
import './index.scss';

function DocumentData({ data, onRemove, onDataFetch }) {
  const [isLoading, setIsLoading] = useState(false);
  const { entityId } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);

  const [controlsInfo, setControlsInfo] = useState({});

  const showControlls = useMemo(
    () =>
      data?.thirdPartySuggestion?.suggestionStatus !== 'pending' &&
      !data?.thirdPartySuggestion?.thirdPartyReport?.issuesCount &&
      !data?.thirdPartySuggestion?.isDelete,
    [
      data?.thirdPartySuggestion?.suggestionStatus,
      data?.thirdPartySuggestion?.thirdPartyReport?.issuesCount,
      data?.thirdPartySuggestion?.isDelete,
    ],
  );

  useEffect(() => {
    const { status, sourceType, sourceDetail, eventId, portalConfigurationId, portalDomain, portalName } = data || {};
    setControlsInfo({
      status,
      sourceType,
      sourceDetail,
      eventId,
      portalConfigurationId,
      portalDomain,
      portalName,
    });
  }, [data, isLoading]);

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, __status, allRejected) => {
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.DOCUMENTS,
        issuesChange,
        allRejected,
      });
      onDataFetch({ ...data, issues: issuesList });
    },
    [data, onDataFetch],
  );

  const classBase = 'ickyc-document-record';
  const setSuffix = setClassSuffix(classBase);

  useEffect(() => {
    (JSON.stringify(data) === '{}' || data?.eventHistoryId !== eventHistoryId) &&
      !data?.message &&
      (async () => {
        try {
          setIsLoading(true);
          const { data: responseData } = await api.kyc.entityManagement.getDocumentsTableRecord({
            entityId,
            documentId: eventHistoryId,
            limit: 1,
          });
          onDataFetch(responseData);
        } catch (err) {
          onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      })();
  }, []);

  if (isLoading) {
    return (
      <div className={setSuffix('__wrapper')}>
        <Spinner />
      </div>
    );
  }

  return (
    <DocumentDataContext.Provider value={{ onRemove, documentRecord: data, setDocumentRecord: onDataFetch }}>
      <div className={setSuffix('__wrapper')}>
        {data?.message ? (
          <div className={setSuffix('__message')}>{data.message}</div>
        ) : (
          <>
            <div className={classBase}>
              <div className={setSuffix('__content')}>
                <div className={setSuffix('__content__left')}>
                  <DocumentDetails details={data?.details} />
                </div>
                <div className={setSuffix('__content__right')}>
                  <DocumentPackage />
                </div>
              </div>
            </div>
            {showControlls && (
              <HistoryRecordControls
                info={controlsInfo}
                onStatusChange={handleStatusChange}
                apiRequest={api.kyc.entityManagement.changeHistoryDocumentsEventStatus}
              />
            )}
          </>
        )}
      </div>
    </DocumentDataContext.Provider>
  );
}
DocumentData.propTypes = {
  data: PropTypes.shape({
    details: PropTypes.shape({
      editableText: PropTypes.string,
      fileName: PropTypes.string,
      type: PropTypes.string,
    }),
    eventHistoryId: PropTypes.string,
    files: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          blobUrl: PropTypes.string,
          format: PropTypes.string,
        }),
      ),
      paginationData: PropTypes.shape({
        totalPages: PropTypes.number,
        currentPage: PropTypes.number,
        limit: PropTypes.number,
        skip: PropTypes.number,
        total: PropTypes.number,
      }),
    }),
    thirdPartySuggestion: PropTypes.shape({
      editedSupportingDoc: PropTypes.shape({
        files: PropTypes.shape({
          paginationData: PropTypes.shape({
            skip: PropTypes.number,
          }),
        }),
      }),
      thirdPartyReport: PropTypes.shape({
        issuesCount: PropTypes.number,
      }),
      suggestionStatus: PropTypes.string,
      isDelete: PropTypes.bool,
    }),
    issues: PropTypes.arrayOf(PropTypes.string),
    sourceType: PropTypes.string,
    sourceDetails: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }),
  onDataFetch: PropTypes.func,
  onRemove: PropTypes.func,
};

DocumentData.defaultProps = {
  data: { details: { editableText: '' } },
  onDataFetch: () => {},
  onRemove: () => {},
};
export default DocumentData;
