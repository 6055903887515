const STATUS_OPTIONS = [
  { id: '0', label: 'Active', value: 'Active' },
  {
    id: '1',
    label: 'Archived',
    value: 'Archived',
  },
];

const productStatusTabs = [
  {
    label: 'Active',
    key: 'active',
  },
  {
    label: 'Archived',
    key: 'archived',
  },
];

export default { STATUS_OPTIONS, productStatusTabs };
