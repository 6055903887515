import { CircularProgress } from '@material-ui/core';
import api from 'api';
import classNames from 'classnames';
import CloseButton from 'components/Buttons/CloseButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Modal from 'components/Modal';
import { setIn } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router';
import toastr from 'toastr';
import utilities from 'utilities';
import routesEnum from 'utilities/enums/routesEnum';
import IconManager from 'utilities/services/IconManager';
import './styles.scss';

const V2Modal = ({
  hideModal,
  initialValues,
  withFieldArray,
  className,
  showErrorsInConfirmation,
  onSubmitHandler,
  onInvalidSubmit,
  children,
  titles,
  disableSubmitWhenErrorMessageIsDisplayed,
  ...rest
}) => {
  const baseClass = 'ickyc-affiliated-form-modal';
  const [initialVals, setInitialVals] = useState(initialValues || {});
  const [page, setPage] = useState(0);
  const history = useHistory();

  const fieldArrayMutators = useMemo(() => (withFieldArray ? { ...arrayMutators } : {}), [withFieldArray]);

  const isLastPage = page === React.Children.count(children) - 1;
  const isFirstPage = page === 0;

  const previous = () => setPage(prev => Math.max(prev - 1, 0));

  const renderErrors = err => {
    return (
      <div className={`${baseClass}__submissionError`}>
        {Array.isArray(err) ? (
          <>
            {err[0]}
            <ul>{err.map((e, index) => (index > 0 ? <li>{e}</li> : <></>))}</ul>
          </>
        ) : (
          err
        )}
      </div>
    );
  };

  const next = useCallback(
    vals => {
      setPage(prev => Math.min(prev + 1, children.length - 1));
      setInitialVals(vals);
    },
    [children.length],
  );

  const onSubmit = useCallback(
    async (vals, form) => {
      if (isLastPage) {
        return onSubmitHandler(vals, form);
      }

      const leNames = vals?.entities?.map(entity => entity?.name);
      let errors = {};

      const setError = (key, value) => {
        errors = setIn(errors, key, value);
      };

      const navigateToEntity = ({ searchParam }) => {
        const encodedSearchParam = encodeURIComponent(searchParam);
        history.push(`${routesEnum.kyc.ENTITY_MANAGEMENT}?fullySearchParam=${encodedSearchParam}`);
        hideModal();
      };

      try {
        const { data } = await api.kyc.entityManagement.legalEntity.checkDuplicates(leNames);

        data.forEach(duplicate => {
          const duplicateIndexes = [];

          leNames.forEach((name, index) => {
            if (name === duplicate) {
              duplicateIndexes.push(index);
            }
          });

          duplicateIndexes.forEach(duplicateIndex => {
            setError(`entities[${duplicateIndex}].name`, 'Legal Entity with this name already exists.');

            setError(
              `entities[${duplicateIndex}].message`,
              <span className={`${baseClass}__duplicate`} onClick={() => navigateToEntity({ searchParam: duplicate })}>
                {`Continue to ${duplicate} Profile`}
              </span>,
            );
          });
        });

        if (Object.entries(errors).length > 0) {
          return errors;
        }

        next(initialVals);
      } catch (err) {
        toastr.error('Error occurred while moving to next step');
      }
    },
    [isLastPage, onSubmitHandler, next, initialVals, hideModal, history],
  );

  const skip = () => {
    next(initialVals);
  };

  const activePage = React.Children.toArray(children)[page];

  return (
    <Modal hideModal={hideModal} className={className}>
      <FinalForm initialValues={initialVals} onSubmit={onSubmit} mutators={fieldArrayMutators} {...rest}>
        {({
          handleSubmit,
          submitting,
          submitError,
          pristine,
          leaveModalOpen,
          closeLeaveModal,
          showConfirmationForm,
          onRejectClick,
          onConfirmClick,
          confirmButtonText,
          confirmationText,
          rejectButtonText,
          openLeaveModal,
          form,
          errors,
          values,
          touched,
        }) => {
          console.log({
            touched,
            errors,
            drugi: utilities.getTouchedAndErrorFields(touched, errors),
          });
          if (leaveModalOpen && pristine) hideModal();
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                !isLastPage && Object.entries(errors).length && onInvalidSubmit(values, form);
                handleSubmit(e, form);
              }}
              className={baseClass}
            >
              <div className={`${baseClass}__header ickyc-modal-stepper-header`}>
                {titles.map((title, index) => {
                  const partClasses = classNames({
                    'ickyc-modal-stepper-header--part': true,
                    'ickyc-modal-stepper-header--part--active': index === page,
                  });
                  return (
                    <>
                      <div className={partClasses} key={title}>
                        {typeof title === 'string' ? <h2>{title}</h2> : title}
                        {page > index && IconManager.get(IconManager.names.CHECK_FILLED)}
                        {index === titles.length - 1 && (
                          <>
                            <span className="ickyc-spacer" />
                            <CloseButton transparent onClick={hideModal} />
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
              <div className={`${baseClass}__content ${submitting ? `${baseClass}__content--loading` : ''}`}>
                {activePage}
                {isLastPage && submitError && (
                  <div className={`${baseClass}__submissionError`}>
                    {Array.isArray(submitError) ? (
                      <>
                        <span>{submitError[0]}</span>
                        <ul>{submitError.map((e, index) => (index > 0 ? <li>{e}</li> : <></>))}</ul>
                      </>
                    ) : (
                      submitError
                    )}
                  </div>
                )}
              </div>
              <div className={`${baseClass}__footer`}>
                <PrimaryButton variant="link" type="button" onClick={() => openLeaveModal()} disabled={submitting}>
                  Cancel
                </PrimaryButton>
                <div className={`${baseClass}__footer__buttons-right`}>
                  {!isFirstPage && (
                    <PrimaryButton variant="link" type="button" onClick={previous} disabled={submitting}>
                      « Previous
                    </PrimaryButton>
                  )}
                  {!isLastPage && !isFirstPage && (
                    <OutlineButton disabled={submitting} onClick={skip}>
                      Skip
                    </OutlineButton>
                  )}
                  <PrimaryButton
                    type="submit"
                    disabled={
                      (isLastPage && submitting) ||
                      (disableSubmitWhenErrorMessageIsDisplayed &&
                        utilities.getTouchedAndErrorFields(touched, errors).length) ||
                      !values.entities
                    }
                  >
                    {isLastPage ? 'Submit' : 'Save and Next'}
                    {submitting && <CircularProgress />}
                  </PrimaryButton>
                </div>
              </div>
              {showConfirmationForm && (
                <div className={`${baseClass}__warning`}>
                  <div>
                    {showErrorsInConfirmation && submitError && renderErrors(submitError)}
                    <h2 className={`${baseClass}__warning--message`}>{confirmationText}</h2>
                    <div>
                      <OutlineButton disabled={submitting} onClick={onRejectClick}>
                        {rejectButtonText}
                      </OutlineButton>
                      <PrimaryButton disabled={submitting} type="submit" onClick={onConfirmClick}>
                        {confirmButtonText}
                        {submitting && <CircularProgress />}
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              )}
              {leaveModalOpen && (
                <div className={`${baseClass}__warning`}>
                  <div>
                    <h2 className={`${baseClass}__warning--message`}>
                      Are you sure you want to navigate away from this window and discard the data?
                    </h2>
                    <div>
                      <OutlineButton onClick={closeLeaveModal}>Stay</OutlineButton>
                      <PrimaryButton onClick={hideModal}>Leave</PrimaryButton>
                    </div>
                  </div>
                </div>
              )}
            </form>
          );
        }}
      </FinalForm>
    </Modal>
  );
};
V2Modal.propTypes = {
  hideModal: PropTypes.func,
  initialValues: PropTypes.shape({}),
  withFieldArray: PropTypes.bool,
  showErrorsInConfirmation: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  onInvalidSubmit: PropTypes.func.isRequired,
  disableSubmitWhenErrorMessageIsDisplayed: PropTypes.bool,
};
V2Modal.defaultProps = {
  hideModal: () => {},
  initialValues: {},
  withFieldArray: false,
  showErrorsInConfirmation: true,
  className: '',
  disableSubmitWhenErrorMessageIsDisplayed: false,
};
export default V2Modal;
