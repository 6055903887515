import api from 'api';
import SelectContentTable from 'components/Select/components/SelectContentTable';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import ToggleField from 'components/ToggleField';
import { FORM_ERROR } from 'final-form';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import ProtectedComponent from 'router/components/ProtectedComponent';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import { EntityContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import utils from '../../utils';
import ExistingCasesTableRow from '../ExistingCasesTableRow';
import './styles.scss';

const { initialParams } = utils;
const ReportsModalFields = ({ entityId }) => {
  const [params, setParams] = useState(initialParams);
  const [pagination, setPagination] = useState({});
  const [cases, setCases] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isNaturalPerson } = useContext(EntityContext);

  const {
    values: { includeImages, includeAmlCases },
  } = useFormState();
  const { change } = useForm();

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const handleIncludeAmlCasesChange = useCallback(
    async includeAmlCases => {
      !includeAmlCases && change('includeOnlyAcceptedMatches', false);
    },
    [change],
  );

  const casesValues = useMemo(() => cases?.map(item => item.value), [cases]);

  useEffect(() => {
    const loadCases = async () => {
      setIsLoading(true);
      try {
        const res = await api.kyc.entityManagement.getEntityOpenCases(entityId);
        const casesData = res.data.data.map(item => ({
          label: item.name,
          value: item.id,
          id: item.id,
        }));
        setPagination(res.data.paginationData);
        setCases(casesData);
      } catch (err) {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      } finally {
        setIsLoading(false);
      }
    };

    loadCases();
  }, []);
  return (
    <di className="ickyc-reports-modal-fields">
      <Field
        component={SelectField}
        name="format"
        label="Format"
        options={enums.EXPORT_REPORT_TYPES}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
      <Field component={ToggleField} name="includeAcceptedOnly" label="Include Accepted Only" />
      <Field component={ToggleField} name="includeImages" label="Include Images" />
      {!!includeImages && (
        <div className="ickyc-reports-modal-fields__hidden-options">
          {isNaturalPerson && <Field component={ToggleField} name="includeIdDocsImages" label="ID Docs" />}
          {isNaturalPerson && <Field component={ToggleField} name="includeFaceMatchImages" label="Face Matches" />}
          <Field component={ToggleField} name="includeSupportingDocs" label="Supporting Document Packages" />
        </div>
      )}
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
        <div className="ickyc-reports-modal-fields__aml-toggle">
          <Field
            component={ToggleField}
            name="includeAmlCases"
            label="Include AML cases"
            disabled={!cases || cases?.length === 0}
            onChange={handleIncludeAmlCasesChange}
          />
          {cases?.length === 0 && (
            <span className="ickyc-reports-modal-fields__aml-toggle__no-cases">
              There are no cases available for the profile
            </span>
          )}
        </div>

        {includeAmlCases && (
          <div className="ickyc-reports-modal-fields__hidden-options">
            <Field component={ToggleField} name="includeOnlyAcceptedMatches" label="Include Only Accepted Matches" />
          </div>
        )}

        {includeAmlCases && (
          <Field
            component={SelectField}
            name="cases"
            label="What cases do you want to include in this report?"
            multipleSelection
            Trigger={<SelectTrigger />}
            withTableContent
            options={cases}
            initialValue={casesValues}
            validate={validators.customValidationForCasesDropdown()}
            Content={
              <SelectContentTable
                tableProps={{
                  tableRow: ExistingCasesTableRow,
                  headerRow: TableHeader,
                  handleParamsChange: changeParams,
                  pagination,
                  headerData: {
                    sortCriteria: params.sortCriteria,
                    sortOrder: params.sortOrder,
                    onClick: changeParams,
                    items: [
                      {
                        type: 'id',
                        label: 'Id',
                        clickable: true,
                      },
                      {
                        type: 'name',
                        label: 'Name',
                        clickable: true,
                      },
                    ],
                  },
                  updating: isLoading,
                  withLoadMore: true,
                  withPagination: true,
                  className: 'ickyc-existing-cases-table',
                }}
              />
            }
          />
        )}
      </ProtectedComponent>
    </di>
  );
};
export default ReportsModalFields;
