import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import api from '../../../../api';
import FormModal from '../../../../components/Modal/FormModal';
import SelectFilterContent from '../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../components/SelectField';
import ToggleField from '../../../../components/ToggleField';
import useSelect from '../../../../hooks/useSelect';
import { selectUsersToAssign } from '../../../../store/selectors/global.selector';
import enums from '../../../../utilities/enums';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import './styles.scss';

const BatchActionsCaseModal = ({ hideModal, complianceLogsIds, onBatchAction }) => {
  const className = 'ickyc-batch-action-cases-modal';
  const setSuffix = setClassSuffix(className);
  const assignedToItems = useSelect(selectUsersToAssign).map(user => ({
    id: user.id,
    label: user.name,
    value: user.id,
  }));

  const handleSubmit = async vals => {
    if (!vals.status && !vals.assignTo?.length && !vals?.copyFromPreviousCases) {
      return { [FORM_ERROR]: 'No Values Selected!' };
    }
    try {
      const { data } = await api.kyc.caseManagement.batchUpdateCasesFromModal({ ...vals, complianceLogsIds });
      onBatchAction(data);
      hideModal();
    } catch (err) {
      if (err?.response) {
        console.log(err?.response);
        const { status: resStatus, data } = err.response;
        if (resStatus >= 400 && resStatus < 500) {
          return { [FORM_ERROR]: data.message };
        }
        if (resStatus === 500) {
          return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };
  return (
    <FormModal
      initialValues={{ assignTo: null, status: null, copyFromPreviousCases: false }}
      onSubmit={handleSubmit}
      hideModal={hideModal}
      className={className}
      showErrorsInConfirmation
      title="Batch Actions"
    >
      <div className={setSuffix('__container')}>
        <div className={setSuffix('__container__dropdowns')}>
          <span> Case Status</span>
          <Field
            component={SelectField}
            name="status"
            options={enums.MAPPED_CASE_STATUSES}
            Content={<SelectFilterContent />}
            Trigger={<SelectTrigger placeholder="- Choose Status -" />}
          />
          <span> Assign To</span>
          <Field
            component={SelectField}
            name="assignTo"
            options={assignedToItems}
            renderAsPortal
            Content={<SelectFilterContent />}
            Trigger={<SelectTrigger placeholder="- Choose -" />}
          />
        </div>
        {/* <Field component={ToggleField} label="Repeat Cases" name="repeat" /> */}
        <Field
          component={ToggleField}
          label="Copy all Accept/Reject/Unknown decisions from Previous Case"
          name="copyFromPreviousCases"
        />
      </div>
    </FormModal>
  );
};
BatchActionsCaseModal.propTypes = {
  hideModal: PropTypes.func,
  complianceLogsIds: PropTypes.array,
  onBatchAction: PropTypes.func,
};
BatchActionsCaseModal.defaultProps = {
  hideModal: () => {},
  complianceLogsIds: [],
  onBatchAction: () => {},
};
export default BatchActionsCaseModal;
