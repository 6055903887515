import DownloadDocumentIcon from 'assets/DownloadDocumentIcon';
import UploadDocumentIcon from 'assets/UploadDocumentIcon';
import classNames from 'classnames';
import CloseButton from 'components/Buttons/CloseButton';
import Modal from 'components/Modal';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import DocumentsPreview from 'scenes/Kyc/EntityManagement/components/DocumentsSection/components/DocumentsData/components/DocumentsPackageTable/components/DocumentPackageTableRow/components/DocumentsPreview';
import './styles.scss';
import utils from './utils';

const DocumentDropzone = props => {
  const {
    label,
    required,
    uploadText,
    preview,
    hasError,
    errorMessage,
    value,
    onChange,
    accept,
    downloadText,
    allowMultiple,
    ...rest
  } = props;
  const baseClass = 'ickyc-document-dropzone';
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { close: closePreviewModal, isOpen: isOpenPreviewModal, open: openPreviewModal } = useModalHandler();

  const handleOnDrop = useCallback(
    files => {
      const tempDocuments = files.map(file => {
        const objectURL = URL.createObjectURL(file);
        const format = utils.getProperFormat(file.name);
        return {
          originalName: file.name,
          type: file.type,
          blobURL: objectURL,
          downloadUrl: objectURL,
          previewUrl: objectURL,
          format,
          file,
        };
      });

      setUploadedFiles(old => {
        const newFiles = allowMultiple ? [...old, ...tempDocuments] : [...tempDocuments];
        onChange(newFiles);
        return newFiles;
      });
    },
    [onChange, setUploadedFiles],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleOnDrop,
    accept,
    multiple: allowMultiple,
  });

  const removeFile = useCallback(
    event => {
      event.stopPropagation();
      onChange(undefined);
      setUploadedFiles([]);
    },
    [onChange],
  );

  const handleDownload = useCallback(() => {
    if (value?.blobURL) {
      const downloadLink = document.createElement('a');
      downloadLink.href = value?.blobURL;
      downloadLink.download = value?.originalName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }, [value?.blobURL, value?.originalName]);

  const handleDownloadClick = useCallback(() => {
    downloadText === 'Download Template' ? handleDownload() : openPreviewModal();
  }, [openPreviewModal, handleDownload, downloadText]);

  const classes = classNames(baseClass, {
    [`${baseClass}--required`]: required,
    [`${baseClass}--error`]: hasError,
  });

  return (
    <div className={classes}>
      {label && <label>{label}</label>}

      <div {...getRootProps()} onClick={e => e.stopPropagation()}>
        <input {...getInputProps()} {...rest} />
        {value ? (
          <div onClick={handleDownloadClick} className={`${baseClass}__content ${baseClass}__content--download`}>
            <DownloadDocumentIcon />
            <p>{downloadText}</p>
            {!preview && <CloseButton onClick={removeFile} />}
          </div>
        ) : (
          <div onClick={open} className={`${baseClass}__content ${baseClass}__content--upload`}>
            <UploadDocumentIcon />
            <p>{uploadText}</p>
          </div>
        )}
      </div>
      {isOpenPreviewModal && (
        <Modal
          open={isOpenPreviewModal}
          hideModal={closePreviewModal}
          title={`Document Preview`}
          onClick={e => e.stopPropagation()}
        >
          <div className="ickyc-document-dropzone__document-modal ">
            <CloseButton
              className="ickyc-document-package-table-row__close-button"
              transparent
              onClick={closePreviewModal}
            />
            <DocumentsPreview answers={uploadedFiles} />
          </div>
        </Modal>
      )}
      {hasError && <small className={`${baseClass}__error`}>{errorMessage}</small>}
    </div>
  );
};

DocumentDropzone.propTypes = {
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  uploadText: PropTypes.string,
  preview: PropTypes.bool,
  downloadText: PropTypes.string,
  allowMultiple: PropTypes.bool,
};

DocumentDropzone.defaultProps = {
  hasError: false,
  errorMessage: undefined,
  label: undefined,
  description: undefined,
  required: undefined,
  uploadText: 'Upload a Document Template',
  downloadText: 'Preview Documents',
  preview: false,
  allowMultiple: true,
};

export default DocumentDropzone;
