import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import utilities from 'utilities';
import api from '../../../../../../api';
import Accordion from '../../../../../../components/Accordion';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import DebounceInput from '../../../../../../components/DebounceInput';
import Table from '../../../../../../components/Tables/Table';
import TableHeader from '../../../../../../components/Tables/Table/components/TableHeader';
import useModalHandler from '../../../../../../hooks/useModalHandler';
import bus from '../../../../../../modules/bus';
import ProtectedComponent from '../../../../../../router/components/ProtectedComponent';
import enums from '../../../../../../utilities/enums';
import authEnums from '../../../../../../utilities/enums/authEnums';
import IconManager from '../../../../../../utilities/services/IconManager';
import TableHeaders from '../../../../../../utilities/services/TableHeaders';
import {
  AffiliatedEntitiesErrorContext,
  EntityContext,
  PermissionGroupContext,
} from '../../../../../../utilities/services/contexts';
import FilterToggleButton from '../../../../components/FilterToggleButton';
import SearchControls from '../../../../components/SearchControls';
import CSVDownloadModal from '../../../components/CSVDownloadModal';
import EntitiesFilterModal from '../../../components/EntitiesFilterModal';
import IssueList from '../../../components/IssueList';
import AffiliatedEntityModal from './components/AffiliatedEntityModal';
import AffiliatedEntityTableRow from './components/AffiliatedEntityTableRow';
import './styles.scss';

const AffiliatedEntitiesSection = ({ badge }) => {
  const [personells, setPersonells] = useState([]);
  const [personellsPagination, setPersonellPagination] = useState({});
  const [fetching, setFetching] = useState(false);
  const [params, setParams] = useState({ sortOrder: 1, sortCriteria: 'date', skip: 0, limit: 10 });
  const { entityId, affiliatedEntitiesReviewRequired } = useContext(EntityContext);
  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState({
    assignedTo: [],
    countries: [],
    riskLevels: [],
    statuses: [],
  });
  const [reviewPrimaryContactsIssue, setReviewPrimaryContactsIssue] = useState(affiliatedEntitiesReviewRequired);
  const [entityModalOpen, setEntityModalOpen] = useState(false);
  const [error, setError] = useState();
  useEffect(() => {
    async function fetchImportantPersonell() {
      try {
        setFetching(true);

        const {
          data: { data, paginationData: pagination },
        } = await api.kyc.entityManagement.legalEntity.getAffiliatedEntitiesTable({
          ...params,
          filters,
          filterOn,
          entityId,
          limit: 5,
        });
        setPersonellPagination(pagination);
        setPersonells(data);
      } catch (err) {
        utilities.errorHandler(err);
      } finally {
        setFetching(false);
      }
    }
    fetchImportantPersonell();
  }, [params, filters, entityId, filterOn]);

  /**
   * Add new Entity to existing array of Entities
   * @param {Object} newEntity
   */
  const setNewlyAdded = newEntity => {
    setReviewPrimaryContactsIssue(newEntity?.affiliatedEntitiesReviewRequired);
    setPersonells(prev => {
      let newArray = [];
      if (prev.length === 0) newArray.push(newEntity);
      else {
        newArray = [newEntity, ...prev];
      }
      return newArray;
    });
    bus.broadcastEvent(
      enums.BUS_EVENTS.UPDATE_ISSUES,
      {
        section: enums.ACCORDION_INDEXES.AFFILIATED_ENTITIES,
        issuesChange: newEntity.issues,
      },
      [],
    );
    setPersonellPagination(prev => ({ ...prev, total: prev.total + 1 }));
  };

  const handlePersonellSearch = useCallback(value => {
    setParams(prev => ({ ...prev, limit: prev.limit, searchString: value }));
  }, []);

  const containerRef = useRef(null);

  const handleEntityRemove = useCallback(entity => {
    setPersonells(prev => prev.filter(e => e.entityId !== entity.entityId));
    setPersonellPagination(prev => ({ ...prev, total: prev.total - 1 }));
    bus.broadcastEvent(
      enums.BUS_EVENTS.UPDATE_ISSUES,
      {
        section: enums.ACCORDION_INDEXES.AFFILIATED_ENTITIES,
        issuesChange: -entity.issues,
      },
      [],
    );
  }, []);

  const changeParams = useCallback(additionalParams => setParams(prev => ({ ...prev, ...additionalParams })), []);

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => !prev);
  }, []);

  const handleFilterSave = useCallback(values => {
    setFilters(values);
    setFilterOn(true);
  }, []);

  const handleEntityUpdate = useCallback(entity => {
    setPersonells(prev => prev.map(person => (person.entityId === entity.entityId ? entity : person)));
  }, []);

  const { isOpen: isCSVModalOpen, close: closeCSVModal, open: openCSVModal } = useModalHandler();
  const { create: canCreate } = useContext(PermissionGroupContext);
  return (
    <Accordion
      title="Affiliated Entities"
      withBadge
      accordionOpen={false}
      badge={badge}
      accented
      accordionindex={enums.ACCORDION_INDEXES.AFFILIATED_ENTITIES}
      className="ickyc-affiliated-entities-section"
    >
      <AffiliatedEntitiesErrorContext.Provider value={{ error, setError }}>
        <div ref={containerRef}>
          <div className="ickyc-affiliated-entities-section__header">
            {reviewPrimaryContactsIssue && (
              <div className="ickyc-affiliated-entities-section__header__issues-container">
                <IssueList list={['Review primary contacts.']} />
              </div>
            )}
            <p className="ickyc-affiliated-entities-section__header__globalError">{error}</p>
            <div className="ickyc-affiliated-entities-section__header__controls">
              {canCreate && <PrimaryButton onClick={() => setEntityModalOpen(true)}> + Add Entity </PrimaryButton>}
            </div>
          </div>
          {entityModalOpen && (
            <AffiliatedEntityModal
              hideModal={() => setEntityModalOpen(false)}
              containerRef={containerRef}
              setNewlyAdded={setNewlyAdded}
            />
          )}

          <SearchControls>
            <div className="ickyc-search-controls__info">
              <span>
                {personellsPagination.total > 1
                  ? `${personellsPagination.total}  Results`
                  : `${personellsPagination.total} Result`}
              </span>
              <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.FILES_MANAGEMENT}>
                <PrimaryButton variant="link" onClick={openCSVModal}>
                  {IconManager.get(IconManager.names.DOWNLOAD)} Download CSV
                </PrimaryButton>
              </ProtectedComponent>
            </div>
            {isCSVModalOpen && (
              <CSVDownloadModal
                hideModal={closeCSVModal}
                parentEntityId={entityId}
                count={personellsPagination?.total || 0}
                filters={filters}
                filterOn={filterOn}
                csvDownloadRequest={api.kyc.entityManagement.createCSVExport}
              />
            )}
            <div className="ickyc-search-controls__filter-section">
              <DebounceInput
                initialValue={params.searchString}
                placeholder="Search Entities"
                onChange={handlePersonellSearch}
              />
              <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
                <EntitiesFilterModal onFilterSave={handleFilterSave} />
              </FilterToggleButton>
            </div>
          </SearchControls>
          <Table
            values={personells}
            pagination={personellsPagination}
            tableRow={AffiliatedEntityTableRow}
            headerRow={TableHeader}
            className="ickyc-affiliated-enitities-table"
            withPagination
            onControlsClick={setReviewPrimaryContactsIssue}
            updating={fetching}
            handleParamsChange={changeParams}
            headerData={{
              sortCriteria: params.sortCriteria,
              sortOrder: params.sortOrder,
              items: TableHeaders.AffiliatedEntityTable,
            }}
            reviewPrimaryContactsIssue={reviewPrimaryContactsIssue}
            onRemove={handleEntityRemove}
            onUpdate={handleEntityUpdate}
            containerRef={containerRef}
            idPropName={'entityId'}
          />
        </div>
      </AffiliatedEntitiesErrorContext.Provider>
    </Accordion>
  );
};
AffiliatedEntitiesSection.propTypes = {
  badge: PropTypes.number,
};
AffiliatedEntitiesSection.defaultProps = {
  badge: 0,
};
export default AffiliatedEntitiesSection;
