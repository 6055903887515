import classNames from 'classnames';
import useSupportingPackageOptions from 'hooks/useSupportingPackageOptions';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useSelect from '../../../../../../../../../hooks/useSelect';
import { selectDocumentTypes } from '../../../../../../../../../store/selectors/global.selector';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import validators from '../../../../../../../../../utilities/services/validators';
import ModuleOption from '../ModuleOption';
import './styles.scss';

const KYCRequestsLegalEntityForm = ({ index }) => {
  const baseClass = 'ickyc-kyc-requests-legal-entity-form';
  const setSuffix = setClassSuffix(baseClass);
  const documentPackages = useSelect(selectDocumentTypes);
  const { documentPackageOptions } = useSupportingPackageOptions(documentPackages);
  const detailsOptions = [{ label: 'User Enters Manually', value: true, id: 'User Enters Manually' }];
  const dataValidationOptions = [{ label: 'Validate with LEI', value: true, id: 'Validate with LEI' }];

  const { change } = useForm();

  const {
    input: { value: entityDocumentRequired },
  } = useField(`modules[${index}].kycRequests.addressDetailsRequireSupportingDocument`);
  const {
    input: { value: addressDocumentRequired },
  } = useField(`modules[${index}].kycRequests.entityDetailsRequireSupportingDocument`);

  const usersToAssign = useSelect(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );

  const classes = classNames([
    baseClass,
    {
      [setSuffix('--background-gray')]: index % 2 !== 0,
    },
    {
      [setSuffix('--background-white')]: index % 2 === 0,
    },
  ]);
  const indentSectionClasses = classNames(setSuffix('__section-with-select'), setSuffix('__pushed-in-twice'));
  const detailsSectionClasses = classNames(setSuffix('__section-with-select'), setSuffix('__details'));

  useEffect(() => {
    if (!entityDocumentRequired) {
      change(`modules[${index}].kycRequests.addressDetailsSupportingDocumentId`, null);
    }
    if (!addressDocumentRequired) {
      change(`modules[${index}].kycRequests.entityDetailsSupportingDocumentId`, null);
    }
  }, [entityDocumentRequired, addressDocumentRequired]);

  return (
    <ModuleOption className={classes}>
      <div className={setSuffix('__entity-information-settings')}>
        <div className={setSuffix('__section-with-select')}>
          <Field
            component={ToggleField}
            label="Run KYC Service: Entity Data Validation"
            name={`modules[${index}].kycRequests.entityDataValidation`}
          />
          <Field
            component={SelectField}
            name={`modules[${index}].kycRequests.validateWithLei`}
            options={dataValidationOptions}
            Trigger={<SelectTrigger placeholder="Validate" />}
            Content={<SelectFilterContent />}
          />
        </div>
        <Field
          component={ToggleField}
          label="Auto-Accept if No Issues Are Found with Entity Data Validation"
          name={`modules[${index}].kycRequests.entityDataValidationAutoAcceptance`}
        />
        <div className={detailsSectionClasses}>
          <Field component={ToggleField} label="Entity Details" name={`modules[${index}].kycRequests.entityDetails`} />
          <Field
            component={SelectField}
            name={`modules[${index}].kycRequests.entityDetailsUserEntersManually`}
            options={detailsOptions}
            Trigger={<SelectTrigger placeholder="Enter Details" />}
            Content={<SelectFilterContent />}
          />
        </div>
        <Field
          component={ToggleField}
          label="Create an Issue for Each Change"
          name={`modules[${index}].kycRequests.entityDetailsCreateIssues`}
        />
        <div className={indentSectionClasses}>
          <Field
            component={ToggleField}
            label="Require Supporting Document"
            name={`modules[${index}].kycRequests.entityDetailsRequireSupportingDocument`}
          />
          <Field
            component={SelectField}
            options={documentPackageOptions}
            disabled={!addressDocumentRequired}
            name={`modules[${index}].kycRequests.entityDetailsSupportingDocumentId`}
            Trigger={<SelectTrigger placeholder="Document Pakcage" />}
            Content={<SelectFilterContent />}
            validate={validators.customRequiredSupportingDocument(index, 'entityDetailsRequireSupportingDocument')}
          />
        </div>
        <div className={detailsSectionClasses}>
          <Field
            component={ToggleField}
            label="Address Details"
            name={`modules[${index}].kycRequests.addressDetails`}
          />
          <Field
            component={SelectField}
            name={`modules[${index}].kycRequests.addressDetailsUserEntersManually`}
            options={detailsOptions}
            Trigger={<SelectTrigger placeholder="Enter Details" />}
            Content={<SelectFilterContent />}
          />
        </div>
        <Field
          component={ToggleField}
          label="Create an Issue for Each Change"
          name={`modules[${index}].kycRequests.addressDetailsCreateIssues`}
        />
        <div className={indentSectionClasses}>
          <Field
            component={ToggleField}
            label="Require Supporting Document"
            name={`modules[${index}].kycRequests.addressDetailsRequireSupportingDocument`}
          />
          <Field
            component={SelectField}
            options={documentPackageOptions}
            disabled={!entityDocumentRequired}
            name={`modules[${index}].kycRequests.addressDetailsSupportingDocumentId`}
            Trigger={<SelectTrigger placeholder="Document Package" />}
            Content={<SelectFilterContent />}
            validate={validators.customRequiredSupportingDocument(index, 'addressDetailsRequireSupportingDocument')}
            renderAsPortal
          />
        </div>
        <div className={setSuffix('__section-with-select')}>
          <Field component={ToggleField} label="Auto-Assign To:" name={`modules[${index}].kycRequests.autoAssign`} />
          <Field
            component={SelectField}
            options={usersToAssign}
            renderAsPortal
            name={`modules[${index}].kycRequests.autoAssignUserId`}
            Trigger={<SelectTrigger placeholder="Search User" />}
            Content={<SelectFilterContent />}
          />
        </div>
      </div>
    </ModuleOption>
  );
};
KYCRequestsLegalEntityForm.propTypes = {
  index: PropTypes.number.isRequired,
};
export default KYCRequestsLegalEntityForm;
