import CheckboxGroupField from 'components/CheckboxGroupField';
import DocumentDropzone from 'components/DocumentsAndCustomQuestions/components/DocumentDropzone';
import DocumentDropzoneField from 'components/DocumentsAndCustomQuestions/components/DocumentDropzoneField';
import InputField from 'components/InputField';
import RadioGroupField from 'components/RadioGroupFIeld';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TextareaField from 'components/TextareaField';
import React from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';

export const itemTypes = {
  DOCUMENT: 'Document',
  TEXTFIELD: 'TextField',
  CURRENCYFIELD: 'CurrencyField',
  NUMERICALFIELD: 'NumericalField',
  MULTIPLECHOICE: 'MultipleChoice',
  PERCENTAGEFIELD: 'PercentageField',
  DISCRETECHOICE: 'DiscreteChoice',
};

export const extraIdentifiers = {
  CURRENCYSELECT: 'CurrencySelect',
  CURRENCYAMOUNT: 'CurrencyAmount',
};

const prepareDocumentProps = parameters => {
  const acceptedFileTypes = Object.entries(parameters).reduce((acc, [key, value]) => {
    if (value && key.startsWith('restrictTo')) {
      const extension = `.${key.slice(10).toLowerCase()}`;
      acc.push(extension);
      // handle .jpg as well as .jpeg
      extension === '.jpeg' && acc.push('.jpg');
    }
    return acc;
  }, []);

  return { accept: acceptedFileTypes };
};

const prepareMultipleChoiceProps = parameters => {
  const { items } = parameters;
  let initialValue = [];

  const options = items.map(({ acceptedAnswer, preSelected }) => {
    if (preSelected) {
      initialValue.push(acceptedAnswer);
    }
    return {
      value: acceptedAnswer,
      label: acceptedAnswer,
    };
  });

  return { options, initialValue };
};

const prepareDiscreteChoiceProps = parameters => {
  const { items } = parameters;
  let initialValue;

  const options = items.map(({ acceptedAnswer, preselected }) => {
    if (preselected) {
      initialValue = acceptedAnswer;
    }
    return {
      value: acceptedAnswer,
      label: acceptedAnswer,
    };
  });

  return { options, initialValue };
};

const prepareTextareaFieldProps = parameters => {
  const { characterLimit } = parameters;
  return { maxLength: characterLimit };
};

const preparePercentageFieldProps = parameters => {
  const { decimalPlaces } = parameters;
  // return { maxLength: decimal };
};

const prepareCurrencyFieldProps = parameters => {
  const { carecterLimit, items } = parameters;
  const dollar = items.find(item => item.code === 'USD')?.id;
  const options = items.map(({ code, id, decimalPlaces }) => ({ label: code, value: id, id, decimalPlaces }));
  return { maxLength: carecterLimit, options, initialValue: dollar || items[0]?.id };
};

export const typeToProps = (itemType, parameters, extraIdentifier) => {
  const transformedParameters = JSON.parse(parameters);

  switch (itemType) {
    case itemTypes.DOCUMENT:
      return prepareDocumentProps(transformedParameters);
    case itemTypes.MULTIPLECHOICE:
      return prepareMultipleChoiceProps(transformedParameters);
    case itemTypes.DISCRETECHOICE:
      return prepareDiscreteChoiceProps(transformedParameters);
    case itemTypes.TEXTFIELD:
      return prepareTextareaFieldProps(transformedParameters);
    case itemTypes.CURRENCYFIELD:
      const { maxLength, ...selectProps } = prepareCurrencyFieldProps(transformedParameters);
      if (extraIdentifier === extraIdentifiers.CURRENCYAMOUNT) {
        return { maxLength };
      }
      if (extraIdentifier === extraIdentifiers.CURRENCYSELECT) {
        return selectProps;
      }
    case itemTypes.NUMERICALFIELD:
      return prepareTextareaFieldProps(transformedParameters);
    case itemTypes.PERCENTAGEFIELD:
      return preparePercentageFieldProps(transformedParameters);
    default:
      return {};
  }
};
const typeToProperFieldComponent = (item, index) => ({
  [itemTypes.DOCUMENT]: (
    <>
      <Field
        name={`items.${item.id}`}
        validate={item.isRequired && validators.required(' ')}
        component={DocumentDropzoneField}
        required={item.isRequired}
        label={`${index + 1}. ${item.description}`}
        uploadText="Upload Document"
        {...typeToProps(item.type, item.parameters)}
      />
      {item.documentFullPath && (
        <DocumentDropzone
          value={{ name: 'Download Template', blobUrl: item.documentFullPath }}
          preview
          downloadText="Download Template"
        />
      )}
    </>
  ),
  [itemTypes.TEXTFIELD]: (
    <Field
      name={`items.answers.${item.id}.${itemTypes.TEXTFIELD}.typedText`}
      validate={item.isRequired && validators.required(' ')}
      component={TextareaField}
      required={item.isRequired}
      label={`${index + 1}. ${item.description}`}
      labelPosition={'top'}
      {...typeToProps(item.type, item.parameters)}
    />
  ),
  [itemTypes.CURRENCYFIELD]: (
    <div className="ickyc-package-items-fields__currency">
      <Field
        component={SelectField}
        name={`items.answers.${item.id}.${itemTypes.CURRENCYFIELD}.currency`}
        validate={item.isRequired && validators.required(' ')}
        required={item.isRequired}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
        labelPosition="top"
        label={`${index + 1}. ${item.description}`}
        {...typeToProps(item.type, item.parameters, extraIdentifiers.CURRENCYSELECT)}
      />
      <Field
        name={`items.answers.${item.id}.${itemTypes.CURRENCYFIELD}.amount`}
        validate={item.isRequired && validators.required(' ')}
        component={InputField}
        required={item.isRequired}
        topLabeled
        positiveNumbersOnly
        {...typeToProps(item.type, item.parameters, extraIdentifiers.CURRENCYAMOUNT)}
        labelPosition={'top'}
      />
    </div>
  ),
  [itemTypes.NUMERICALFIELD]: (
    <Field
      name={`items.answers.${item.id}.${itemTypes.NUMERICALFIELD}.insertedNumber`}
      validate={item.isRequired && validators.required(' ')}
      component={InputField}
      required={item.isRequired}
      label={`${index + 1}. ${item.description}`}
      topLabeled
      labelPosition="top"
      type="number"
      {...typeToProps(item.type, item.parameters)}
    />
  ),
  [itemTypes.MULTIPLECHOICE]: (
    <div className="ickyc-package-items-fields__multiple-choice">
      <Field
        name={`items.answers.${item.id}.${itemTypes.MULTIPLECHOICE}.acceptedAnswers`}
        validate={item.isRequired && validators.required(' ')}
        component={CheckboxGroupField}
        required={item.isRequired}
        label={`${index + 1}. ${item.description}`}
        {...typeToProps(item.type, item.parameters)}
      />
      <Field
        name={`items.answers.${item.id}.${itemTypes.MULTIPLECHOICE}.otherAnswer`}
        validate={item.isRequired && validators.required(' ')}
        component={InputField}
        required={item.isRequired}
        label="Other Answer"
        topLabeled
        labelPosition={'top'}
      />
    </div>
  ),
  [itemTypes.PERCENTAGEFIELD]: (
    <Field
      name={`items.answers.${item.id}.${itemTypes.PERCENTAGEFIELD}.insertedNumber`}
      validate={item.isRequired && validators.required(' ')}
      component={InputField}
      required={item.isRequired}
      label={`${index + 1}. ${item.description}`}
      topLabeled
      type="number"
      min={0}
      max={100}
      preffix="%"
    />
  ),
  [itemTypes.DISCRETECHOICE]: (
    <div className="ickyc-package-items-fields__discrete-choice">
      <Field
        name={`items.answers.${item.id}.${itemTypes.DISCRETECHOICE}.acceptedAnswer`}
        validate={item.isRequired && validators.required(' ')}
        component={RadioGroupField}
        required={item.isRequired}
        label={`${index + 1}. ${item.description}`}
        {...typeToProps(item.type, item.parameters)}
      />
      <Field
        name={`items.answers.${item.id}.${itemTypes.DISCRETECHOICE}.otherAnswer`}
        validate={item.isRequired && validators.required(' ')}
        component={InputField}
        required={item.isRequired}
        label="Other Answer"
        topLabeled
        labelPosition={'top'}
      />
    </div>
  ),
});

export default typeToProperFieldComponent;
