import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DebounceInput from 'components/DebounceInput';
import BareHeading from 'components/Headings/BareHeading';
import CheckboxTable from 'components/Tables/CheckboxTable';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import useSelect from 'hooks/useSelect';
import useTable from 'hooks/useTable';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import { selectDefaultScreeningProfile } from 'store/selectors/global.selector';
import authEnums from 'utilities/enums/authEnums';
import socketKeys from 'utilities/enums/socketKeys';
import IconManager from 'utilities/services/IconManager';
import SignalRService from 'utilities/services/SignalRService';
import TableHeaders from 'utilities/services/TableHeaders';
import { PermissionGroupContext } from 'utilities/services/contexts';
import FilterToggleButton from '../components/FilterToggleButton';
import SearchModal from '../components/SearchModal';
import BatchActionsCaseModal from './BatchActionsCaseModal';
import CasesFilterModal from './components/CasesFilterModal';
import CasesTableRow from './components/CasesTableRow';
import './styles.scss';

const CaseManagement = ({ className }) => {
  const classBase = 'ickyc-case-management';
  const classes = classNames(className, ['ickyc-kyc-page', `${classBase}`]);

  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState({
    assignesTo: [],
    attched: [],
    riskTypes: [],
    statuses: [],
    ongoing: false,
    runBy: [],
    potentialIssues: false,
  });
  const [selectedCaseIds, setSelectedCaseIds] = useState([]);
  const [updatingRows, setUpdatingRows] = useState([]);

  const {
    isOpen: isBatchActionsModalOpen,
    close: closeBatchActionsModal,
    open: openBatchActionsModal,
  } = useModalHandler();
  const defaultScreeningProfileSettings = useSelect(selectDefaultScreeningProfile);
  const { state } = useLocation();

  const { isOpen: isCaseModalOpen, open: openCaseModal, close: closeCaseModal } = useModalHandler();

  const { ongoingMonitoring: canOngoingMonitoring, status: canStatus } = useContext(PermissionGroupContext);

  const platformAccessByLicence = useSelect(selectUserAccessByLicense);

  const initialParams = useMemo(
    () => ({
      skip: 0,
      limit: 10,
      sortCriteria: 'created',
      sortOrder: 1,
      searchString: null,
    }),
    [],
  );

  // TODO: Replace with sockets when they are implemented on the BE
  const refetchData = useCallback((apiRequest, cases) => {
    if (cases.find(caseItem => caseItem.processing === true)) {
      setTimeout(() => {
        apiRequest(false);
      }, 1500);
    }
  }, []);

  const {
    pagination,
    params,
    records: cases,
    removeRow,
    isLoading: isFetching,
    addRows,
    changeParams,
    updateRowData,
    overrideTable,
  } = useTable(api.kyc.caseManagement.getCaseManagementTable, initialParams, 'data');

  const handleCaseRemove = useCallback(
    caseIds => {
      if (Array.isArray(caseIds) && caseIds.length) {
        caseIds.forEach(elm => {
          removeRow('id', elm);
        });
      }
    },
    [removeRow],
  );

  const applySearchTerm = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );

  const setNewlyAdded = useCallback(
    caseObj => {
      addRows(caseObj);
    },
    [addRows],
  );

  const tableHeaderItems = useMemo(() => {
    let items = TableHeaders.CaseManagementTable;
    if (!canOngoingMonitoring) {
      items = items.filter(item => item.type !== 'ongoing');
    }
    if (!canStatus) {
      items = items.filter(item => item.type !== 'status');
    }
    if (!platformAccessByLicence.some(el => el === authEnums.ACCESS_BY_LICENCE.ENTITIES)) {
      items = items.filter(item => item.type !== 'attachedTo');
    }
    return items;
  }, [canOngoingMonitoring, canStatus, platformAccessByLicence]);

  const tableCotrols = () => {
    return <></>;
  };

  const handleBatchAction = data => {
    if (!Array.isArray(data) || !data.length) return;

    const newCases = cases.map(caseData => ({
      ...caseData,
      ...(data.find(({ id }) => id === caseData?.id) || {}),
      checked: selectedCaseIds?.some(caseId => caseId === caseData?.id),
    }));

    overrideTable(newCases);
  };

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => {
      changeParams({ filterOn: !prev });
      return !prev;
    });
  }, [changeParams]);

  const handleFilterSave = useCallback(
    values => {
      setFilters(values);
      changeParams({ filters: values, filterOn: true });
      setFilterOn(true);
    },
    [changeParams],
  );

  const handleSelectedItemsChange = selectedCases => {
    setSelectedCaseIds(selectedCases);
  };

  const disableBatchActions = useMemo(() => {
    return selectedCaseIds?.length === 0;
  }, [selectedCaseIds]);

  const handleBatchActionFromModal = useCallback(
    data => {
      const { createdCases, updatedCases } = data;
      if (createdCases?.length) {
        setNewlyAdded(createdCases);
      }
      if (updatedCases?.length) {
        updatedCases.forEach(elm => {
          updateRowData('id', elm?.id, {
            checked: updatedCases.length > 1,
            ...elm,
          });
        });
      }
    },
    [setNewlyAdded, updateRowData],
  );

  const tableRowSocketUpdate = useCallback(
    ({ id, ...rest }) => {
      updateRowData('id', id, {
        ...rest,
      });
    },
    [updateRowData],
  );

  useEffect(() => {
    SignalRService.receiveMessage(socketKeys.CASE_READY, tableRowSocketUpdate);
  }, []);

  useEffect(() => {
    if (state?.openFromHome || state?.openFromDashboard) openCaseModal();
  }, [state, openCaseModal]);

  return (
    <div className={classes}>
      <BareHeading title="Case Management" />
      <div className="ickyc-case-management__header">
        <h2>Cases</h2>
        <div className="ickyc-case-management__header__controls">
          <DebounceInput onChange={applySearchTerm} placeholder="Search Cases" />
          <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
            <CasesFilterModal onFilterSave={handleFilterSave} />
          </FilterToggleButton>
          <Tooltip
            placement="top"
            trigger={
              <div>
                <PrimaryButton onClick={openBatchActionsModal} disabled={disableBatchActions}>
                  {IconManager.get(IconManager.names.BATCH_ACTIONS)} Batch Actions
                </PrimaryButton>
              </div>
            }
            content={<span>Action all selected cases at once.</span>}
          />

          <PrimaryButton onClick={openCaseModal}>+ Add Case</PrimaryButton>
        </div>
      </div>
      {isCaseModalOpen && (
        <SearchModal
          hideModal={closeCaseModal}
          setNewlyAdded={setNewlyAdded}
          disableAttachTo
          initialValues={defaultScreeningProfileSettings}
        />
      )}
      {isBatchActionsModalOpen && (
        <BatchActionsCaseModal
          hideModal={closeBatchActionsModal}
          onBatchAction={handleBatchActionFromModal}
          complianceLogsIds={selectedCaseIds}
        />
      )}
      <CheckboxTable
        TableControlsComponent={tableCotrols}
        values={cases}
        onSelectedItemsChange={handleSelectedItemsChange}
        onBatchAction={handleBatchAction}
        disabled={false}
        pagination={pagination}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          items: tableHeaderItems,
          withDelete: true,
        }}
        withLimitChange
        headerRow={TableHeader}
        tableRow={CasesTableRow}
        updatingRows={updatingRows}
        setUpdatingRows={setUpdatingRows}
        withPagination
        handleParamsChange={changeParams}
        updating={isFetching}
        onRemove={handleCaseRemove}
        className="ickyc-case-management-table"
      />
    </div>
  );
};

CaseManagement.propTypes = {
  className: PropTypes.string,
};

CaseManagement.defaultProps = {
  className: undefined,
};

export default CaseManagement;
