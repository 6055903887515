import api from 'api';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import useTable from 'hooks/useTable';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TableHeaders from 'utilities/services/TableHeaders';
import { DragContext } from 'utilities/services/contexts';
import Table from '../Tables/Table';
import DocumentsAndCustomQuestionsTableRow from './components/DocumentsAndCustomQuestionsTableRow';
import './styles.scss';
import { NEW_PACKAGE_ITEM_ID } from './utils';

// Custom questions are disabled for phase 1
// of supporting documents

const DocumentsAndCustomQuestions = ({ packageId }) => {
  const [remainingSupportingDocumentsCount, setRemainingSupportingDocumentsCount] = useState(0);
  const {
    isLoading,
    records,
    setRecords,
    changeParams,
    pagination,
    params,
    addRow,
    removeRow,
    updateRowData,
  } = useTable(api.administration.supportingDocumentPackages.getSupportingDocumentPackagesItems, { packageId });

  const bottomDivRef = useRef(null);

  const handleAddNewDocument = useCallback(() => {
    if (bottomDivRef.current) {
      bottomDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    addRow(
      {
        type: 'Document',
        packageId: packageId,
        isRequired: false,
        parameters: {
          restrictToJPEG: true,
          restrictToPNG: true,
          restrictToPDF: true,
          restrictToDOC: true,
          requireReupload: false,
        },
        id: NEW_PACKAGE_ITEM_ID,
      },
      'last',
    );
  }, [addRow, packageId]);

  const handleAddNewQuestion = useCallback(() => {
    if (bottomDivRef.current) {
      bottomDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    addRow(
      {
        type: 'TextField',
        packageId: packageId,
        isRequired: false,
        parameters: {},
        id: NEW_PACKAGE_ITEM_ID,
      },
      'last',
    );
  }, [addRow, packageId]);
  const mapRecords = useMemo(
    () =>
      records.map(record => {
        if (record?.type !== 'CurrencyField') {
          return {
            ...record,
            parameters: typeof record?.parameters === 'string' ? JSON.parse(record?.parameters) : record?.parameters,
          };
        }

        const { items, characterLimit } = JSON.parse(record?.parameters);

        const currencyParams = {
          characterLimit,
          ...items.reduce(
            (acc, curr) => ({
              ...acc,
              [`currency${curr?.id}`]: curr,
            }),
            {},
          ),
        };

        return { ...record, parameters: currencyParams };
      }),
    [records],
  );

  const dropCallback = useCallback(async (reorderedList, droppedElement) => {
    const newOrder = reorderedList.findIndex(item => item.id === droppedElement.id);

    try {
      await api.administration.supportingDocumentPackages.reorderDocumentPackageItems({
        itemId: droppedElement.id,
        newOrder,
      });
      setRecords(reorderedList);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const {
        data: count,
      } = await api.administration.supportingDocumentPackages.getRemainingSupportingDocumentsCount();
      setRemainingSupportingDocumentsCount(count);
    })();
  }, []);

  const TableControls = () => {
    return (
      <div className="documents-and-custom-questions__header">
        <h3>Documents + Custom Questions</h3>
        <div>
          <PrimaryButton disabled={remainingSupportingDocumentsCount === 0} onClick={handleAddNewDocument}>
            + Add New Document
          </PrimaryButton>
          <PrimaryButton disabled={remainingSupportingDocumentsCount === 0} onClick={handleAddNewQuestion}>
            + Add New Question
          </PrimaryButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="documents-and-custom-questions">
        <DragContext.Provider
          value={{
            dragList: records,
            dropCallback,
            shouldSwap: true,
          }}
        >
          <Table
            values={mapRecords}
            tableControls={TableControls}
            pagination={pagination}
            tableRow={DocumentsAndCustomQuestionsTableRow}
            onRemove={removeRow}
            withPagination
            updating={isLoading}
            handleParamsChange={changeParams}
            headerRow={TableHeader}
            headerData={{
              items: TableHeaders.DocumentsAndCustomQuestionsTableHeader,
            }}
            updateRowData={updateRowData}
            {...params}
          />
        </DragContext.Provider>
      </div>
      <div ref={bottomDivRef} />
    </>
  );
};
export default DocumentsAndCustomQuestions;
