import classNames from 'classnames';
import BareHeading from 'components/Headings/BareHeading';
import PropTypes from 'prop-types';
import React from 'react';
import BundleDiscountsSection from './components/BundleDiscountsSection';
import WebProductsSection from './components/WebProductsSection';

const WebPricing = ({ className }) => {
  const classes = classNames('ickyc-page', className);

  return (
    <div className={classes}>
      <BareHeading title="Web Pricing" />
      <BundleDiscountsSection />
      <WebProductsSection />
    </div>
  );
};

WebPricing.propTypes = {
  className: PropTypes.string,
};

WebPricing.defaultProps = {
  className: '',
};
export default WebPricing;
