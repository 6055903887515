import api from 'api';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setToken, setUserPermissions, setUserType } from 'store/actions/auth.action';
import authEnums from 'utilities/enums/authEnums';
import setClassSuffix from 'utilities/services/ClassManager';
import tokenHandler from 'utilities/services/local-storage/TokenStorage';
import { v4 as uuid } from 'uuid';
import NewPasswordForm from '../NewPassword';
import Signin from '../SignIn';

/**
 * login page
 */

const Login = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [resetToken, setResetToken] = useState(false);
  const dispatch = useDispatch();
  const [errorActivatingAccount, setErrorActivatingAccount] = useState();
  const history = useHistory();

  useEffect(() => {
    const confirmEmail = (_token, _code) => {
      api.auth
        .confirmEmail({ token: _token, code: _code })
        .then(response => {
          const { data } = response;
          const { twoFactorAuthentication } = data;
          tokenHandler.token = data.token;
          tokenHandler.refreshToken = data.refreshToken;
          // Saving token inside redux
          dispatch(setToken(data.token));
          dispatch(setUserPermissions(data.permissions));
          dispatch(setUserType(data.type));
          if (twoFactorAuthentication) {
            if (twoFactorAuthentication === authEnums.TWO_FACTOR_AUTHENTICATION.EnabledWithAuthentificator) {
              history.push(`/auth/two-factor-authentication/code-confirmation?token=${data.token}`);
            } else {
              history.push('/kyc/home-screen');
            }
          } else {
            history.push('/kyc/home-screen');
          }
        })
        .catch(() => {
          setErrorActivatingAccount('Something went wrong, please try again later');
        });
    };

    const params = new URLSearchParams(window.location.search);
    const resetTokenParam = params.get('resetToken');
    const codeParam = params.get('code');
    const tokenParam = params.get('token');

    // activation link
    if (tokenParam && codeParam) {
      confirmEmail(tokenParam.toString(), codeParam.toString());
      return;
    }
    if (resetTokenParam) {
      setResetToken(resetTokenParam);
      setActiveTab(1);
      return;
    }

    setActiveTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const tabs = [
    {
      title: 'Sign In',
      component: <Signin />,
      onClick: () => setActiveTab(0),
    },
    {
      component: <NewPasswordForm setResetTokenError={setErrorActivatingAccount} resetToken={resetToken} />,
    },
  ];

  const baseClass = 'ickyc-widget-main';
  const setSuffix = setClassSuffix(baseClass);

  const activeTabClasses = () =>
    classNames({
      [setSuffix('__content__tab__cell')]: true,
    });

  return errorActivatingAccount ? (
    <h3>{errorActivatingAccount}</h3>
  ) : (
    <>
      <div className={setSuffix('__content__tab')}>
        {activeTab !== 1 &&
          tabs.map((tab, index) => {
            return (
              tab.title && (
                <div key={uuid()} className={activeTabClasses(index)} onClick={tab.onClick}>
                  {tab.title}
                </div>
              )
            );
          })}
      </div>
      {tabs[activeTab].component}
    </>
  );
};

export default Login;
