import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import LicenceModule from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceModule';

const TechnicalModule = ({ namePreffix, disabled, ...rest }) => {
  return (
    <LicenceModule
      title="Technical"
      headeritems={
        <>
          <Field
            component={ToggleField}
            name={`${namePreffix}.technical.webhooksEnabled`}
            label="Webhooks"
            disabled={disabled}
          />
          <Field
            component={ToggleField}
            name={`${namePreffix}.technical.apiEnabled`}
            label="API Manager"
            disabled={disabled}
          />
        </>
      }
      {...rest}
    />
  );
};

TechnicalModule.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};

TechnicalModule.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default TechnicalModule;
