import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ThirdPartyConfirm from './components/ThirdPartyConfirm';
import ThirdPartyDeleteAddress from './components/ThirdPartyDeleteAddress';
import ThirdPartyDeleteDocument from './components/ThirdPartyDeleteDocument';
import ThirdPartyEdit from './components/ThirdPartyEdit';
import ThirdPartyEditDocument from './components/ThirdPartyEdit/components/ThirdPartyEditDocument';
import ThirdPartyReport from './components/ThirdPartyReport';
import ThirdPartyReportDocument from './components/ThirdPartyReportDocument';
import './styles.scss';

const ThirdPartyConfirmation = ({ data, onDataFetch, onRemove, isSupportingDocument }) => {
  const {
    suggestionType,
    thirdPartyInfo,
    editedAddressEvent,
    editedCorporateEvent,
    editedSupportingDoc,
  } = data?.thirdPartySuggestion;

  const classBase = 'ickyc-third-party-confirmation';

  const thirdPartyComponent = useMemo(() => {
    const commonProps = { data, onDataFetch };

    switch (suggestionType) {
      case 0:
        return <ThirdPartyConfirm thirdPartyInfo={thirdPartyInfo} />;
      case 1:
        if (isSupportingDocument) {
          return <ThirdPartyReportDocument {...commonProps} />;
        }
        return <ThirdPartyReport {...commonProps} />;
      case 2:
        if (isSupportingDocument && !editedSupportingDoc) {
          return <ThirdPartyDeleteDocument {...commonProps} onRemove={onRemove} />;
        }
        if (isSupportingDocument) {
          return <ThirdPartyEditDocument {...commonProps} />;
        }
        if (!editedAddressEvent && !editedCorporateEvent) {
          return <ThirdPartyDeleteAddress {...commonProps} onRemove={onRemove} />;
        }
        return <ThirdPartyEdit {...commonProps} />;
      case 3:
        if (isSupportingDocument) {
          return (
            <>
              <ThirdPartyReportDocument {...commonProps} />
              <ThirdPartyEditDocument {...commonProps} />
            </>
          );
        }
        if (!editedAddressEvent && !editedCorporateEvent) {
          return (
            <>
              <ThirdPartyReport {...commonProps} />
              <ThirdPartyDeleteAddress {...commonProps} onRemove={onRemove} />
            </>
          );
        }
        return (
          <>
            <ThirdPartyReport {...commonProps} />
            <ThirdPartyEdit {...commonProps} />
          </>
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <div className={classBase}>{thirdPartyComponent}</div>;
};

ThirdPartyConfirmation.propTypes = {
  data: PropTypes.object,
  onDataFetch: PropTypes.func,
  onRemove: PropTypes.func,
  isSupportingDocument: PropTypes.bool,
};

ThirdPartyConfirmation.defaultProps = {
  data: {},
  onDataFetch: () => {},
  onRemove: () => {},
  isSupportingDocument: false,
};

export default ThirdPartyConfirmation;
