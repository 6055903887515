import api from 'api';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import toastr from 'toastr';
import licenceEnums from 'utilities/enums/licenceEnums';
import AMLProduct from '../AMLProduct';
import IDDProduct from '../IDDProduct';

import './styles.scss';

const ActiveProductsTab = ({ isActive }) => {
  const { batch, change } = useForm();
  const [renderItems, setRenderItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setIsLoading(true);
        const {
          data: { data: products },
        } = await api.internal.webProducts.getWebProducts(isActive === 0);

        setRenderItems([]);

        batch(() => {
          products
            .sort((productA, productB) => productA.type - productB.type)
            .forEach(product => {
              change(product.id, product);
              if (product.type === licenceEnums.PRODUCT_TYPES.AML) {
                setRenderItems(prev => [
                  ...prev,
                  <AMLProduct key={product.id} namePreffix={product.id} isActive={!isActive} disabled />,
                ]);
              } else if (product.type === licenceEnums.PRODUCT_TYPES.IDD) {
                setRenderItems(prev => [
                  ...prev,
                  <IDDProduct key={product.id} namePreffix={product.id} isActive={!isActive} disabled />,
                ]);
              }
            });
        });
      } catch (err) {
        toastr.error('Error occured while getting products');
      } finally {
        setIsLoading(false);
      }
    };

    getProducts();
  }, [isActive]);

  return (
    <div className="active-products-tab">
      {isLoading && <Loader />}
      {renderItems}
    </div>
  );
};

ActiveProductsTab.propTypes = {
  classNames: PropTypes.string,
  isActive: PropTypes.number,
};

ActiveProductsTab.defaultProps = {
  classNames: '',
  isActive: 0,
};

export default ActiveProductsTab;
