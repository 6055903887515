import React from 'react';

export default () => (
  <svg
    clip-rule="evenodd"
    fill-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <g transform="translate(-20 -20)">
      <path d="m31.002 24.001v-.002c0-.552.448-1 .999-1s.999.448.999 1v.003c0 1.856.737 3.636 2.05 4.948 1.302 1.302 3.064 2.038 4.904 2.05.016-.001.031-.001.047-.001.551 0 .999.448.999 1 0 .553-.448 1.001-.999 1.001h-.003c-1.856 0-3.636.737-4.948 2.05-1.313 1.312-2.05 3.092-2.05 4.948v.001c0 .553-.448 1.001-.999 1.001-.526 0-.958-.409-.996-.926-.003-.024-.005-.049-.005-.076 0-1.856-.737-3.636-2.05-4.948-1.312-1.313-3.092-2.05-4.948-2.05h-.001c-.551 0-.999-.448-.999-1.001 0-.552.448-1 .999-1 .016 0 .031 0 .047.001 1.839-.012 3.601-.748 4.902-2.05 1.313-1.312 2.05-3.092 2.05-4.948zm-4 15.943v.056c0 .552-.448 1-1 1s-1-.448-1-1c0-.553-.449-1-1.002-1h-.002c-.551-.001-.998-.449-.998-1 0-.552.448-1 1-1 .553 0 1.002-.447 1.002-1 0-.552.448-1 1-1s1 .448 1 1v.056c.029.526.466.944 1 .944.552 0 1 .448 1 1 0 .551-.447.999-.998 1h-.002c-.534 0-.971.418-1 .944zm4.998-11.821c.422.82.971 1.577 1.635 2.242.665.664 1.422 1.213 2.242 1.635-.82.422-1.577.971-2.242 1.635-.664.665-1.213 1.422-1.635 2.242-.422-.82-.971-1.577-1.635-2.242-.665-.664-1.422-1.213-2.242-1.635.82-.422 1.577-.971 2.242-1.635.664-.665 1.213-1.422 1.635-2.242zm6.999-.18c.001.019.001.037.001.056 0 .552-.448 1-1 1s-1-.448-1-1c0-.553-.449-1-1.002-1h-.002c-.551-.001-.998-.449-.998-1 0-.552.448-1 1-1 .553 0 1.002-.448 1.002-1s.448-1 1-1 1 .448 1 1c0 .018 0 .037-.001.055.029.527.467.945 1.001.945.552 0 1 .448 1 1 0 .551-.447.999-.998 1h-.002c-.534 0-.972.417-1.001.944z" />
    </g>
  </svg>
);
