import axiosInstance from '../../axiosInstance';

const WEB_PRODUCTS_ROUTE = '/internal/web-products';
const WEB_PRICING_ROUTE = '/internal/web-pricing';

const ENDPOINTS = {
  getWebProducts: isActive => `${WEB_PRODUCTS_ROUTE}/all?active=${isActive}`,
  archiveWebProduct: id => `${WEB_PRODUCTS_ROUTE}/${id}/archive`,

  getOneTimeSetupFee: () => `${WEB_PRICING_ROUTE}/one-time`,
  discounts: () => `${WEB_PRICING_ROUTE}/discounts`,
};

const getWebProducts = isActive => axiosInstance.post(ENDPOINTS.getWebProducts(isActive));
const archiveWebProduct = id => axiosInstance.put(ENDPOINTS.archiveWebProduct(id));

const getOneTimeSetupFee = () => axiosInstance.get(ENDPOINTS.getOneTimeSetupFee());
const setOneTimeSetupFee = price => axiosInstance.put(ENDPOINTS.getOneTimeSetupFee(), price);

const getDiscounts = () => axiosInstance.get(ENDPOINTS.discounts());
const setDiscounts = discounts => axiosInstance.put(ENDPOINTS.discounts(), discounts);

export default {
  getWebProducts,
  archiveWebProduct,
  getOneTimeSetupFee,
  setOneTimeSetupFee,
  getDiscounts,
  setDiscounts,
};
