import classNames from 'classnames';
import IconButton from 'components/Buttons/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import IconManager from 'utilities/services/IconManager';

import './styles.scss';

const LicenceModule = props => {
  const { className, title, headeritems, children, handleHide } = props;

  const classes = classNames('licence-module', className);

  return (
    <div className={classes}>
      <div
        className={classNames('licence-module__header', {
          'licence-module__header--with-divider': children,
        })}
      >
        <div className="licence-module__title">
          <h3>{title}</h3>
          {handleHide && (
            <IconButton onClick={handleHide} transparent>
              {IconManager.get(IconManager.names.MINUS_CIRCULAR)}
            </IconButton>
          )}
        </div>
        {headeritems}
      </div>
      {children && <div className="licence-module__content">{children}</div>}
    </div>
  );
};

LicenceModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  headeritems: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  handleHide: PropTypes.func,
};

export default LicenceModule;
