import api from 'api';
import classNames from 'classnames';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useParams } from 'react-router';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import './styles.scss';

const ThirdPartyOptionsForm = ({ index }) => {
  const [domainOptions, setDomainOptions] = useState(null);
  const { clientId } = useParams();

  const {
    input: { onChange: onChangeNpConfigId },
  } = useField(`modules[${index}].npConfigId`);

  const baseClass = 'ickyc-third-party-options-form';
  const setSuffix = setClassSuffix(baseClass);
  const classes = classNames([
    baseClass,
    {
      [setSuffix('--background-gray')]: index % 2 !== 0,
    },
  ]);

  useEffect(() => {
    (async () => {
      try {
        let response = null;

        if (clientId) {
          response = await api.businessManager.getDomainsForNaturalPerson(clientId);
        } else {
          response = await api.administration.portalManagement.getDomainsForNaturalPerson();
        }

        setDomainOptions(response.data);
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedDomainsList = useMemo(
    () =>
      (domainOptions || [])
        ?.reduce((acc, domain) => [...acc, { url: domain?.domains[0]?.url, id: domain?.id }], [])
        .map(domain => ({
          id: domain.id,
          value: domain.url,
          label: domain.url,
        })),
    [domainOptions],
  );

  return (
    <div className={classes}>
      <Field
        component={SelectField}
        name={`modules[${index}].thirdPartyDomain`}
        Trigger={
          <SelectTrigger placeholder={`${domainOptions ? 'Choose Natural Person Portal' : 'Loading domains...'}`} />
        }
        onChange={e => {
          const configId = mappedDomainsList.find(domain => domain?.value === e)?.id;
          onChangeNpConfigId(configId || null);
        }}
        Content={<SelectContent />}
        options={[{ id: 'something', value: null, label: 'Unassign Portal' }, ...mappedDomainsList]}
        validate={validators.required()}
      />
      <Field
        component={ToggleField}
        name={`modules[${index}].requireNaturalPersonVerification`}
        label="Require a Natural Person identity verification on Third Party"
      />
      <Field
        component={ToggleField}
        name={`modules[${index}].requireTPDeclaration`}
        label="Require Third Party to declare BAR Association and Registration Number"
      />
      <Field
        component={ToggleField}
        name={`modules[${index}].allowTPDataManipulation`}
        label="Allow the Third Party to Edit or Update Legal Entity Data in the KYC Portal"
      />
    </div>
  );
};
ThirdPartyOptionsForm.propTypes = {
  index: PropTypes.number.isRequired,
};
export default ThirdPartyOptionsForm;
