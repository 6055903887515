import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import Accordion from '../../../../../../components/Accordion';
import RadioButtonField from '../../../../../../components/RadioButtonField';
import Section from '../../../../../../components/Section';
import SelectFilterContent from '../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../components/SelectField';
import { FieldCheckBoxTable } from '../../../../../../components/Tables/CheckboxTable';
import FieldCountriesList from '../../../../../../components/Tables/FieldCountriesList';
import ToggleField from '../../../../../../components/ToggleField';
import useSelect from '../../../../../../hooks/useSelect';
import ProtectedComponent from '../../../../../../router/components/ProtectedComponent';
import { selectUserAccessByLicense } from '../../../../../../store/selectors/auth.selector';
import enums from '../../../../../../utilities/enums';
import authEnums from '../../../../../../utilities/enums/authEnums';
import { SCREENING_SOURCES } from '../../../../../Kyc/CaseManagement/enums';
import AutoAssignementControls from '../../../../components/AutoAssignementControlButtons';
import AutoAssignementRow from '../../../../components/AutoAssignementRow';
import SourceAssignementRow from './components/SourceAssignementRow';
import './index.scss';

const ScreeningSourcesTableRow = ({ text, className, isBold }) => {
  return <td className={`${className}__text ${isBold && `${className}__text--bold`}`}>{text}</td>;
};

ScreeningSourcesTableRow.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  isBold: PropTypes.bool.isRequired,
};

const CaseAssignement = () => {
  const classsBase = 'ickyc-risk-screening-case-assignement';
  const {
    values: { assignmentType, assignedBySource, sources, appliedNationalities },
  } = useFormState();

  const convertToNumber = useCallback(value => Number(value), []);

  const platformAccessByLicence = useSelect(selectUserAccessByLicense);
  const usersToAssign = useSelector(state =>
    platformAccessByLicence.includes(authEnums.ACCESS_BY_LICENCE.ENTITIES)
      ? [
          {
            label: 'Same User Assigned To KYC Profile',
            value: 'same-user-assigned-to-kyc-profile',
            id: 'same-user-assigned-to-kyc-profile',
            isBold: true,
          },
        ].concat(
          state.global.usersToAssign.map(({ id: uId, name }) => ({
            label: name,
            value: uId,
            id: uId,
          })),
        )
      : state.global.usersToAssign.map(({ id: uId, name }) => ({
          label: name,
          value: uId,
          id: uId,
        })),
  );

  const ssTableOptions = useMemo(() => {
    let sourceList = Object.keys(SCREENING_SOURCES).filter(src => {
      return sources.includes(Number(src));
    });
    if (sourceList.some(el => Number(el) === 16)) {
      sourceList = sourceList.filter(src => Number(src) !== 16);
      sourceList.unshift('16');
    }
    return sourceList.map(src => {
      const loadedValue = assignedBySource.find(as => as.id.toString() === src.toString());
      return {
        ...loadedValue,
        label: SCREENING_SOURCES[src],
        id: src,
        isBold: [16, 3, 8].includes(Number(src)),
      };
    });
  }, [sources, assignedBySource]);

  const idsToInclude = Object.entries(appliedNationalities)
    .filter(entry => !!entry[1].enabled)
    .map(entry => Number(entry[0].slice('country'.length, entry[0].length)));

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.AUTO_ACCEPTANCE_ENTITY_STATUSES).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  return (
    <div className={classsBase}>
      <div>
        <Field
          name="assignmentType"
          parse={convertToNumber}
          component={RadioButtonField}
          label={<h2>Assign All to</h2>}
          type="radio"
          value={enums.RISK_SCREENING_ASSIGNEMENT_TYPES.AssignAllTo}
        />
        <Field
          name="assignmentUserId"
          component={SelectField}
          options={usersToAssign}
          Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
          Content={<SelectFilterContent />}
        />
      </div>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <Accordion
          title={
            <Field
              name="assignmentType"
              type="radio"
              parse={convertToNumber}
              component={RadioButtonField}
              label={<h2>Assign By Jurisdiction</h2>}
              value={enums.RISK_SCREENING_ASSIGNEMENT_TYPES.AssignByJurisdiction}
            />
          }
          accordionOpen={assignmentType === enums.RISK_SCREENING_ASSIGNEMENT_TYPES.AssignByJurisdiction}
        >
          <FieldCountriesList
            modulePreffix="appliedNationalities"
            TableControlsComponent={AutoAssignementControls}
            tableRow={AutoAssignementRow}
            idsToInclude={idsToInclude}
          />
        </Accordion>
      </ProtectedComponent>
      <Accordion
        title={
          <Field
            name="assignmentType"
            parse={convertToNumber}
            type="radio"
            component={RadioButtonField}
            label={<h2>Assign By Source</h2>}
            value={enums.RISK_SCREENING_ASSIGNEMENT_TYPES.AssignBySource}
          />
        }
        accordionOpen={assignmentType === enums.RISK_SCREENING_ASSIGNEMENT_TYPES.AssignBySource}
      >
        <Field
          name="assignedBySource"
          render={({ input }) => (
            <FieldCheckBoxTable
              TableControlsComponent={AutoAssignementControls}
              tableRow={SourceAssignementRow}
              values={ssTableOptions}
              onChange={input.onChange}
              className="ickyc-screening-sources-table"
            />
          )}
        />
      </Accordion>
      <div>
        <Field
          parse={convertToNumber}
          name="assignmentType"
          type="radio"
          component={RadioButtonField}
          label={<h2>Do Not Assign</h2>}
          value={enums.RISK_SCREENING_ASSIGNEMENT_TYPES.DoNotAssign}
        />
      </div>
      <Section title="Automations" className="ickyc-risk-screening-automations">
        <Field
          component={ToggleField}
          name="setCaseToClosedIfNoMatches"
          label={<strong>If no matches are found, set Case status to Closed.</strong>}
        />
        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
          <Field
            component={ToggleField}
            name="autoAcceptance"
            label={
              <>
                <strong>No potential risk updates the KYC Status.</strong>
                <Field
                  component={SelectField}
                  label="If no potential risk is identified, update the Entity's KYC Profile to: "
                  name="autoAcceptanceStatus"
                  options={mappedStatuses}
                  Trigger={<SelectTrigger placeholder="Choose Status" />}
                  Content={<SelectFilterContent />}
                />
              </>
            }
          />
        </ProtectedComponent>
      </Section>
    </div>
  );
};

export default CaseAssignement;
