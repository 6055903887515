import api from 'api';
import InternalPortalIcon from 'assets/InternalPortal';
import classNames from 'classnames';
import Modal from 'components/Modal';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import enums from 'utilities/enums';
import DependencyIcon from '../../../../../assets/DependencyIcon';
import CloseButton from '../../../../../components/Buttons/CloseButton';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import FormModal from '../../../../../components/Modal/FormModal';
import Toggle from '../../../../../components/Toggle';
import Tooltip from '../../../../../components/Tooltip';
import useModalHandler from '../../../../../hooks/useModalHandler';
import utilities from '../../../../../utilities';
import routesEnum from '../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../utilities/services/DateManager';
import { PortalManagementContext } from '../../../../../utilities/services/contexts';
import DeleteDefaultPortalForm from '../DeleteDefaultPortalForm';

const PortalsTableRow = ({ rowInfo, className, onRemove, onRadioClick, onUpdateRow, setUpdating }) => {
  const { id, name, status, tags, urls, isDefault, creationDate, type, hasHiddenDomain, isInternal } = rowInfo;
  const setColumnClass = setClassSuffix(`${className}__column`);
  const [shouldViewAll, setShouldViewAll] = useState(false);
  const [changeStatusConfMsg, setChangeStatusConfMsg] = useState('');
  const [forceDelete, setForceDelete] = useState(false);
  const [showDeleteDefaultPortalForm, setShowDeleteDefaultPortalForm] = useState(false);

  const { close: closeStatusConfModal, isOpen: isOpenStatusConfModal, open: openStatusConfModal } = useModalHandler();
  const {
    isOpen: isOpenConfirmationModal,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModalHandler();

  const { deleteConfiguration, clientId, groupPermissions, domain } = useContext(PortalManagementContext);

  const onDeleteHandler = useCallback(
    ({ newDefaultPortalId }) => {
      const isNaturalPerson = type === 'NaturalPerson';
      const paramsData = clientId
        ? { id, clientId, type: isNaturalPerson ? 0 : 1 }
        : { id, type: isNaturalPerson ? 0 : 1 };

      return deleteConfiguration(...Object.entries(paramsData).map(el => el[1]), forceDelete, newDefaultPortalId)
        .then(() => {
          onRemove('id', id, newDefaultPortalId);
          toastr.success(`Cofiguration has been deleted`);
          closeConfirmationModal();
          setForceDelete(false);
        })
        .catch(err => {
          if (err?.response) {
            const { status: resStatus, data } = err.response;
            if (resStatus === 409) {
              setForceDelete(true);
              return;
            }
            if (resStatus === 406) {
              setForceDelete(true);
              setShowDeleteDefaultPortalForm(true);
              return;
            }
            if (resStatus >= 400 && resStatus < 500) {
              return { [FORM_ERROR]: data.message };
            }
            if (resStatus === 500) {
              return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    },
    [onRemove, closeConfirmationModal, clientId, deleteConfiguration, id, type, forceDelete],
  );

  const handleOnShowMoreClick = () => {
    setShouldViewAll(prev => !prev);
  };

  const preventBubling = e => {
    e.stopPropagation();
  };

  const defaultPortalUrl = useMemo(() => {
    return urls.find(portal => portal.isDefault)?.url || urls[0]?.url;
  }, [urls]);

  const portalDetailsLink = useMemo(() => {
    if (clientId) {
      return utilities.routeParams(
        type === 'LegalEntity'
          ? routesEnum.internal.LEGAL_ENTITY_PORTAL_PROFILE
          : routesEnum.internal.NATURAL_PERSON_PORTAL_PROFILE,
        {
          id,
          clientId,
        },
      );
    }
    return utilities.routeParams(
      type === 'LegalEntity'
        ? routesEnum.administration.PORTAL_DETAILS_LEGAL
        : routesEnum.administration.PORTAL_DETAILS_NATURAL,
      {
        id,
      },
    );
  }, [id, type, clientId]);

  const handleStatusChange = (shouldForce = false) => {
    setUpdating(true);
    (clientId
      ? api.businessManager.updatePortalStatus(id, !status, type, clientId, shouldForce)
      : api.administration.portalManagement.updatePortalStatus(id, !status, type, shouldForce)
    )
      .then(({ data }) => {
        onUpdateRow('id', id, { status: !status });
        toastr.success(`Status was changed to ${!status ? 'Active' : 'Disabled'} `);
      })
      .catch(err => {
        console.log(err);

        if (err?.response) {
          const { status, data } = err.response;
          if (status === 409) {
            if (Array.isArray(data?.errors)) {
              if (data.errors[0]?.includes('use this portal for Identity Verification.')) {
                setChangeStatusConfMsg(
                  `${data.errors[0]} Changing the portal status will disable all Legal Entity Portal modules associated with it. Would you like to proceed?`,
                );
                openStatusConfModal();
                return;
              }
            }
          }
        }

        toastr.error('Error occurred during update');
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const handleRejectClick = useCallback(() => {
    setForceDelete(false);
    closeConfirmationModal();
  }, [closeConfirmationModal, setForceDelete]);

  const handleRadioClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      onRadioClick(id, type);
      !status && handleStatusChange(true);
    },
    [onRadioClick, id, type, status, handleStatusChange],
  );

  return (
    <>
      <tr className={`${className}__row`} id={id}>
        <td className={setColumnClass('__default')}>
          {!isInternal && (
            <label onClick={handleRadioClick}>
              <input data-lpignore="true" type="radio" checked={isDefault} />
            </label>
          )}
        </td>
        <td className={setColumnClass('__creationDate')}>
          <NavLink to={{ pathname: portalDetailsLink, state: { domain } }}>
            <span>{creationDate ? DateManager.monDayYearLocal(creationDate) : ''}</span>
          </NavLink>
        </td>
        <td
          className={classNames(setColumnClass('__dependency'), {
            [setColumnClass('__dependency--blue')]: isInternal,
          })}
        >
          {hasHiddenDomain && (
            <Tooltip
              trigger={
                <span>
                  <DependencyIcon />
                </span>
              }
              content={<span className={setColumnClass('__dependency__tippy')}>Used in another portal</span>}
            />
          )}
          {isInternal && (
            <Tooltip
              trigger={
                <span>
                  <InternalPortalIcon />
                </span>
              }
              content={<span className={setColumnClass('__dependency__tippy')}>Internal Portal</span>}
            />
          )}
        </td>
        <td className={setColumnClass('__name')}>
          <NavLink to={{ pathname: portalDetailsLink, state: { domain } }}>
            <span>{name}</span>
          </NavLink>
        </td>
        <td className={setColumnClass('__type')}>
          <NavLink to={{ pathname: portalDetailsLink, state: { domain } }}>
            <span>{type}</span>
          </NavLink>
        </td>
        <td className={setColumnClass('__url')} onClick={preventBubling}>
          <div className={setColumnClass('__url__container')}>
            <ul>
              {shouldViewAll ? (
                urls.map(({ url }) => <li key={url}>{url}</li>)
              ) : (
                <li className={setColumnClass('__url__container__list-item')} key={defaultPortalUrl}>
                  {defaultPortalUrl}
                </li>
              )}
            </ul>
            {urls?.length > 1 ? (
              <PrimaryButton variant="link" onClick={handleOnShowMoreClick}>
                {shouldViewAll ? 'Show Less' : 'Show More'}
              </PrimaryButton>
            ) : (
              <div />
            )}
          </div>
        </td>

        <td className={setColumnClass('__tags')}>
          <NavLink to={{ pathname: portalDetailsLink, state: { domain } }}>
            <span>{tags?.join(', ')}</span>
          </NavLink>
        </td>
        <td className={setColumnClass('__status')}>
          {!isInternal && (
            <Toggle
              value={status}
              showCustomText
              toggleOnText="Active"
              toggleOffText="Disabled"
              onChange={handleStatusChange}
              disabled={isDefault}
            />
          )}
        </td>
        <td className={setColumnClass('__remove')}>
          {!isInternal && (
            <>
              {(clientId ? groupPermissions.delete : true) && (
                <DeleteButton
                  onClick={e => {
                    e.stopPropagation();
                    openConfirmationModal();
                  }}
                />
              )}
            </>
          )}
        </td>
      </tr>
      {isOpenConfirmationModal && (
        <FormModal
          hideModal={closeConfirmationModal}
          title="Remove Configuration"
          className="ickyc-confirmation-modal ickyc-portal-management-confirm-modal"
          onSubmit={onDeleteHandler}
          showErrorsInConfirmation={!forceDelete}
          onRejectClick={handleRejectClick}
          showConfirmationForm={forceDelete && !showDeleteDefaultPortalForm}
          confirmationText="You are about to delete your default portal. Would you like to proceed?"
        >
          {showDeleteDefaultPortalForm ? (
            <DeleteDefaultPortalForm
              portalType={
                type === 'NaturalPerson' ? enums.ENTITY_TYPE_NAMES.NATURAL_PERSON : enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY
              }
              clientId={clientId}
            />
          ) : (
            <span className="ickyc-confirmation-message">
              Are you sure you want to delete &nbsp;
              <b>{`${name || `Portal Name`}`}</b>?
            </span>
          )}
        </FormModal>
      )}
      {isOpenStatusConfModal && (
        <Modal
          open={isOpenStatusConfModal}
          className="ickyc-confirmation-modal"
          hideModal={() => {
            closeStatusConfModal();
          }}
        >
          <div className="ickyc-form-modal ickyc-portal-management-modal ickyc-confirmation-risk-level-modal">
            <h2 className="ickyc-form-modal__header">
              Portal Status Confirmation
              <CloseButton
                transparent
                onClick={() => {
                  closeStatusConfModal();
                }}
              />
            </h2>
            <div className="ickyc-form-modal__content">{changeStatusConfMsg}</div>
            <div className="ickyc-form-modal__footer">
              <PrimaryButton
                variant="link"
                type="button"
                onClick={() => {
                  closeStatusConfModal();
                }}
              >
                Decline
              </PrimaryButton>
              <PrimaryButton
                type="button"
                onClick={() => {
                  handleStatusChange(true);
                  closeStatusConfModal();
                }}
              >
                Confirm
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

PortalsTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    urls: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.bool,
    isDefault: PropTypes.bool,
    creationDate: PropTypes.string,
    type: PropTypes.number,
    hasHiddenDomain: PropTypes.bool,
    isInternal: PropTypes.bool,
  }),
  className: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onRadioClick: PropTypes.func,
};
PortalsTableRow.defaultProps = {
  rowInfo: {
    urls: [],
    status: false,
    tags: [],
    creationDate: '',
    isDefault: false,
  },
  onRemove: () => {},
  onRadioClick: () => {},
};

export default PortalsTableRow;
