const PERMISSION_TAGS_MAPPING = {
  view: 'View',
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  requestClientUpdate: 'Request Client Update',
  amlSearchDashboard: 'AML Search Dashboard',
  amlSearchComponent: 'AML Search Component',
  customAmlSearches: 'Custom AML Searches',
  linkEntities: 'Link Entities',
  csvDownloads: 'CSV Downloads',
  naturalPersonReports: 'Natural Person Report',
  legalEntityReports: 'Legal Entity Report',
  status: 'Status',
  ongoingMonitoring: 'Ongoing Monitoring',
  createCustomPortals: 'Create Custom Portals',
  createGeneralPortals: 'Create General Portals',
  deactivateCompany: 'Deactivate Company',
};

const PERMISSION_GROUP = {
  PERSONAL_INFORMATION: 'personalInformation',
  CORPORATE_INFORMATION: 'corporateInformation',
  IDENTITY_DOCUMENTS: 'identityDocuments',
  BIOMETRIC_AUTHENTICATION: 'biometricAuthentication',
  DOCUMENTS: 'documents',
  AFFILIATED_ENTITIES: 'affiliatedEntities',
  ADDRESSES: 'addresses',
  RISK_SCREENING: 'riskScreening',
  REPORTS: 'reports',
  USER_MANAGEMENT: 'userManagement',
  KYC_PORTALS: 'kycPortals',
  COMPLIANCE_SETTINGS: 'complianceSettings',

  BUSINESS_MANAGER: 'businessManager',
  ICOMPLY_TEAM: 'iComplyTeam',
  LICENCE_MANAGER: 'licenceManager',
  ACCOUNT: 'account',
  EMAIL_TEMPLATES: 'emailTemplates',
  DASHBOARD: 'dashboard',
};

const PERMISSION_TAGS_OPTIONS = {
  PLATFORM_USER: [
    {
      name: 'emails',
      label: 'Emails',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'account',
      label: 'Account',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'personalInformation',
      label: 'Personal Information',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
        PERMISSION_TAGS_MAPPING.requestClientUpdate,
      ],
    },
    {
      name: 'corporateInformation',
      label: 'Corporate Information',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
        PERMISSION_TAGS_MAPPING.requestClientUpdate,
      ],
    },
    {
      name: 'affiliatedEntities',
      label: 'Affiliated Entities',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'addresses',
      label: 'Addresses',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'identityDocuments',
      label: 'Identity Document',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
        PERMISSION_TAGS_MAPPING.requestClientUpdate,
      ],
    },
    {
      name: 'biometricAuthentication',
      label: 'Biometric Authentication',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
        PERMISSION_TAGS_MAPPING.requestClientUpdate,
      ],
    },
    {
      name: 'documents',
      label: 'Documents',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.delete,
        PERMISSION_TAGS_MAPPING.requestClientUpdate,
      ],
    },
    {
      name: 'riskScreening',
      label: 'Risk Screening',
      permissions: [
        PERMISSION_TAGS_MAPPING.amlSearchDashboard,
        PERMISSION_TAGS_MAPPING.amlSearchComponent,
        PERMISSION_TAGS_MAPPING.customAmlSearches,
        PERMISSION_TAGS_MAPPING.linkEntities,
        PERMISSION_TAGS_MAPPING.status,
        PERMISSION_TAGS_MAPPING.ongoingMonitoring,
      ],
    },
    {
      name: 'reports',
      label: 'Reports',
      permissions: [
        PERMISSION_TAGS_MAPPING.csvDownloads,
        PERMISSION_TAGS_MAPPING.naturalPersonReports,
        PERMISSION_TAGS_MAPPING.legalEntityReports,
      ],
    },
  ],

  ADMIN_USER: [
    {
      name: 'userManagement',
      label: 'User Management',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.delete,
        PERMISSION_TAGS_MAPPING.create,
      ],
    },
    {
      name: 'kycPortals',
      label: 'KYC Portals',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.createCustomPortals,
        PERMISSION_TAGS_MAPPING.createGeneralPortals,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'complianceSettings',
      label: 'Compliance Settings',
      permissions: [PERMISSION_TAGS_MAPPING.view, PERMISSION_TAGS_MAPPING.edit],
    },
  ],
  ICOMPLY_USER: [
    {
      name: 'businessManager',
      label: 'Business Manager',
      permissions: [PERMISSION_TAGS_MAPPING.view],
    },
    {
      name: 'licenceManager',
      label: 'Licence Manager',
      permissions: [PERMISSION_TAGS_MAPPING.view],
    },
  ],
  ICOMPLY_ADMIN_USER: [
    {
      name: 'businessManager',
      label: 'Business Manager',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.deactivateCompany,
      ],
    },
    {
      name: 'iComplyTeam',
      label: 'iComply Team',
      permissions: [PERMISSION_TAGS_MAPPING.view],
    },
    {
      name: 'licenceManager',
      label: 'Licence Manager',
      permissions: [PERMISSION_TAGS_MAPPING.view, PERMISSION_TAGS_MAPPING.create, PERMISSION_TAGS_MAPPING.edit],
    },
  ],
  ICOMPLY_SUPER_ADMIN_USER: [
    {
      name: 'businessManager',
      label: 'Business Manager',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.deactivateCompany,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'iComplyTeam',
      label: 'iComply Team',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
    {
      name: 'licenceManager',
      label: 'Licence Manager',
      permissions: [
        PERMISSION_TAGS_MAPPING.view,
        PERMISSION_TAGS_MAPPING.create,
        PERMISSION_TAGS_MAPPING.edit,
        PERMISSION_TAGS_MAPPING.delete,
      ],
    },
  ],
};

const USER_STATUS = [
  { id: 'Active', label: 'Active', value: 'Active' },
  { id: 'Inactive', label: 'Inactive', value: 'Inactive' },
];

const TAGS = [
  { id: 'VIP', label: 'VIP', value: 'VIP' },
  { id: 'VIP 1', label: 'VIP 1', value: 'VIP 1' },
  { id: 'VIP 2', label: 'VIP 2', value: 'VIP 2' },
  { id: 'AML', label: 'AML', value: 'AML' },
];

const LICENSE_TYPES = [
  { id: 'custom', label: 'Custom', value: 0 },
  { id: 'website', label: 'Website', value: 1 },
];

const USER_TYPES = {
  CLIENT_USER: 'Client User',
  RESTRICTED_PLATFORM_USER: 'Restricted User',
  PLATFORM_USER: 'Platform User',
  RESTRICTED_ADMIN: 'Restricted Admin',
  FULL_ADMIN: 'Full Admin',
};

const ROLES = {
  ADMIN: 'Admin',
};
const TWO_FACTOR_AUTHENTICATION = {
  Disabled: 'Disabled',
  Enabled: 'Enabled',
  EnabledWithoutAuthentificator: 'EnabledWithoutAuthentificator',
  EnabledWithAuthentificator: 'EnabledWithAuthentificator',
};

const USER_TYPES_DROPDOWN_VALUES = [
  { id: 'Platform User', label: 'Platform User', value: 'Platform User' },
  { id: 'Restricted Admin', label: 'Restricted Admin', value: 'Restricted Admin' },
  { id: 'Full Admin', label: 'Full Admin', value: 'Full Admin' },
];

const ENTITY_TYPE = {
  LEGAL_ENTITY: { value: 0, text: 'Legal Entity' },
  NATURAL_PERSON: { value: 1, text: 'Natural Person' },
  CASE: { value: 2, text: 'Case' },
};

const ICOMPLY_USERS_ROLES = {
  ICOMPLY_SUPER_ADMIN: 'IComplySuperAdmin',
  ICOMPLY_ADMIN: 'IComplyAdmin',
  ICOMPLY_USER: 'IComplyUser',
};

const ICOMPLY_USERS_TYPES = {
  ICOMPLY_SUPER_ADMIN: 'iComply Super Admin',
  ICOMPLY_ADMIN: 'iComply Admin',
  ICOMPLY_USER: 'iComply User',
};

const ICOMPLY_USERS_TYPES_DROPDOWN_VALUES = [
  { id: 'iComply Super Admin', label: 'iComply Super Admin', value: 'iComply Super Admin' },
  { id: 'iComply Admin', label: 'iComply Admin', value: 'iComply Admin' },
  { id: 'iComply User', label: 'iComply User', value: 'iComply User' },
];

const ENTITY_TYPE_FILTER_OPTIONS = [
  { value: 'Legal Entity', label: 'Legal Entity', id: 'Legal Entity' },
  { value: 'Natural Person', label: 'Natural Person', id: 'Natural Person' },
];

const ACCESS_BY_LICENCE = {
  HOME_SCREEN: 'Home Screen',
  CASES: 'Cases',
  MY_WORK: 'My Work',
  ENTITIES: 'Entities',
  API_MANAGER: 'API Manager',
  AUTOMATIONS: 'Automations',
  COMPLIENCE_SETTINGS: 'Compliance Settings',
  FILES_MANAGEMENT: 'Files Management',
  PORTAL_MANAGEMENT: 'Portal Management',
  USER_MANAGEMENT: 'User Management',
  WEBHOOKS: 'Webhooks',
  SUPPORTING_DOCUMENTS: 'Supporting Documents',
  RISK_LEVELS: 'Risk Levels',
  RISK_SCREENING: 'Risk Screening',
  RISK_SCORING: 'Risk Scoring',
  QUICK_SETUP_SETTINGS: 'Quick Setup',
  EMAILS: 'Emails',
  ACCOUNT: 'Account',
  NOTIFICATIONS: 'Notifications',
};
export default {
  PERMISSION_TAGS_MAPPING,
  PERMISSION_GROUP,
  PERMISSION_TAGS_OPTIONS,
  USER_STATUS,
  TAGS,
  LICENSE_TYPES,
  USER_TYPES,
  TWO_FACTOR_AUTHENTICATION,
  USER_TYPES_DROPDOWN_VALUES,
  ROLES,
  ENTITY_TYPE,
  ICOMPLY_USERS_ROLES,
  ICOMPLY_USERS_TYPES,
  ICOMPLY_USERS_TYPES_DROPDOWN_VALUES,
  ENTITY_TYPE_FILTER_OPTIONS,
  ACCESS_BY_LICENCE,
};
