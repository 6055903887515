const BILLING_CYCLE_OPTIONS = [
  { id: 'Monthly', label: 'Monthly', value: '0' },
  { id: 'Quarterly', label: 'Quarterly', value: '1' },
  { id: 'Annually', label: 'Annually', value: '2' },
  { id: 'By Contract', label: 'By Contract', value: '3' },
];

const BILLING_CYCLE_REDUCED_OPTIONS = [
  { id: 'Monthly', label: 'Monthly', value: '0' },
  { id: 'Annually', label: 'Annually', value: '1' },
];

const BILLING_DAYS_OPTIONS = [
  {
    label: '1st',
    value: 1,
    id: '1st',
  },
  {
    label: '2nd',
    value: 2,
    id: '2nd',
  },
  {
    label: '3rd',
    value: 3,
    id: '3rd',
  },
  {
    label: '4th',
    value: 4,
    id: '4th',
  },
  {
    label: '5th',
    value: 5,
    id: '5th',
  },
  {
    label: '6th',
    value: 6,
    id: '6th',
  },
  {
    label: '7th',
    value: 7,
    id: '7th',
  },
  {
    label: '8th',
    value: 8,
    id: '8th',
  },
  {
    label: '9th',
    value: 9,
    id: '9th',
  },
  {
    label: '10th',
    value: 10,
    id: '10th',
  },
  {
    label: '11th',
    value: 11,
    id: '11th',
  },
  {
    label: '12th',
    value: 12,
    id: '12th',
  },
  {
    label: '13th',
    value: 13,
    id: '13th',
  },
  {
    label: '14th',
    value: 14,
    id: '14th',
  },
  {
    label: '15th',
    value: 15,
    id: '15th',
  },
  {
    label: '16th',
    value: 16,
    id: '16th',
  },
  {
    label: '17th',
    value: 17,
    id: '17th',
  },
  {
    label: '18th',
    value: 18,
    id: '18th',
  },
  {
    label: '19th',
    value: 19,
    id: '19th',
  },
  {
    label: '20th',
    value: 20,
    id: '20th',
  },
  {
    label: '21st',
    value: 21,
    id: '21st',
  },
  {
    label: '22nd',
    value: 22,
    id: '22nd',
  },
  {
    label: '23rd',
    value: 23,
    id: '23rd',
  },
  {
    label: '24th',
    value: 24,
    id: '24th',
  },
  {
    label: '25th',
    value: 25,
    id: '25th',
  },
  {
    label: '26th',
    value: 26,
    id: '26th',
  },
  {
    label: '27th',
    value: 27,
    id: '27th',
  },
  {
    label: '28th',
    value: 28,
    id: '28th',
  },
];

const LICENCE_STATUS_OPTIONS = {
  EXISTING: 0,
  NEW: 1,
  DELETED: 2,
};

const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: '0',
    id: '0',
  },
  {
    label: 'Paused',
    value: '2',
    id: '2',
  },
  {
    label: 'Upcoming',
    value: '4',
    id: '4',
  },
  {
    label: 'Canceled',
    value: '5',
    id: '5',
  },
];
const PERIOD_OPTIONS = [
  {
    label: 'Days',
    value: '0',
    id: '0',
  },
  {
    label: 'Weeks',
    value: '1',
    id: '1',
  },
  {
    label: 'Months',
    value: '2',
    id: '2',
  },
];

const TERM_PERIOD_OPTIONS = [
  {
    label: 'Days',
    value: 0,
    id: '0',
  },
  {
    label: 'Months',
    value: 1,
    id: '1',
  },
  {
    label: 'Years',
    value: 2,
    id: '2',
  },
];

const KYC_SERVICES_OPTIONS = [
  {
    label: 'Secure Portal Sessions',
    value: '0',
    id: '0',
  },
  {
    label: 'Identity Verification for Natural Persons',
    value: '1',
    id: '1',
  },
  {
    label: 'Document Authentication',
    value: '2',
    id: '2',
  },
  {
    label: 'Biometric Authentication',
    value: '3',
    id: '3',
  },

  {
    label: 'Supporting Documents for Natural Persons',
    value: '4',
    id: '4',
  },
  {
    label: 'DUNS Validation',
    value: '6',
    id: '6',
  },
  {
    label: 'GLEI Validation',
    value: '7',
    id: '7',
  },
  {
    label: 'Supporting Document for Legal Entities',
    value: '8',
    id: '8',
  },
  {
    label: 'AML Risk Screening',
    value: '9',
    id: '9',
  },
  {
    label: 'Daily Ongoing Monitoring',
    value: '10',
    id: '10',
  },
];

const PAYMENT_METHODS = [
  { id: 'bankWire', label: 'Bank Wire', value: 0 },
  { id: 'creditCard', label: 'Credit Card', value: 1 },
  { id: 'crypto', label: 'Crypto', value: 2 },
  { id: 'preauthorizedDebit', label: 'Pre-Authorized Debit', value: 3 },
];

const LICENCE_TYPES = {
  CUSTOM: 0,
  WEBSITE: 1,
};

const LICENCE_STATUS_TYPES = {
  ACTIVE: '0',
  EXPIRED: '1',
  PAUSED: '2',
  TERMINATED: '3',
  UPCOMING: '4',
  CANCELED: '5',
};

const PRODUCT_TYPES = {
  AML: 0,
  IDD: 1,
};

export default {
  BILLING_DAYS_OPTIONS,
  BILLING_CYCLE_OPTIONS,
  BILLING_CYCLE_REDUCED_OPTIONS,
  LICENCE_STATUS_OPTIONS,
  STATUS_OPTIONS,
  PERIOD_OPTIONS,
  TERM_PERIOD_OPTIONS,
  KYC_SERVICES_OPTIONS,
  PAYMENT_METHODS,
  LICENCE_TYPES,
  LICENCE_STATUS_TYPES,
  PRODUCT_TYPES,
};
