import { CloudUpload } from '@material-ui/icons';
import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DebounceInput from 'components/DebounceInput';
import ButtonHeading from 'components/Headings/ButtonHeading';
import CheckboxTable from 'components/Tables/CheckboxTable';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import Tooltip from 'components/Tooltip';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useModalHandler from 'hooks/useModalHandler';
import useSelect from 'hooks/useSelect';
import useTable from 'hooks/useTable';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import ProtectedComponent from 'router/components/ProtectedComponent';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import authEnums from 'utilities/enums/authEnums';
import routesEnum from 'utilities/enums/routesEnum';
import socketKeys from 'utilities/enums/socketKeys';
import IconManager from 'utilities/services/IconManager';
import SignalRService from 'utilities/services/SignalRService';
import TableHeaders from 'utilities/services/TableHeaders';
import FilterToggleButton from '../components/FilterToggleButton';
import SearchControls from '../components/SearchControls';
import BatchActionsModal from './BatchEntityModal';
import EntityTableRow from './EntityTableRow';
import LegalEntityModal from './LegalEntityModal';
import NaturalPersonModal from './NaturalPersonModal';
import CSVDownloadModal from './components/CSVDownloadModal';
import CSVUploadModal from './components/CSVUploadModal';
import EntitiesFilterModal from './components/EntitiesFilterModal';
import './styles.scss';

/**
 * Entity Management page.
 */
const EntityManagement = ({ className }) => {
  const [naturalPersonModal, setNaturalPersonModal] = useState(false);
  const [incorporatedModal, setIncorporatedModal] = useState(false);
  const [csvUploadModal, setCSVUploadModal] = useState(false);
  const [selectedEntityIds, setSelectedEntityIds] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [updatingRows, setUpdatingRows] = useState([]);

  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState({ assignedTo: [], countries: [], riskLevels: [], statuses: [] });

  const { isOpen: isCSVModalOpen, close: closeCSVModal, open: openCSVModal } = useModalHandler();

  const {
    isOpen: isBatchActionsModalOpen,
    close: closeBatchActionsModal,
    open: openBatchActionsModal,
  } = useModalHandler();

  const pageRef = useRef(null);

  const location = useLocation();

  const csvLogId = new URLSearchParams(location.search).get('csvcomplianceLogId');
  const fullySearchString = new URLSearchParams(location.search).get('fullySearchParam');

  const history = useHistory();

  const initialParams = useMemo(() => {
    const defaultParams = {
      skip: 0,
      limit: 10,
      sortCriteria: 'lastUpdated',
      sortOrder: 1,
      searchString: null,
      csvLogId,
    };

    if (fullySearchString) {
      return {
        ...defaultParams,
        fullySearchString,
      };
    }

    return defaultParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    pagination,
    params,
    records,
    removeRow,
    isLoading,
    addRows,
    changeParams,
    overrideTable,
    updateRowData,
  } = useTable(api.kyc.entityManagement.getEntitiesTable, initialParams);

  useEffectSkipFirst(() => {
    if (fullySearchString) {
      changeParams({ fullySearchString, searchString: null });
    } else {
      changeParams({ fullySearchString: null });
    }
  }, [fullySearchString]);

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => {
      changeParams({ filterOn: !prev });
      return !prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterSave = useCallback(values => {
    setFilters(values);
    changeParams({ filters: values, filterOn: true });
    setFilterOn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = useCallback(
    searchString => {
      const navigateToEntity = () => {
        history.push(routesEnum.kyc.ENTITY_MANAGEMENT);
      };

      navigateToEntity();
      changeParams({ searchString, fullySearchString: null });
    },
    [changeParams, history],
  );

  const setNewlyAdded = useCallback(
    entities => {
      addRows(entities);
    },
    [addRows],
  );

  const baseClass = 'ickyc-entities';

  const classes = classNames('ickyc-kyc-page', baseClass, className);

  const onDeleteEntity = useCallback(
    iDs => {
      if (Array.isArray(iDs) && iDs.length) {
        iDs.forEach(id => {
          removeRow('entityId', id);
        });
      }
    },
    [removeRow],
  );
  const platformAccess = useSelect(selectUserAccessByLicense);

  const clickHandlers = useMemo(() => {
    if (!platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.FILES_MANAGEMENT)) {
      return [() => setNaturalPersonModal(prev => !prev), () => setIncorporatedModal(prev => !prev)];
    }
    return [
      () => setCSVUploadModal(true),
      () => setNaturalPersonModal(prev => !prev),
      () => setIncorporatedModal(prev => !prev),
    ];
  }, [platformAccess]);

  const platformAccessByLicence = useSelect(selectUserAccessByLicense);

  const tableHeaderItems = useMemo(() => {
    let items = TableHeaders.EntityManagement;
    if (!platformAccessByLicence.some(el => el === authEnums.ACCESS_BY_LICENCE.RISK_LEVELS)) {
      items = items.filter(item => item.type !== 'risk');
    }
    return items;
  }, [platformAccessByLicence]);

  const handleOnBatchUpdate = data => {
    if (Array.isArray(data) && data.length) {
      const newRecords = records.map(record => ({
        ...record,
        ...(data.find(el => el?.entityId === record?.entityId) || {}),
        checked: selectedEntities?.some(entity => entity?.entityId === record?.entityId),
      }));

      overrideTable(newRecords);
    }
  };

  const buttonCoplies = useMemo(() => {
    if (!platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.FILES_MANAGEMENT)) {
      return [<>+ Add Natural Person</>, <>+ Add Legal Entity</>];
    }
    return [
      <Tooltip
        placement="top"
        trigger={
          <div className="ickyc-search-controls__upload-csv">
            <CloudUpload /> Upload CSV
          </div>
        }
        content={<span>Upload a CSV file to add multiple entities at once.</span>}
      />,
      <>+ Add Natural Person</>,
      <>+ Add Legal Entity</>,
    ];
  }, [platformAccess]);

  const tableCotrols = () => {
    return <></>;
  };

  const handleSelectedItemsChange = selectedEnties => {
    setSelectedEntityIds(selectedEnties);
  };

  const handleLocalValsChange = selectedEnties => {
    setSelectedEntities(selectedEnties);
  };

  const batchSelectedNaturalPerson = useMemo(() => selectedEntities.some(ent => ent?.type === 'Natural Person'), [
    selectedEntities,
  ]);

  const batchSelectedLegalEntity = useMemo(() => selectedEntities.some(ent => ent?.type === 'Legal Entity'), [
    selectedEntities,
  ]);

  const disableBatchActions = useMemo(() => {
    return selectedEntityIds?.length === 0;
  }, [selectedEntityIds]);

  const tableRowSocketUpdate = useCallback(
    ({ id, ...rest }) => {
      updateRowData('entityId', id, {
        ...rest,
      });
    },
    [updateRowData],
  );

  useEffect(() => {
    SignalRService.receiveMessage(socketKeys.ISSUES_COUNT_PROCESSED, tableRowSocketUpdate);
  }, []);

  return (
    <div className={classes} ref={pageRef}>
      <ButtonHeading title="Entities" buttonCopies={buttonCoplies} clickHandlers={clickHandlers} />
      {csvUploadModal && <CSVUploadModal containerRef={pageRef} hideModal={() => setCSVUploadModal(false)} />}
      {isBatchActionsModalOpen && (
        <BatchActionsModal
          hideModal={closeBatchActionsModal}
          entityIds={selectedEntityIds}
          onBatchAction={handleOnBatchUpdate}
          batchSelectedLegalEntity={batchSelectedLegalEntity}
          batchSelectedNaturalPerson={batchSelectedNaturalPerson}
        />
      )}
      {naturalPersonModal && (
        <NaturalPersonModal
          hideModal={() => setNaturalPersonModal(false)}
          multipleEntities
          setNewlyAdded={setNewlyAdded}
        />
      )}
      {incorporatedModal && (
        <LegalEntityModal hideModal={() => setIncorporatedModal(false)} setNewlyAdded={setNewlyAdded} />
      )}
      <SearchControls>
        <div className="ickyc-search-controls__info">
          <span>{pagination.total > 1 ? `${pagination.total || 0}  Results` : `${pagination.total || 0} Result`}</span>

          <Tooltip
            placement="top"
            trigger={
              <div>
                <ProtectedComponent
                  requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.csvDownloads]}
                  permissionGroup={authEnums.PERMISSION_GROUP.REPORTS}
                >
                  <PrimaryButton variant="link" onClick={openCSVModal}>
                    {IconManager.get(IconManager.names.DOWNLOAD)} Download CSV
                  </PrimaryButton>
                </ProtectedComponent>
              </div>
            }
            content={<span>Click to Download Entities in a CSV File</span>}
          />
        </div>
        {isCSVModalOpen && (
          <CSVDownloadModal
            hideModal={closeCSVModal}
            count={pagination?.total || 0}
            filters={filters}
            filterOn={filterOn}
            searchEntity={params.searchString}
            selectedEntityIds={selectedEntityIds}
            csvDownloadRequest={api.kyc.entityManagement.createCSVExport}
          />
        )}
        <div className="ickyc-search-controls__filter-section">
          <DebounceInput initialValue={params.searchString} placeholder="Search Entities" onChange={handleSearch} />
          <Tooltip
            placement="top"
            trigger={
              <div>
                <PrimaryButton onClick={openBatchActionsModal} disabled={disableBatchActions}>
                  {IconManager.get(IconManager.names.BATCH_ACTIONS)} Batch Actions
                </PrimaryButton>
              </div>
            }
            content={<span>Action all selected entities at once.</span>}
          />
          <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
            <EntitiesFilterModal onFilterSave={handleFilterSave} />
          </FilterToggleButton>
        </div>
      </SearchControls>

      <CheckboxTable
        TableControlsComponent={tableCotrols}
        onBatchUpdate={handleOnBatchUpdate}
        onSelectedItemsChange={handleSelectedItemsChange}
        onLocalValsChanage={handleLocalValsChange}
        values={records}
        disabled={false}
        pagination={pagination}
        tableRow={EntityTableRow}
        headerRow={TableHeader}
        className="ickyc-entities-table"
        withPagination
        withLimitChange
        idPropName="entityId"
        onRemove={onDeleteEntity}
        updating={isLoading}
        updatingRows={updatingRows}
        setUpdatingRows={setUpdatingRows}
        handleParamsChange={changeParams}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: tableHeaderItems,
          withDelete: true,
        }}
      />
    </div>
  );
};
EntityManagement.propTypes = {
  className: PropTypes.string,
};

EntityManagement.defaultProps = {
  className: undefined,
};
export default EntityManagement;
