import classNames from 'classnames';
import IconButton from 'components/Buttons/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import IconManager from 'utilities/services/IconManager';

const PlusComponent = ({ className, text, isChecked, handleShow, disabled }) => {
  const handleClick = useCallback(() => {
    handleShow(text);
  }, [text, handleShow]);

  const classes = classNames(className, 'licence-item', {
    'plus-component--disabled': disabled,
  });

  return (
    <div className={classes}>
      <IconButton onClick={handleClick} transparent disabled={disabled}>
        {IconManager.get(isChecked ? IconManager.names.CHECK_CIRCULAR : IconManager.names.PLUS_CIRCULAR)}
      </IconButton>
      {text && <p>{text}</p>}
    </div>
  );
};

PlusComponent.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  handleShow: PropTypes.func,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

PlusComponent.defaultProps = {
  className: '',
  text: undefined,
  handleShow: () => {},
  isChecked: false,
  disabled: false,
};

export default PlusComponent;
