import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import LicenceItem from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceItem';
import LicenceModule from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceModule';
import validators from 'utilities/services/validators';

const CoreModule = ({ namePreffix, disabled, ...rest }) => {
  return (
    <LicenceModule
      title="Core"
      headeritems={
        <>
          <LicenceItem noButtons text="Admin Users" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.core.adminUsersLimit`}
              type="number"
              placeholder="Number of Users"
              validate={validators.required(' ')}
              disabled={disabled}
            />
          </LicenceItem>
          <LicenceItem noButtons text="Platform Users" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.core.platformUsersLimit`}
              type="number"
              placeholder="Number of Users"
              validate={validators.required(' ')}
              disabled={disabled}
            />
          </LicenceItem>
        </>
      }
      {...rest}
    >
      <Field
        component={ToggleField}
        name={`${namePreffix}.core.userManagementEnabled`}
        label="User Management"
        disabled={disabled}
      />
      <Field
        component={ToggleField}
        name={`${namePreffix}.core.filesManagementEnabled`}
        label="Files Management"
        disabled={disabled}
      />
      <Field
        component={ToggleField}
        name={`${namePreffix}.core.notificationEnabled`}
        label="Notifications"
        disabled={disabled}
      />
    </LicenceModule>
  );
};

CoreModule.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};

CoreModule.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default CoreModule;
