import { Box, CircularProgress, Typography } from '@material-ui/core';
import api from 'api';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputFieldMuiAdapter from 'components/InputFieldMuiAdapter';
import SelectFieldMuiAdapter from 'components/SelectFieldMuiAdapter';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { Field, useField } from 'react-final-form';
import toastr from 'toastr';
import OrganizationsTable from './components/OrganizationsTable';

import './styles.scss';

const ORGANIZATION_SEARCH_SOURCES_OPTIONS = [
  { value: 'lei', text: 'LEI', key: 'lei' },
  { value: 'minerva', text: 'All Data Sources', key: 'minerva' },
];

const OrganizationSearchForm = ({ onAddOrganization }) => {
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState({ newOrg: false, loadMore: false });
  const [organizations, setOrganizations] = useState([]);
  const [params, setParams] = useState({ skip: 0, limit: 10, currentPage: 0 });
  const lastFetchPage = useRef(1);

  const {
    input: { value: searchValue },
  } = useField('organizationSearch');

  const handleSearch = useCallback(() => {
    lastFetchPage.current = 1;

    toastr.info('This process may take some time');

    setIsLoadingOrganizations(prev => ({ ...prev, newOrg: true }));
    const newParams = { ...searchValue, skip: 0, limit: 10, currentPage: 1 };

    api.kyc.entityManagement.legalEntity
      .searchOrganizations(newParams)
      .then(({ data }) => {
        const { data: searchOrganizations, paginationData } = data;
        setOrganizations(searchOrganizations || []);
        setParams(() => ({ ...newParams, ...paginationData }));
      })
      .catch(err => {
        console.log({ err });
      })
      .finally(() => setIsLoadingOrganizations(prev => ({ ...prev, newOrg: false })));
  }, [isLoadingOrganizations, searchValue]);

  const handleLoadMore = useCallback(() => {
    if (
      isLoadingOrganizations.loadMore ||
      lastFetchPage.current === params.currentPage + 1 ||
      params.currentPage === params.totalPages
    )
      return;

    lastFetchPage.current = params.currentPage + 1;

    setIsLoadingOrganizations(prev => ({ ...prev, loadMore: true }));

    const newParams = { ...params, skip: params.currentPage * params.limit };

    api.kyc.entityManagement.legalEntity
      .searchOrganizations(newParams)
      .then(({ data }) => {
        const { data: searchOrganizations, paginationData } = data;
        setOrganizations(prev => [...prev, ...searchOrganizations]);
        setParams(() => ({ ...newParams, ...paginationData }));
      })
      .catch(err => {
        console.log({ err });
      })
      .finally(() => setIsLoadingOrganizations(prev => ({ ...prev, loadMore: false })));
  }, [isLoadingOrganizations, params]);

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSearch();
      }
    },
    [handleSearch],
  );

  return (
    <div className="ickyc-add-legal-entities-org-search">
      <div className="ickyc-add-legal-entities-org-search__inputs">
        <Field
          component={InputFieldMuiAdapter}
          onKeyDown={handleKeyDown}
          name="organizationSearch.name"
          className="ickyc-add-legal-entities-org-search__inputs__field"
          label="Name*"
        />
        <Field
          component={SelectFieldMuiAdapter}
          name="organizationSearch.source"
          options={ORGANIZATION_SEARCH_SOURCES_OPTIONS}
          className="ickyc-add-legal-entities-org-search__inputs__source"
          allowNull
        />
        <Field
          component={InputFieldMuiAdapter}
          onKeyDown={handleKeyDown}
          className="ickyc-add-legal-entities-org-search__inputs__field"
          name="organizationSearch.number"
          label="Number"
          allowNull
        />
        <Box className="ickyc-add-legal-entities-org-search__search-btn-wrapper">
          <PrimaryButton
            type="button"
            onClick={handleSearch}
            disabled={!searchValue.name || isLoadingOrganizations.more || isLoadingOrganizations.newOrg}
          >
            Search
          </PrimaryButton>
        </Box>
      </div>

      {isLoadingOrganizations.newOrg ? (
        <Box className="organizations-table__loading-more">
          <CircularProgress size="28px" />
          <Typography>Loading Organizations...</Typography>
        </Box>
      ) : (
        !!params.currentPage && (
          <OrganizationsTable
            onAddOrganization={onAddOrganization}
            isLoading={isLoadingOrganizations.loadMore}
            onLoadMore={handleLoadMore}
            records={organizations}
            params={{ source: organizations[0]?.source || searchValue.source }}
          />
        )
      )}
    </div>
  );
};

OrganizationSearchForm.propTypes = {
  onAddOrganization: PropTypes.func,
};
OrganizationSearchForm.defaultProps = {
  onAddOrganization: () => {},
};

export default OrganizationSearchForm;
