/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Constants from 'utilities/constants';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import CountrySelectField from '../../../../../components/CountrySelectField';
import DatePickerField from '../../../../../components/DatepickerField';
import InputField from '../../../../../components/InputField';
import TextareaField from '../../../../../components/TextareaField';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import validators from '../../../../../utilities/services/validators';
import JurisdictionItem from './components/JurisdictionItem';
import './styles.scss';

const LegalEntityInformationForm = ({ preview, responsive, namePreffix, checkList }) => {
  const baseClass = !responsive ? 'ickyc-corporate-info' : 'ickyc-corporate-info--responsive';
  const setSuffix = setClassSuffix(baseClass);

  const {
    mutators: { push, remove },
  } = useForm();

  const reportingJurisdictionsPrefix = `${namePreffix}.reportingJurisdictions`;

  const addReportingJurisdictions = useCallback(() => {
    push(reportingJurisdictionsPrefix, null);
  }, [push, reportingJurisdictionsPrefix]);

  const removeReportingJurisdictions = useCallback(
    ind => {
      remove(reportingJurisdictionsPrefix, ind);
    },
    [remove, reportingJurisdictionsPrefix],
  );

  const taxJurisdictionsPrefix = `${namePreffix}.taxJurisdictions`;

  const addTaxJurisdictions = useCallback(() => {
    push(taxJurisdictionsPrefix, null);
  }, [push, reportingJurisdictionsPrefix]);

  const removeTaxJurisdictions = useCallback(
    ind => {
      remove(taxJurisdictionsPrefix, ind);
    },
    [remove, taxJurisdictionsPrefix],
  );

  return (
    <div className={baseClass}>
      <div className={setSuffix('__block')}>
        <Field
          component={InputField}
          name={`${namePreffix}.name`}
          autoFocus={!preview}
          label="Name"
          accentText={!checkList?.nameStatus}
          preview={preview}
          validate={
            !preview &&
            validators.composeValidators(
              validators.required(),
              validators.stringMaxLength(Constants.CORP_NAME_MAX_LENGTH),
              validators.containsLetter('Must contain at least one letter'),
            )
          }
          required
        />
        <Field
          component={TextareaField}
          name={`${namePreffix}.doingBusinessAs`}
          accentText={!checkList?.doingBusinessAsStatus}
          label="Doing Business As"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.businessNumber`}
          accentText={!checkList?.businessNumberStatus}
          label="Business Number"
          type="text"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.leiNumber`}
          accentText={!checkList?.leiNumberStatus}
          label="GLEI Number"
          type="text"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.dunsNumber`}
          accentText={!checkList?.dunsNumberStatus}
          label="DUNS Number"
          type="text"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.einTin`}
          accentText={!checkList?.einTinStatus}
          label="EIN/TIN"
          type="text"
          preview={preview}
        />

        {!preview ? (
          <PrimaryButton
            className="ickyc-button ickyc-button--primary jurisdiction__button"
            onClick={addReportingJurisdictions}
          >
            + Add Reporting Jurisdiction
          </PrimaryButton>
        ) : (
          <div className="ickyc-input ickyc-input--preview jurisdiction-label">
            <label>Reporting Jurisdictions</label>
          </div>
        )}
        <FieldArray name={reportingJurisdictionsPrefix}>
          {({ fields }) => {
            return fields.map((name, index) => (
              <JurisdictionItem
                key={name}
                namePreffix={name}
                preview={preview}
                index={index}
                handleOnRemove={removeReportingJurisdictions}
              />
            ));
          }}
        </FieldArray>
      </div>
      <div className={setSuffix('__block')}>
        <Field
          component={InputField}
          name={`${namePreffix}.alsoKnownAs`}
          accentText={!checkList?.alsoKnownAsStatus}
          label="Also Known As"
          preview={preview}
        />
        <Field
          component={TextareaField}
          name={`${namePreffix}.formerlyKnownAs`}
          accentText={!checkList?.formerlyKnownAsStatus}
          label="Formerly Known As"
          preview={preview}
        />
        <Field
          component={CountrySelectField}
          label="Formation Country"
          name={`${namePreffix}.jurisdictionOfIncorporationId`}
          accentText={!checkList?.jurisdictionOfIncorporationIdStatus}
          preview={preview}
        />
        <Field
          component={DatePickerField}
          name={`${namePreffix}.formationDate`}
          accentText={!checkList?.dateOfFormationStatus}
          label="Formation Date"
          preview={preview}
        />
        <Field
          component={CountrySelectField}
          label="Domicile"
          name={`${namePreffix}.jurisdictionOfDomicileId`}
          accentText={!checkList?.jurisdictionOfDomicileIdStatus}
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.phone`}
          accentText={!checkList?.phoneStatus}
          label="Phone"
          type="text"
          preview={preview}
          parse={value => value.replace(/[^\d]/g, '')}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.website`}
          accentText={!checkList?.websiteStatus}
          label="Website"
          type="text"
          preview={preview}
        />
        {!preview ? (
          <PrimaryButton
            className="ickyc-button ickyc-button--primary jurisdiction__button"
            onClick={addTaxJurisdictions}
          >
            + Add Tax Jurisdiction
          </PrimaryButton>
        ) : (
          <div className="ickyc-input ickyc-input--preview">
            <label>Tax Jurisdictions</label>
          </div>
        )}
        <FieldArray name={taxJurisdictionsPrefix}>
          {({ fields }) => {
            return fields.map((name, index) => (
              <JurisdictionItem
                key={name}
                namePreffix={name}
                preview={preview}
                index={index}
                handleOnRemove={removeTaxJurisdictions}
              />
            ));
          }}
        </FieldArray>
      </div>
      <div className={setSuffix('__block--full')}>
        <Field
          component={props => <TextareaField {...props} />}
          name={`${namePreffix}.natureOfBusiness`}
          label="Nature of Business"
          preview={preview}
          autoExpand
        />
      </div>
    </div>
  );
};

LegalEntityInformationForm.propTypes = {
  preview: PropTypes.bool,
  responsive: PropTypes.bool,
  namePreffix: PropTypes.string,
  checkList: PropTypes.shape({
    nameStatus: PropTypes.bool,
    doingBusinessAsStatus: PropTypes.bool,
    businessNumberStatus: PropTypes.bool,
    leiNumberStatus: PropTypes.bool,
    dunsNumberStatus: PropTypes.bool,
    einTinStatus: PropTypes.bool,
    alsoKnownAsStatus: PropTypes.bool,
    formerlyKnownAsStatus: PropTypes.bool,
    jurisdictionOfIncorporationIdStatus: PropTypes.bool,
    jurisdictionOfTaxResidenceIdStatus: PropTypes.bool,
    jurisdictionOfDomicileIdStatus: PropTypes.bool,
    companyPhoneStatus: PropTypes.bool,
    dateOfFormationStatus: PropTypes.bool,
    websiteStatus: PropTypes.bool,
  }),
};

LegalEntityInformationForm.defaultProps = {
  preview: false,
  responsive: false,
  namePreffix: 'corporateInformation',
  checkList: {
    nameStatus: true,
    doingBusinessAsStatus: true,
    businessNumberStatus: true,
    leiNumberStatus: true,
    dunsNumberStatus: true,
    einTinStatus: true,
    alsoKnownAsStatus: true,
    formerlyKnownAsStatus: true,
    jurisdictionOfIncorporationIdStatus: true,
    jurisdictionOfTaxResidenceIdStatus: true,
    jurisdictionOfDomicileIdStatus: true,
    companyPhoneStatus: true,
    dateOfFormationStatus: true,
    websiteStatus: true,
  },
};

export default LegalEntityInformationForm;
