import TableNavigation from 'components/TableNavigation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import toastr from 'toastr';
import utilities from 'utilities';
import enums from 'utilities/enums';
import ScrapedAffiliatedEntityRow from '../ScrapedAffiliatedEntityRow';
import { divideStringOnLastSpace } from './utils';

const affiliationsPageSize = 5;
const ScrapedOrganizationsPreview = ({ scrapedOrganizations, setScrapedOrganizations, entityTableName }) => {
  const [currentOrganizationIndex, setCurrentOrganizationIndex] = useState(0);
  const [currentAffiliationsIndex, setCurrentAffiliationsIndex] = useState(0);
  const { mutators, change } = useForm();
  const { values } = useFormState();

  const loadNextOrganization = useCallback(() => {
    setCurrentOrganizationIndex(prev => Math.min(scrapedOrganizations.length, prev + 1));
    setCurrentAffiliationsIndex(0);
  }, [scrapedOrganizations.length]);
  const loadPreviousOrganization = useCallback(() => {
    setCurrentOrganizationIndex(prev => Math.max(0, prev - 1));
    setCurrentAffiliationsIndex(0);
  }, []);

  const goToNextAffiliationsPage = useCallback(() => setCurrentAffiliationsIndex(prev => prev + 1), []);
  const goToPreviousAffiliationsPage = useCallback(
    () => setCurrentAffiliationsIndex(prev => Math.max(0, prev - 1)),
    [],
  );

  const currentScrapedOrganization = useMemo(() => {
    return scrapedOrganizations[currentOrganizationIndex];
  }, [scrapedOrganizations, currentOrganizationIndex]);

  const currentAffiliations = useMemo(() => {
    return (
      currentScrapedOrganization?.links?.slice(
        Math.max(0, currentAffiliationsIndex * affiliationsPageSize),
        Math.min(currentScrapedOrganization?.links?.length, (currentAffiliationsIndex + 1) * affiliationsPageSize),
      ) || []
    );
  }, [currentScrapedOrganization, currentAffiliationsIndex]);

  const addAffiliation = useCallback(
    namePreffix => {
      const affiliation = utilities.accessObjectNestedValuesByStringPath(values, namePreffix);
      const { entityName, type, email } = affiliation;

      const [name, index] = namePreffix.split('[').map(item => item.replace(']', ''));

      mutators.update(name, index, { ...affiliation, isAdded: true });

      setScrapedOrganizations(oldOrganizations =>
        oldOrganizations.map((organization, index) =>
          index === currentOrganizationIndex
            ? {
                ...organization,
                links: organization.links.map(link => {
                  if (link.email === email) {
                    return { ...affiliation, isAdded: true };
                  } else {
                    const scrapedEntity = values.scrapedEntities.find(entity => entity.email === link.email);
                    return scrapedEntity || link;
                  }
                }),
              }
            : organization,
        ),
      );

      if (type === String(enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY)) {
        mutators.push(entityTableName, {
          type,
          email,
          name: entityName,
          entitySearch: entityName,
        });
      } else {
        const [firstName, lastName] = divideStringOnLastSpace(entityName);
        mutators.push(entityTableName, {
          type,
          email,
          name: firstName,
          lastName,
          entitySearch: firstName,
        });
      }

      toastr.success(`${entityName} was added as affiliation successfully`);
    },
    [entityTableName, mutators, currentOrganizationIndex, values],
  );

  useEffect(() => {
    change('scrapedEntities', currentAffiliations);
  }, [currentAffiliations, change]);

  return (
    currentScrapedOrganization && (
      <div className="ickyc-link-affiliated-entities-form__scraped-organization-box">
        {scrapedOrganizations?.length > 1 && (
          <div className="ickyc-link-affiliated-entities-form__scraped-organization-header">
            <p>{currentOrganizationIndex + 1}</p>
            <p>{currentScrapedOrganization?.companyName}</p>
            <span className="ickyc-spacer" />
            <TableNavigation
              totalNumber={scrapedOrganizations.length}
              pageNumber={currentOrganizationIndex + 1}
              goToNextPage={loadNextOrganization}
              goToPrevPage={loadPreviousOrganization}
              pageSize={1}
            />
          </div>
        )}
        <>
          <FieldArray name={'scrapedEntities'} initialValue={currentAffiliations}>
            {({ fields }) => {
              return fields.map(name => (
                <ScrapedAffiliatedEntityRow
                  namePreffix={name}
                  addAffiliation={addAffiliation}
                  entityTableName={entityTableName}
                />
              ));
            }}
          </FieldArray>

          <TableNavigation
            pageSize={affiliationsPageSize}
            totalNumber={currentScrapedOrganization.links.length}
            pageNumber={currentAffiliationsIndex + 1}
            goToNextPage={goToNextAffiliationsPage}
            goToPrevPage={goToPreviousAffiliationsPage}
          />
        </>
      </div>
    )
  );
};

export default ScrapedOrganizationsPreview;
