import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField, useForm } from 'react-final-form';
import LicenceItem from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceItem';
import LicenceModule from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceModule';
import validators from 'utilities/services/validators';

const DueDiligenceModule = ({ namePreffix, disabled, ...rest }) => {
  const { change } = useForm();

  const {
    input: { value: supportingDocumentsEnabled },
  } = useField(`${namePreffix}.dueDiligence.supportingDocumentsEnabled`);

  useEffectSkipFirst(() => {
    if (!disabled) {
      change(
        `${namePreffix}.dueDiligence.supportingDocumentsLimit`,
        supportingDocumentsEnabled === false ? 0 : undefined,
      );
    }
  }, [supportingDocumentsEnabled, change, disabled]);

  return (
    <LicenceModule
      title="Due Diligence"
      headeritems={
        <>
          <LicenceItem noButtons text="Natural Persons" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.dueDiligence.naturalPersonsLimit`}
              type="number"
              placeholder="Number of Persons"
              validate={validators.required(' ')}
              disabled={disabled}
            />
          </LicenceItem>
          <LicenceItem noButtons text="Legal Entities" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.dueDiligence.legalEntitiesLimit`}
              type="number"
              placeholder="Number of Entities"
              validate={validators.required(' ')}
              disabled={disabled}
            />
          </LicenceItem>
        </>
      }
      {...rest}
    >
      <LicenceItem
        noButtons
        text={
          <Field
            component={ToggleField}
            name={`${namePreffix}.dueDiligence.supportingDocumentsEnabled`}
            label="Supporting Documents"
            disabled={disabled}
          />
        }
        disabled={disabled}
      >
        {!!supportingDocumentsEnabled && (
          <Field
            component={InputField}
            name={`${namePreffix}.dueDiligence.supportingDocumentsLimit`}
            type="number"
            placeholder="Maximum number"
            validate={validators.requiredNumber}
            disabled={disabled}
          />
        )}
      </LicenceItem>
      <Field
        component={ToggleField}
        name={`${namePreffix}.dueDiligence.riskLevelsEnabled`}
        label="Risk Levels"
        disabled={disabled}
      />
    </LicenceModule>
  );
};

DueDiligenceModule.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};

DueDiligenceModule.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default DueDiligenceModule;
