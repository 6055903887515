import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Checkbox from '../Checkbox';
import FieldDescription from '../FieldDescription';

import './styles.scss';

const CheckboxGroup = ({
  label,
  description,
  required,
  value: initialValue,
  options,
  hasError,
  onChange,
  error,
  preview,
}) => {
  const [groupValue, setGroupValue] = useState(initialValue || []);
  const classes = classNames('ickyc-checkbox-group', {
    'ickyc-checkbox-group--required': required,
    'ickyc-checkbox-group--error': hasError,
  });

  const handleChange = event => {
    const { value, checked } = event.target;

    if (checked) {
      setGroupValue(old => {
        onChange && onChange([...old, value]);
        return [...old, value];
      });
    } else {
      setGroupValue(old => {
        const filteredList = old.filter(v => v !== value);
        onChange && onChange(filteredList.length ? filteredList : []);
        return filteredList.length ? filteredList : [];
      });
    }
  };

  return (
    <div className={classes}>
      <label className="ickyc-checkbox-group__label">{label}</label>
      {options.map(({ value, label: optionLabel }) => (
        <Checkbox
          onChange={handleChange}
          type="checkbox"
          key={value}
          value={value}
          label={optionLabel}
          checked={groupValue?.includes(value)}
          preview={preview}
        />
      ))}
      {hasError && <small className="ickyc-checkbox-group__error">{error}</small>}
      {description && <FieldDescription text={description} />}
    </div>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  defaultValues: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
};

export default CheckboxGroup;
