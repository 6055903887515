import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';
import './styles.scss';

const Numerical = ({ customLabel, fieldName }) => {
  return (
    <div className="ickyc-custom-document-options">
      <span className="ickyc-custom-document-options--label">Question ID</span>
      <span>
        <Field name="id" component={InputField} preview></Field>
      </span>
      <span className="ickyc-custom-document-options--label">{customLabel}</span>
      <span>
        <Field
          name={fieldName}
          component={InputField}
          type="number"
          min="1"
          max="255"
          validate={validators.required()}
        ></Field>
      </span>
    </div>
  );
};
Numerical.propTypes = {
  customLabel: PropTypes.string,
  fieldName: PropTypes.string,
};
Numerical.defaultProps = {
  customLabel: 'Character Limit',
  fieldName: 'parameters.characterLimit',
};
export default Numerical;
