import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import LicenceModule from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceModule';

const AdvancedWorkflowModule = ({ namePreffix, disabled, ...rest }) => {
  return (
    <LicenceModule
      title="Advanced Workflow"
      headeritems={
        <>
          <Field
            component={ToggleField}
            name={`${namePreffix}.advancedWorkflow.automationsEnabled`}
            label="Automations"
            disabled={disabled}
          />
          <Field
            component={ToggleField}
            name={`${namePreffix}.advancedWorkflow.riskScoringEnabled`}
            label="Risk Scoring"
            disabled={disabled}
          />
          <Field
            component={ToggleField}
            name={`${namePreffix}.advancedWorkflow.quickSetupSettingsEnabled`}
            label="Quick Setup Settings"
            disabled={disabled}
          />
        </>
      }
      {...rest}
    />
  );
};

AdvancedWorkflowModule.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};

AdvancedWorkflowModule.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default AdvancedWorkflowModule;
