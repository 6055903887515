import api from 'api';
import FormModal from 'components/Modal/FormModal';
import { FORM_ERROR } from 'final-form';
import history from 'modules/history';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReportsModalFields from './components/ReportsModalFields';
import './styles.scss';
import utils from './utils';

const { initialValues } = utils;

const ReportsModal = ({ hideModal, entityId }) => {
  const [submitedSuccessfully, setSubmitedSuccessfully] = useState(false);

  const handleSubmit = async configData => {
    if (submitedSuccessfully) {
      history.push(`/administration/files-management/reports`);
      return;
    }

    if (configData.includeImages === false) {
      configData.includeIdDocsImages = false;
      configData.includeFaceMatchImages = false;
      configData.includeSupportingDocs = false;
    }

    if (configData.includeAmlCases === false) {
      configData.cases = [];
      configData.includeOnlyAcceptedMatches = false;
    }

    configData.type = 'EntityReportConfig';

    return api.kyc.entityManagement
      .createPersonalReportConfig(entityId, configData)
      .then(() => {
        setSubmitedSuccessfully(true);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <FormModal
      onSubmit={handleSubmit}
      hideModal={hideModal}
      initialValues={initialValues}
      title="Request Report"
      className="ickyc-reports-modal"
      submitButtonText="Request Report"
      showErrorsInConfirmation
      onRejectClick={hideModal}
      showConfirmationForm={submitedSuccessfully}
      confirmButtonText="Go to Reports"
      confirmationText="To download requested report please go to Reports in Administration."
      rejectButtonText="Cancel"
    >
      <ReportsModalFields entityId={entityId} />
    </FormModal>
  );
};
ReportsModal.propTypes = {
  hideModal: PropTypes.func,
  entityId: PropTypes.string,
};

ReportsModal.defaultProps = {
  hideModal: null,
  entityId: '',
};

export default ReportsModal;
