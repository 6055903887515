import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import FieldDescription from '../FieldDescription';
import RadioButton from '../RadioButton';
import './styles.scss';

const RadioGroupField = ({ label, input, meta, description, options, required }) => {
  const { name, onChange, value } = input;

  const hasError = useMemo(() => {
    if (meta) {
      const { invalid, touched, error, submitError, dirtySinceLastSubmit } = meta;
      if (submitError && !dirtySinceLastSubmit) {
        return submitError;
      }
      return touched && invalid && error;
    }
    return false;
  }, [meta]);

  const handleChange = useCallback(
    changeEvent => {
      onChange(changeEvent.target.value);
    },
    [onChange],
  );

  const baseClass = 'ickyc-radio-group-field';
  const classses = classNames(baseClass, {
    [`${baseClass}--error`]: hasError,
    [`${baseClass}--required`]: required,
  });

  return (
    <div className={classses}>
      {label && <label>{label}</label>}
      <div className={`${baseClass}__content`}>
        {options.map(option => (
          <RadioButton name={name} {...option} onChange={handleChange} checked={value === option.value} />
        ))}
      </div>
      {!!description && <FieldDescription text={description} />}
    </div>
  );
};

RadioGroupField.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  required: PropTypes.bool,
};
RadioGroupField.defaultProps = {
  label: undefined,
  description: undefined,
  input: undefined,
  meta: undefined,
  required: undefined,
};
export default RadioGroupField;
