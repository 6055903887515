import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ExistingCasesTableRow = ({ rowInfo, className, onClick }) => {
  const { label, id, selected, hovered } = rowInfo;
  const classes = classNames({
    [`${className}__row--hovered`]: hovered,
    [`${className}__row--selected`]: selected,
    [`${className}__row`]: true,
  });

  return (
    <tr key={id} className={classes} onClick={() => onClick(id)}>
      <td className={`${className}__column__id`}>
        <span>{id}</span>
      </td>
      <td className={`${className}__column__name`}>
        <span>{label}</span>
      </td>
    </tr>
  );
};
ExistingCasesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    hovered: PropTypes.bool,
  }),
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
ExistingCasesTableRow.defaultProps = {
  onClick: () => {},
  rowInfo: {
    selected: false,
    label: '',
    hovered: false,
  },
};
export default ExistingCasesTableRow;
