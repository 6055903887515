const answerFormatOptions = [
  { label: 'Discrete Choice', value: 'DiscreteChoice' },
  { label: 'Text Field', value: 'TextField' },
  { label: 'Currency Field', value: 'CurrencyField' },
  { label: 'Numerical Field', value: 'NumericalField' },
  { label: 'Multiple Choice', value: 'MultipleChoice' },
  { label: 'Percentage Field', value: 'PercentageField' },
];

const stopPropagation = event => event.stopPropagation();
export default { answerFormatOptions, stopPropagation };
