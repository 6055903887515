/* eslint-disable jsx-a11y/no-autofocus */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { SelectContext } from '../..';
import useDebouncedFilter from '../../../../hooks/useDebouncedFilter';
import Table from '../../../Tables/Table';
import './styles.scss';

const SelectContentTable = ({ tableProps, className }) => {
  const { options, multipleSelection, onChange, value } = useContext(SelectContext);
  const { inputProps } = useDebouncedFilter(1000, tableProps.handleParamsChange);
  const inputRef = useRef();
  const handleTableClick = args => {
    const selectedOption = options.find(opt => opt.id === args);
    if (selectedOption && !selectedOption.disabled) {
      onChange(selectedOption);
    }
  };
  const baseClass = 'ickyc-select-table-content';
  const classes = classNames({ [baseClass]: true, [className]: className });

  return (
    <div className={classes}>
      <input data-lpignore="true" {...inputProps} ref={inputRef} autoFocus />
      <Table
        values={options.map((opt, index) => ({
          ...opt,
          hovered: index === null,
          selected: multipleSelection ? value.some(val => val.id === opt.id) : value.id === opt.id,
        }))}
        {...tableProps}
        onClick={handleTableClick}
      />
    </div>
  );
};
SelectContentTable.propTypes = {
  multipleSelection: PropTypes.bool,
  isOpen: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  hoverIndex: PropTypes.number,
  tableProps: PropTypes.shape({ handleParamsChange: PropTypes.func }).isRequired,
  className: PropTypes.string,
};
SelectContentTable.defaultProps = {
  multipleSelection: false,
  hoverIndex: -1,
  options: [],
  isOpen: false,
  className: null,
};
export default SelectContentTable;
