import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField, useForm } from 'react-final-form';
import LicenceItem from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceItem';
import LicenceModule from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceModule';
import validators from 'utilities/services/validators';

const OnboardingModule = ({ namePreffix, disabled, ...rest }) => {
  const { change } = useForm();

  const {
    input: { value: npCustomUrlsEnabled },
  } = useField(`${namePreffix}.onboarding.npCustomUrlsEnabled`);

  const {
    input: { value: leCustomUrlsEnabled },
  } = useField(`${namePreffix}.onboarding.leCustomUrlsEnabled`);

  useEffectSkipFirst(() => {
    if (!disabled) {
      change(`${namePreffix}.onboarding.npCustomUrlsLimit`, npCustomUrlsEnabled === false ? 0 : undefined);
    }
  }, [npCustomUrlsEnabled, change, disabled]);

  useEffectSkipFirst(() => {
    if (!disabled) {
      change(`${namePreffix}.onboarding.leCustomUrlsLimit`, leCustomUrlsEnabled === false ? 0 : undefined);
    }
  }, [leCustomUrlsEnabled, change, disabled]);

  return (
    <LicenceModule
      title="Onboarding"
      headeritems={
        <>
          <LicenceItem noButtons text="Natural Person Portals" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.onboarding.npPortalsLimit`}
              type="number"
              placeholder="Number of Portals"
              validate={validators.required(' ')}
              disabled={disabled}
            />
          </LicenceItem>
          <LicenceItem
            noButtons
            text={
              <Field
                component={ToggleField}
                name={`${namePreffix}.onboarding.npCustomUrlsEnabled`}
                label="Custom URLs"
                disabled={disabled}
              />
            }
            disabled={disabled}
          >
            {npCustomUrlsEnabled && (
              <Field
                component={InputField}
                name={`${namePreffix}.onboarding.npCustomUrlsLimit`}
                type="number"
                placeholder="Number of URLs"
                validate={validators.requiredNumber}
                disabled={disabled}
              />
            )}
          </LicenceItem>
          <LicenceItem noButtons text="Legal Entity Portals" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.onboarding.lePortalsLimit`}
              type="number"
              placeholder="Number of Portals"
              validate={validators.required(' ')}
              disabled={disabled}
            />
          </LicenceItem>
          <LicenceItem
            noButtons
            text={
              <Field
                component={ToggleField}
                name={`${namePreffix}.onboarding.leCustomUrlsEnabled`}
                label="Custom URLs"
                disabled={disabled}
              />
            }
            disabled={disabled}
          >
            {leCustomUrlsEnabled && (
              <Field
                component={InputField}
                name={`${namePreffix}.onboarding.leCustomUrlsLimit`}
                type="number"
                placeholder="Number of URLs"
                validate={validators.requiredNumber}
                disabled={disabled}
              />
            )}
          </LicenceItem>
        </>
      }
      {...rest}
    >
      <Field
        component={ToggleField}
        name={`${namePreffix}.onboarding.portalManagementEnabled`}
        label="Portal Management"
        disabled={disabled}
      />
      <Field
        component={ToggleField}
        name={`${namePreffix}.onboarding.emailSettingsEnabled`}
        label="Email Settings"
        disabled={disabled}
      />
    </LicenceModule>
  );
};

OnboardingModule.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};

OnboardingModule.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default OnboardingModule;
