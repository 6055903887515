import { css } from '@emotion/core';
import React from 'react';
import BounceLoader from 'react-spinners/ClipLoader';
import './styles.scss';

const override = css`
  display: block;
  margin: 0 auto;
`;

const Spinner = ({ loadingText = 'Just a moment...' }) => (
  <div className="ickyc-loading">
    <BounceLoader css={override} size={100} color="#02ce94" />
    <p>{loadingText}</p>
  </div>
);

export default Spinner;
