import classNames from 'classnames';
import CoreModule from 'components/LicenceModules/CoreModule';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import AMLProduct from 'scenes/Internal/WebPricing/components/WebProductsSection/components/AMLProduct';
import IDDProduct from 'scenes/Internal/WebPricing/components/WebProductsSection/components/IDDProduct';
import licenceEnums from 'utilities/enums/licenceEnums';
import PlusComponent from '../PlusComponent';

import './styles.scss';

const initialShowProductsList = [
  { name: 'AML Product', toggled: false, fieldName: 'aml' },
  { name: 'IDD Product', toggled: false, fieldName: 'idd' },
];

const ProductsSection = ({ className, products, initialWebProductsIds, isExistingWebsiteLicence, namePreffix }) => {
  const [showProductsList, setShowProductsList] = useState(initialShowProductsList);

  const fieldPreffix = namePreffix ? `${namePreffix}.` : '';

  const classes = classNames(className, 'products-section');

  const { batch, change } = useForm();

  const {
    input: {
      value: { amlId, iddId },
      value: webProductsIdsObject,
    },
  } = useField(`${fieldPreffix}webProductsIdsObject`);

  const showProduct = useCallback(
    (selectedProduct, productValues) => {
      const productIdField = `${fieldPreffix}webProductsIdsObject.${selectedProduct.fieldName}Id`;
      const productField = `${fieldPreffix}${productValues.id}`;

      batch(() => {
        change(productIdField, productValues.id);
        change(productField, productValues);
      });

      setShowProductsList(prevProducts =>
        prevProducts.map(product => (product.name === selectedProduct.name ? { ...product, toggled: true } : product)),
      );
    },
    [batch, change, fieldPreffix],
  );

  const handleShowProduct = useCallback(
    name => {
      const selectedProduct = showProductsList.find(product => product.name === name);
      const productValues = products.find(product => !!product[`${selectedProduct.fieldName}WebProduct`]);

      showProduct(selectedProduct, productValues);
    },
    [showProduct, products, showProductsList],
  );

  useEffect(() => {
    if (!initialWebProductsIds) return;

    initialWebProductsIds.forEach(({ id, type }) => {
      const selectedProduct = type === licenceEnums.PRODUCT_TYPES.AML ? showProductsList[0] : showProductsList[1];
      const productValues = products.find(product => product.id === id);

      showProduct(selectedProduct, productValues);
    });
  }, [initialWebProductsIds, webProductsIdsObject, products, showProduct, showProductsList]);

  return (
    <div className={classes}>
      <div className="products-section__plus-components">
        {showProductsList.map(({ name, toggled }) => (
          <PlusComponent
            key={name}
            isChecked={toggled}
            text={name}
            handleShow={handleShowProduct}
            disabled={isExistingWebsiteLicence}
          />
        ))}
      </div>

      {isExistingWebsiteLicence && (
        <CoreModule
          className="products-section__core-module"
          namePreffix={namePreffix ? `${namePreffix}.modules` : 'modules'}
          disabled
        />
      )}

      <div className="products-section__product-preview">
        {amlId && showProductsList.find(product => product.name === 'AML Product').toggled && (
          <AMLProduct
            disabled
            noStatusSelect
            namePreffix={`${fieldPreffix}${amlId}`}
            noCoreModule={isExistingWebsiteLicence}
          />
        )}

        {iddId && showProductsList.find(product => product.name === 'IDD Product').toggled && (
          <IDDProduct
            disabled
            noStatusSelect
            namePreffix={`${fieldPreffix}${iddId}`}
            noCoreModule={isExistingWebsiteLicence}
          />
        )}
      </div>
    </div>
  );
};

ProductsSection.propTypes = {
  className: PropTypes.string,
  namePreffix: PropTypes.string,
  products: PropTypes.array,
  isExistingWebsiteLicence: PropTypes.bool,
  initialWebProductsIds: PropTypes.array,
};

ProductsSection.defaultProps = {
  className: '',
  namePreffix: '',
  products: [],
  isExistingWebsiteLicence: false,
  initialWebProductsIds: undefined,
};

export default ProductsSection;
