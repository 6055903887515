import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import FieldDescription from '../FieldDescription';

import CheckboxGroup from 'components/CheckboxGroup';
import './styles.scss';

const CheckboxGroupField = ({ label, description, required, defaultValues, options, ...rest }) => {
  const {
    input: { value: groupValue, ...restInput },
    meta: { error, modified, touched },
  } = rest;

  const hasError = useMemo(() => error && (touched || modified), [error, touched, modified]);

  const classes = classNames('ickyc-checkbox-group', {
    'ickyc-checkbox-group--required': required,
    'ickyc-checkbox-group--error': hasError,
  });

  return (
    <div className={classes}>
      <label className="ickyc-checkbox-group__label">{label}</label>
      <CheckboxGroup value={groupValue} options={options} {...restInput} />
      {hasError && <small className="ickyc-checkbox-group__error">{error}</small>}
      {description && <FieldDescription text={description} />}
    </div>
  );
};

CheckboxGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  defaultValues: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
};

export default CheckboxGroupField;
