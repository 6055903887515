/* eslint-disable no-nested-ternary */
import api from 'api';
import store from 'store';
import authEnums from 'utilities/enums/authEnums';

const parsePortalStyling = styling => {
  return {
    ...styling,
    colors: {
      ...styling.colors,
      isPoweredByWhite: styling.colors?.isPoweredByWhite ? 1 : 0,
    },
    clientLogo: {
      ...styling.clientLogo,
      imgUrl: {
        preview: styling.clientLogo.imgUrl,
      },
    },
  };
};
const normalizePortalStyling = styling => {
  return {
    ...styling,
    clientLogo: {
      ...styling.clientLogo,
      imgUrl: styling.clientLogo.imgUrl.file ? styling.clientLogo.imgUrl : styling.clientLogo.imgUrl.preview,
    },
  };
};

const splitPortalConfigRestrictionsIntoTables = restrictions => {
  const onboardingRestrictions = {};
  const autoAssignementRestrictions = {};
  const biometricRestrictions = {};
  const acceptableAgeRestrictions = {};
  const documentTypesRestrictions = {};
  const verificationRestrictions = {};

  restrictions.forEach(
    ({
      autoAssignedUser,
      autoAssignedUserId,
      automaticIDVerification,
      biometricAuthenticationType,
      countryId,
      idDocumentTypes,
      minimumAcceptableAge,
      minimumAcceptableAgeStatus,
      onboardingStatus,
    }) => {
      onboardingRestrictions[`country${countryId}`] = { onboardingStatus };
      autoAssignementRestrictions[`country${countryId}`] = { autoAssignedUser, autoAssignedUserId };
      biometricRestrictions[`country${countryId}`] = { biometricAuthenticationType };
      documentTypesRestrictions[`country${countryId}`] = { ...idDocumentTypes };
      acceptableAgeRestrictions[`country${countryId}`] = { minimumAcceptableAgeStatus, minimumAcceptableAge };
      verificationRestrictions[`country${countryId}`] = { automaticIDVerification };
    },
  );

  return {
    onboardingRestrictions,
    autoAssignementRestrictions,
    biometricRestrictions,
    acceptableAgeRestrictions,
    documentTypesRestrictions,
    verificationRestrictions,
  };
};

const mergeRestrictionsTables = restOfConfig => {
  const {
    onboardingRestrictions,
    autoAssignementRestrictions,
    biometricRestrictions,
    acceptableAgeRestrictions,
    documentTypesRestrictions,
    verificationRestrictions,
  } = restOfConfig;

  const restrictions = [];

  Object.entries(onboardingRestrictions).forEach(({ 0: key, 1: value }) => {
    const countryId = Number(key.replace('country', ''));
    const { selected, ...restOfValue } = value;
    restrictions.push({
      [`countryId`]: countryId,
      ...restOfValue,
      ...autoAssignementRestrictions[key],
      ...biometricRestrictions[key],
      ...acceptableAgeRestrictions[key],
      idDocumentTypes: { ...documentTypesRestrictions[key] },
      ...verificationRestrictions[key],
    });
  });

  return restrictions;
};

const extractDomainPathname = autogeneratedDomain => {
  if (autogeneratedDomain?.url) {
    if (autogeneratedDomain.url.includes('/le/')) {
      return autogeneratedDomain.url.split('icomplykyc.com/le/')[1];
    }
    if (autogeneratedDomain.url.includes('/np/')) {
      return autogeneratedDomain.url.split('icomplykyc.com/np/')[1];
    }
    return autogeneratedDomain.url.split('icomplykyc.com/')[1];
  }
  return '';
};

const parseModules = modules =>
  Object.entries({ ...modules })
    .map(entry => {
      if (!entry[1]) {
        return [{ moduleName: entry[0] }];
      }
      if (entry[1].length) {
        return entry[1].map(entryData => ({ ...entryData, moduleName: entry[0] }));
      }
      return [{ ...entry[1], moduleName: entry[0] }];
    })
    .flat()
    .map(el => {
      if (el.moduleName === 'biometricAuthentication') {
        return {
          ...el,
          emotions: {
            ...el.emotions,
            angry: { ...el.emotions.angry, value: el.emotions.angry.value * 100 },
            happy: { ...el.emotions.happy, value: el.emotions.happy.value * 100 },
            sad: { ...el.emotions.sad, value: el.emotions.sad.value * 100 },
            surprised: { ...el.emotions.surprised, value: el.emotions.surprised.value * 100 },
            neutral: { ...el.emotions.neutral, value: Math.round(el.emotions.neutral.value * 100) },
          },
        };
      }
      return el;
    });

const parsePortalConfiguration = config => {
  const { restrictions, modules, styling, domains = [], type, ...restOfConfig } = config;
  const parsedModules = parseModules(modules);
  const consentGatewayIndex = parsedModules.findIndex(el => el.moduleName === 'consentGateway');
  parsedModules.push(parsedModules.splice(consentGatewayIndex, 1)[0]);

  const finalStepIndex = parsedModules.findIndex(el => el.moduleName === 'FinalStep');
  parsedModules.push(parsedModules.splice(finalStepIndex, 1)[0]);

  const autogeneratedDomain = domains.find(domain => domain.isAutoGenerated);

  const domainPathname = extractDomainPathname(autogeneratedDomain);

  return {
    ...restOfConfig,
    type,
    domains: domains.map(domain =>
      domain.isAutoGenerated
        ? {
            ...domain,
            url: type === 'LegalEntity' && domain.url?.includes('https') ? `${domain.url}` : `https://${domain.url}`,
          }
        : domain,
    ),
    styling: parsePortalStyling(styling),
    domainPathname,
    ...splitPortalConfigRestrictionsIntoTables(restrictions),
    modules: parsedModules,
  };
};

const normalizeModules = modules =>
  modules
    .map(({ chosen, selected, ...restOfModule }) => restOfModule)
    .reduce((acc, curr) => {
      const { moduleName, text, ...restOfModule } = curr;
      if (moduleName === 'biometricAuthentication') {
        return {
          ...acc,
          [moduleName]: {
            ...restOfModule,
            text,
            emotions: {
              ...restOfModule.emotions,
              angry: { ...restOfModule.emotions.angry, value: restOfModule.emotions.angry.value / 100 },
              happy: { ...restOfModule.emotions.happy, value: restOfModule.emotions.happy.value / 100 },
              sad: { ...restOfModule.emotions.sad, value: restOfModule.emotions.sad.value / 100 },
              surprised: { ...restOfModule.emotions.surprised, value: restOfModule.emotions.surprised.value / 100 },
              neutral: { ...restOfModule.emotions.neutral, value: restOfModule.emotions.neutral.value / 100 },
            },
          },
        };
      }
      if (moduleName === 'supportingDocumentPackage') {
        const { getState } = store;
        const {
          auth: {
            personalInfo: { accessByLicence },
          },
        } = getState();
        if (accessByLicence && !accessByLicence.some(el => el === authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS))
          return { ...acc, [moduleName]: {} };

        return {
          ...acc,
          [moduleName]: {
            text,
            ...restOfModule,
          },
        };
      }
      if (moduleName === 'consentGateway') {
        const { consentDeclarationText } = restOfModule;

        return {
          ...acc,
          [moduleName]: {
            ...restOfModule,
            consentDeclarationText,
            text,
          },
        };
      }
      if (moduleName === 'addLegalEntity') {
        const {
          kycRequests: { entityDetailsSupportingDocumentId, addressDetailsSupportingDocumentId },
          kycRequests,
        } = restOfModule;
        return {
          ...acc,
          [moduleName]: {
            ...restOfModule,
            kycRequests: {
              ...kycRequests,
              entityDetailsSupportingDocumentId: entityDetailsSupportingDocumentId || 0,
              addressDetailsSupportingDocumentId: addressDetailsSupportingDocumentId || 0,
            },
          },
        };
      }

      return {
        ...acc,
        [moduleName]: {
          ...restOfModule,
          text,
        },
      };
    }, {});

const normalizePortalConfiguration = config => {
  const {
    modules,
    styling,
    domains,
    onboardingRestrictions,
    autoAssignementRestrictions,
    biometricRestrictions,
    acceptableAgeRestrictions,
    documentTypesRestrictions,
    verificationRestrictions,
    restrictionsMessages,
    type,
    ...restOfConfig
  } = config;

  return {
    ...restOfConfig,
    domains: domains.map(domain =>
      domain.isAutoGenerated ? { ...domain, url: domain.url.split('https://')[1] } : domain,
    ),
    type,
    styling: normalizePortalStyling(styling),
    restrictions: mergeRestrictionsTables(config),
    modules: { ...normalizeModules(modules) },
  };
};

/**
 *
 * @param {*} keyValues => object with propName and its initial values
 * {
 *    propA: valueA,
 *    propB: valueB
 *    .
 *    .
 *    .
 * }
 * @returns
 */
const setCountryTableRowValues = keyValues => {
  const { getState } = store;
  const {
    global: { countries },
  } = getState();

  return countries.reduce(
    (acc, curr) => ({
      ...acc,
      [`country${curr.id}`]: keyValues,
    }),
    {},
  );
};

const initializeApiEndpoints = clientId => {
  if (clientId) {
    return {
      getAllPortals: api.businessManager.getPortalsConfigurations,
      updateConfigurationLogoImage: api.businessManager.updateConfigurationLogoImage,
      createPortalsConfiguration: api.businessManager.createPortalsConfiguration,
      updateConfigurationNP: api.businessManager.updatePortalsConfigurationNaturalPerson,
      updateConfigurationLE: api.businessManager.updatePortalsConfigurationLegalEntity,
      fetchExistingConfiguration: api.businessManager.getPortalsConfigurationById,
      deleteConfiguration: api.businessManager.deleteConfiguration,
      updateDefault: api.businessManager.updateDefault,
      getRiskScreeningProfiles: api.businessManager.getRiskScreeningProfilesBusinessManager,
    };
  }
  return {
    getAllPortals: api.administration.portalManagement.getPortalsConfigurations,
    updateConfigurationLogoImage: api.administration.portalManagement.updateConfigurationLogoImage,
    createPortalsConfiguration: api.administration.portalManagement.createPortalsConfiguration,
    updateConfigurationNP: api.administration.portalManagement.updatePortalsConfigurationNaturalPerson,
    updateConfigurationLE: api.administration.portalManagement.updatePortalsConfigurationLegalEntity,
    fetchExistingConfiguration: api.administration.portalManagement.getPortalsConfigurationById,
    updateDefault: api.administration.portalManagement.updateDefault,
    deleteConfiguration: api.administration.portalManagement.deleteConfiguration,
    getRiskScreeningProfiles: api.administration.portalManagement.getRiskScreeningProfiles,
  };
};

export default {
  parsePortalStyling,
  normalizePortalStyling,
  splitPortalConfigRestrictionsIntoTables,
  parsePortalConfiguration,
  normalizePortalConfiguration,
  setCountryTableRowValues,
  initializeApiEndpoints,
  mergeRestrictionsTables,
  extractDomainPathname,
  parseModules,
  normalizeModules,
};
