import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../api';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import WizardFormModal from '../../../../components/Modal/WizardFormModal';
import WizardFormPage from '../../../../components/Modal/WizardFormModal/components/WizardFormPage';
import useSelect from '../../../../hooks/useSelect';
import { selectUserAccessByLicense } from '../../../../store/selectors/auth.selector';
import DateManager from '../../../../utilities/services/DateManager';
import { RCUDefaultValues } from '../components/RequestClientUpdateNaturalPersonModal/utils';
import ApproveEntitiesForm from './components/ApproveEntitiesForm';
import BatchLegalEntityRCU from './components/BatchLegalEntityRCU';
import BatchNaturalPersonRCU from './components/BatchNaturalPersonRCU';
import GeneralInfoEntitiesForm from './components/GeneralInfoEntitesForm';
import BatchActionsService from './services/BatchActions.service';
import './styles.scss';

const BatchEntityModal = ({
  hideModal,
  entityIds,
  onBatchAction,
  batchSelectedLegalEntity,
  batchSelectedNaturalPerson,
}) => {
  const [initVals, setInitVals] = useState({ ...RCUDefaultValues, acceptIssues: 1 });
  const className = 'ickyc-batch-entity-modal';
  const [forceRequest, setForceRequest] = useState(false);
  const [selectedDomainGroup, setSelectedDomainGroup] = useState();
  const platformAccessByLicence = useSelect(selectUserAccessByLicense);
  const [skipNPStep, setSkipNPStep] = useState(!batchSelectedNaturalPerson);
  const [skipLEStep, setSkipLEStep] = useState(!batchSelectedLegalEntity);
  const [newStatusIsApproved, setNewStatusIsApproved] = useState(false);

  const onSubmitHandler = async vals => {
    try {
      const {
        legalEntityBatchClientUpdateDto,
        riskLevel,
        entityStatus,
        assignedUserId,
        tags,
        nextReviewDate,
        createAmlCaseForAffiliatedEntities,
        createAmlCaseForEntities,
        acceptIssues,
        ...restOfVals
      } = vals;

      const submitObj = {
        entityIds,
        entityStatus,
        assignedUserId,
        tags,
        nextReviewDate: DateManager.toBekend(nextReviewDate),
        createAmlCaseForAffiliatedEntities,
        createAmlCaseForEntities,
        naturalPersonClientUpdateDto: skipNPStep
          ? null
          : BatchActionsService.prepareNaturalPersonRcuValsForSubmit(restOfVals, platformAccessByLicence),
        legalEntityBatchClientUpdateDto: skipLEStep
          ? null
          : BatchActionsService.prepareLegalEntityValsForSubmit(legalEntityBatchClientUpdateDto, selectedDomainGroup),
      };
      if (newStatusIsApproved) {
        submitObj.acceptIssues = Boolean(acceptIssues);
      }

      if (riskLevel) {
        submitObj.riskLevel = Number(riskLevel);
      }

      await api.kyc.entityManagement.batchUpdateEntitiesAndRcuFromModal(submitObj, forceRequest).then(response => {
        onBatchAction(response?.data);
        setForceRequest(false);
        hideModal(false);
        toastr.success(`Entites succesfully updated`);
      });
    } catch (err) {
      setForceRequest(false);
      if (err?.response) {
        const { status, data } = err.response;
        console.log(err.response);
        if (status === 400) {
          return { [FORM_ERROR]: data?.title || data?.message };
        }
        if (status === 409) {
          if (data?.message) {
            setForceRequest(true);
            return { [FORM_ERROR]: data?.message };
          }
        }
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: data.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };

  const handleSetInitVals = useCallback(vals => setInitVals(vals), [setInitVals]);

  const handleSelectedDomainGroup = useCallback(vals => setSelectedDomainGroup(vals), [setSelectedDomainGroup]);

  const batchLEStepNumber = useMemo(() => {
    if (newStatusIsApproved) {
      if (batchSelectedNaturalPerson) {
        return 4;
      } else {
        return 3;
      }
    } else {
      if (batchSelectedNaturalPerson) {
        return 3;
      } else {
        return 2;
      }
    }
  }, [newStatusIsApproved, batchSelectedNaturalPerson]);

  const titles = useMemo(() => {
    const allTitles = [
      <h3>
        Step 1: &nbsp;
        <b>General Settings</b>
      </h3>,
    ];
    if (newStatusIsApproved) {
      allTitles.push(
        <h3>
          Step 2: &nbsp;
          <b>Approve Entities</b>
        </h3>,
      );
    }

    if (batchSelectedNaturalPerson) {
      allTitles.push(
        <h3>
          {`Step ${newStatusIsApproved ? 3 : 2}:`} &nbsp;
          <b>Batch Request Client Update for NPs</b>
        </h3>,
      );
    }

    if (batchSelectedLegalEntity) {
      allTitles.push(
        <h3>
          {`Step ${batchLEStepNumber}:`} &nbsp;
          <b>Batch Request Client Update for LEs</b>
        </h3>,
      );
    }
    return allTitles;
  }, [batchSelectedLegalEntity, batchSelectedNaturalPerson, newStatusIsApproved]);

  const childrenComponents = useMemo(() => {
    const pages = [
      <WizardFormPage>
        <GeneralInfoEntitiesForm
          batchSelectedLegalEntity={batchSelectedLegalEntity}
          onStatusChange={setNewStatusIsApproved}
        />
      </WizardFormPage>,
    ];
    if (newStatusIsApproved) {
      pages.push(
        <WizardFormPage>
          <ApproveEntitiesForm />
        </WizardFormPage>,
      );
    }
    if (batchSelectedNaturalPerson) {
      pages.push(
        <WizardFormPage
          footerContent={
            <div className={`${className}__skip-button-container`}>
              <PrimaryButton
                type="submit"
                onClick={() => {
                  setSkipNPStep(true);
                }}
              >
                Skip »
              </PrimaryButton>
            </div>
          }
        >
          <BatchNaturalPersonRCU />
        </WizardFormPage>,
      );
    }

    if (batchSelectedLegalEntity) {
      pages.push(
        <WizardFormPage
          footerContent={
            <div className={`${className}__skip-button-container`}>
              <PrimaryButton
                type="submit"
                onClick={() => {
                  setSkipLEStep(true);
                }}
              >
                Skip »
              </PrimaryButton>
            </div>
          }
        >
          <BatchLegalEntityRCU
            setInitialValues={handleSetInitVals}
            handleSelectedDomainGroup={handleSelectedDomainGroup}
          />
        </WizardFormPage>,
      );
    }

    return pages;
  }, [
    batchSelectedLegalEntity,
    batchSelectedNaturalPerson,
    handleSelectedDomainGroup,
    handleSetInitVals,
    newStatusIsApproved,
  ]);

  return (
    <WizardFormModal
      mutators={{ ...arrayMutators }}
      hideModal={hideModal}
      className={className}
      onSubmitHandler={onSubmitHandler}
      initialValues={initVals}
      showConfirmationForm={forceRequest}
      onRejectClick={hideModal}
      showErrorsInConfirmation
      confirmButtonText="Yes"
      confirmationText="Would you like to proceed and override previous request?"
      rejectButtonText="No"
      titles={titles}
    >
      {childrenComponents}
    </WizardFormModal>
  );
};
BatchEntityModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  entityIds: PropTypes.string.isRequired,
  onBatchAction: PropTypes.func.isRequired,
  batchSelectedNaturalPerson: PropTypes.bool.isRequired,
  batchSelectedLegalEntity: PropTypes.bool.isRequired,
};
export default BatchEntityModal;
