import CustomQuestionIcon from 'assets/CustomQuestionIcon';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TextareaField from 'components/TextareaField';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import validators from 'utilities/services/validators';
import utils from '../../utils';

export default ({ setSuffix }) => {
  const { change } = useForm();

  const cleanParameters = useCallback(
    e => {
      change(`parameters`, {});
    },
    [change],
  );

  return (
    <>
      <td className={setSuffix('__type')}>
        <CustomQuestionIcon />
        <span>Custom Question</span>
      </td>
      <td className={setSuffix('__question')} onClick={utils.stopPropagation}>
        <Field
          label="Question"
          labelPosition="top"
          name="description"
          component={TextareaField}
          validate={validators.required()}
        />
      </td>
      <td className={setSuffix('__answer-format')} onClick={utils.stopPropagation}>
        <Field
          label="Answer Format"
          name="type"
          component={SelectField}
          options={utils.answerFormatOptions}
          onChange={cleanParameters}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
          labelPosition="top"
          validate={validators.required()}
        />
      </td>
    </>
  );
};
