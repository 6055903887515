import api from 'api';
import classNames from 'classnames';
import FormModal from 'components/Modal/FormModal';
import React, { useCallback, useContext, useRef } from 'react';
import PackageItemsFields from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/components/PackageItemsFields';
import prepareItems from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/utils';
import toastr from 'toastr';
import { DocumentDataContext, EntityContext, HistoryRecordContext } from 'utilities/services/contexts';

const AdditionalItemModal = ({ className, packageId, existingItems, hideModal }) => {
  const { setDocumentRecord, documentRecord } = useContext(DocumentDataContext);
  const { entityId } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const baseClass = 'ickyc-additional-item-modal';
  const classes = classNames(baseClass, className);
  const pageRef = useRef(null);

  const addAdditionalItems = useCallback(
    async ({ items, ...restValues }) => {
      const { answersDTO, itemsDTO } = prepareItems(items);
      const values = { ...restValues, items: itemsDTO, answers: answersDTO };
      try {
        await api.kyc.entityManagement.updateSupportingDocumentPackageItems(entityId, eventHistoryId, values);

        const { data: responseData } = await api.kyc.entityManagement.getDocumentsTableRecord({
          entityId,
          documentId: eventHistoryId,
          limit: 1,
        });

        console.log({
          responseData,
        });

        setDocumentRecord(responseData);
        toastr.success('Item added successfully');
        hideModal();
      } catch (err) {
        toastr.error('Error occured while adding package item');
        console.error(err);
      }
    },
    [setDocumentRecord],
  );

  const onlyNotPopulatedItems = useCallback(
    data => data?.filter(item => !existingItems?.some(existingItem => existingItem.itemId === item.id)),
    [existingItems],
  );

  return (
    <div onClick={e => e.stopPropagation()}>
      <FormModal
        title="Add Additional Package Item"
        className={classes}
        onSubmit={addAdditionalItems}
        hideModal={hideModal}
        containerRef={pageRef}
      >
        <PackageItemsFields packageId={packageId} filterData={onlyNotPopulatedItems} />
      </FormModal>
    </div>
  );
};

export default AdditionalItemModal;
