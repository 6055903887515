import classNames from 'classnames';
import InputField from 'components/InputField';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import licenceEnums from 'utilities/enums/licenceEnums';
import validators from 'utilities/services/validators';
import LicenceItem from '../LicenceItem';

import './styles.scss';

const ProductsSetupSection = ({ className, namePreffix, hasAddons, hasDiscount }) => {
  const classes = classNames(className, 'products-setup-section--with-divider', 'products-setup-section');

  const { batch, change } = useForm();

  const fieldPreffix = namePreffix ? `${namePreffix}.setupAndMaintenance` : 'setupAndMaintenance';
  const modulesPreffix = namePreffix ? `${namePreffix}.modules.onboarding` : 'modules.onboarding';

  const {
    input: { value: feePerPortal },
  } = useField(`${fieldPreffix}.recurringMaintenanceAnnual`);

  const {
    input: {
      value: { npPortalsLimit, lePortalsLimit },
    },
  } = useField(modulesPreffix);

  const recurringMaintenanceTotal = feePerPortal * (+npPortalsLimit + +lePortalsLimit);

  const handleChange = useCallback(
    (fieldName, value) => {
      batch(() => {
        if (!value) {
          const disabledItemName = fieldName?.split('Enabled')[0];
          change(disabledItemName, 0);
        }

        change(fieldName, value);
      });
    },
    [change, batch],
  );

  return (
    <section className={classes}>
      <div className="products-setup-section__header">
        <h3>Setup & Maintenance</h3>
      </div>

      <div className="products-setup-section__content">
        <LicenceItem
          noButtons
          disabled
          text="One-Time Setup Fee"
          handleChange={handleChange}
          fieldName={`${fieldPreffix}.oneTimeSetupFeeEnabled`}
        >
          <Field
            disabled
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.oneTimeFee`}
            placeholder=". . ."
            type="number"
          />
        </LicenceItem>
        <LicenceItem
          noButtons
          disabled
          text="Recurring Maintenance Fee"
          handleChange={handleChange}
          fieldName={`${fieldPreffix}.recurringMaintenanceEnabled`}
        >
          <Field
            disabled
            topLabeled
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.recurringMaintenanceAnnual`}
            placeholder=". . ."
            type="number"
            label="Annually"
          />
          <span className="products-setup-section__total-price">{`/per portal (total: \$${recurringMaintenanceTotal})`}</span>
        </LicenceItem>
        <LicenceItem
          noButtons
          disabled
          text="SaaS Fee"
          handleChange={handleChange}
          fieldName={`${fieldPreffix}.saasFeeEnabled`}
        >
          <Field
            disabled
            topLabeled
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.saasFeeMonth`}
            placeholder=". . ."
            type="number"
            label="Monthly"
          />
          <Field
            disabled
            topLabeled
            component={InputField}
            preffix="$"
            name={`${fieldPreffix}.saasFeeAnnual`}
            placeholder=". . ."
            type="number"
            label="Annually"
          />
        </LicenceItem>
        {hasDiscount && (
          <LicenceItem
            noButtons
            text="Discount"
            handleChange={handleChange}
            fieldName={`${fieldPreffix}.discountEnabled`}
          >
            <Field
              component={InputField}
              suffix="%"
              name={`${fieldPreffix}.discount`}
              placeholder=". . ."
              type="number"
              validate={validators.requiredNaturalNumber}
            />
          </LicenceItem>
        )}
        {hasAddons && (
          <LicenceItem
            noButtons
            text="Add-Ons"
            handleChange={handleChange}
            fieldName={`${fieldPreffix}.addonsFeeEnabled`}
          >
            <Field
              disabled
              component={InputField}
              name={`${fieldPreffix}.addonsFee`}
              preffix="$"
              placeholder=". . ."
              type="number"
            />
            <Field
              component={SelectField}
              name={`${fieldPreffix}.saasFeePeriod`}
              options={licenceEnums.BILLING_CYCLE_REDUCED_OPTIONS}
              Trigger={<SelectTrigger placeholder="Billing Cycle" />}
              Content={<SelectContent />}
            />
          </LicenceItem>
        )}
      </div>
    </section>
  );
};

ProductsSetupSection.propTypes = {
  className: PropTypes.string,
  namePreffix: PropTypes.string,
  hasAddons: PropTypes.bool,
  hasDiscount: PropTypes.bool,
};

ProductsSetupSection.defaultProps = {
  className: '',
  namePreffix: '',
  hasAddons: false,
  hasDiscount: false,
};

export default ProductsSetupSection;
