import { IconButton } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import classNames from 'classnames';
import heic2any from 'heic2any';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImagePreview from '../ImagePreview';
import CSVPlaceholder from '../Placeholders/CSVPlaceholder';
import PdfPlaceholder from '../Placeholders/PdfPlaceholder';
import Spinner from '../Spinner';
import './styles.scss';

const DropzoneV2 = ({
  instructionsText,
  maxFiles = 3,
  accept,
  withPreview,
  input: { onChange, onBlur, value },
  withOnClickBlock,
  meta,
  onChangeCallback,
  tabIndex = 0,
  showRequiredErrorOnlyOnSubmit,
}) => {
  const baseClass = 'ickyc-dropzone-v2';
  const [limitMessage, setLimitMessage] = useState();
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    setLimitMessage(value?.length === maxFiles && 'You have reached the max number of files');
  }, [value, maxFiles]);
  const handleDocumentRemove = useCallback(
    (e, index) => {
      e.stopPropagation();
      onChange(value.filter((el, ind) => ind !== index));
    },
    [value, onChange],
  );

  const thumbs =
    Array.isArray(value) &&
    value.slice(0, maxFiles).map((fileInfo, index) => {
      if (fileInfo.typePdf) {
        return (
          <PdfPlaceholder
            url={fileInfo.preview}
            withRemove
            onRemove={e => handleDocumentRemove(e, index)}
            text={fileInfo.file.name}
          />
        );
      }
      if (fileInfo.typeCSV) {
        return <CSVPlaceholder text={fileInfo.file.name} withRemove onRemove={e => handleDocumentRemove(e, index)} />;
      }
      return (
        <ImagePreview
          src={fileInfo.preview}
          withRemove
          onRemove={e => handleDocumentRemove(e, index)}
          alt="preview"
          mini
          enlargeImage
        />
      );
    });

  const hasError = useMemo(() => {
    if (meta) {
      const { touched, error, submitFailed } = meta;
      if (showRequiredErrorOnlyOnSubmit && error?.match(/required/i)) {
        return error && submitFailed;
      } else {
        return error && touched;
      }
    }
    return false;
  }, [meta]);

  const documentRequired = useMemo(() => {
    if (meta) {
      const { touched, error } = meta;
      return error === 'Please select document type before uploading' && touched;
    }
    return false;
  }, [meta]);

  const errorMsg = useMemo(() => (meta ? meta.error : null), [meta]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive } = useDropzone({
    accept: accept === '*' ? undefined : accept,
    noClick: (documentRequired || limitMessage) && withOnClickBlock,
    onDrop: async acceptedFiles => {
      setUploading(true);
      if (limitMessage) {
        setUploading(false);
        return;
      }
      const freeSlots = maxFiles - value?.length;
      let tmpFile;
      try {
        tmpFile = await heic2any({ blob: acceptedFiles[0], toType: 'image/jpeg', quality: 0.5 });
      } catch (e) {
        console.log(e);
      }
      const filesToSave = [
        ...value,
        ...acceptedFiles.slice(0, freeSlots).map((file, index) => {
          return {
            file,
            preview: URL.createObjectURL(tmpFile || file),
            typePdf: file.type.includes('pdf'),
            typeCSV: file.type.includes('csv'),
            isImage: file.type.includes('image'),
          };
        }),
      ];

      onChange(filesToSave);
      onChangeCallback();
      setUploading(false);
      onChangeCallback();
    },
  });

  const classes = classNames({
    [baseClass]: true,
    [`${baseClass}--accept`]: isDragAccept,
    [`${baseClass}--reject`]: isDragReject || hasError,
  });

  const previewClasses = classNames({
    'ickyc-dropzone-v2__previews': true,
  });

  return (
    <div className={classes}>
      <div {...getRootProps({ onFocus: onBlur })} tabIndex={-1}>
        <input {...getInputProps()} />
        <p className="ickyc-dropzone-v2__instructions">
          {instructionsText && <span>{instructionsText}</span>}
          {uploading ? (
            <Spinner />
          ) : (
            <IconButton transparent>
              <CloudUpload />
            </IconButton>
          )}
          <span>
            <span>
              <b>Choose your {maxFiles > 1 ? 'files' : 'file'}</b>
              &nbsp;or drag it here
            </span>
            {hasError && <span className="ickyc-dropzone-v2__instructions--error">{errorMsg}</span>}
            {limitMessage && <span className="ickyc-dropzone-v2__instructions--error">{limitMessage}</span>}
          </span>
        </p>
        {withPreview && value.length > 0 && (
          <div className={previewClasses}>
            {thumbs}
            {isDragActive && <span className="ickyc-ghost" />}
          </div>
        )}
      </div>
    </div>
  );
};
DropzoneV2.propTypes = {
  maxFiles: PropTypes.number,
  accept: PropTypes.string,
  instructionsText: PropTypes.string,
  withPreview: PropTypes.bool,
  withOnClickBlock: PropTypes.bool,
  input: PropTypes.object,
  onChangeCallback: PropTypes.func,
  meta: PropTypes.shape({ invalid: PropTypes.bool, touched: PropTypes.bool, error: PropTypes.string }),
  showRequiredErrorOnlyOnSubmit: PropTypes.bool,
};
DropzoneV2.defaultProps = {
  maxFiles: 3,
  accept: undefined,
  withPreview: true,
  instructionsText: '',
  withOnClickBlock: false,
  onChangeCallback: () => {},
  input: {},
  meta: { invalid: false, touched: false, error: '' },
  showRequiredErrorOnlyOnSubmit: false,
};
export default DropzoneV2;
