/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ImagePreview from '../../../../../../../../../../components/ImagePreview';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../../utilities/services/IconManager';
import './index.scss';

function FaceMatch({ success, cropped, face, pending, procentage }) {
  const classBase = 'ickyc-face-match__status';
  const setSuffix = setClassSuffix(classBase);
  const statusContainerClasses = classNames({
    [classBase]: true,
    [setSuffix('--success')]: success && !pending,
    [setSuffix('--fail')]: success !== null && !success && !pending,
    [setSuffix('--pending')]: pending || success === null,
  });

  const statusIcon = useMemo(() => {
    if (pending || success === null)
      return (
        <>
          {IconManager.get(IconManager.names.QUESTION)}
          {success !== null && <b>Face Match Pending</b>}
        </>
      );

    return success ? (
      <>
        {IconManager.get(IconManager.names.CHECK_FILLED)}
        <b>Face Match Success</b>
      </>
    ) : (
      <>
        {IconManager.get(IconManager.names.CLOSE)}
        <b>Face Match Failed</b>
      </>
    );
  }, [success, pending]);
  return (
    <div className="ickyc-face-match">
      <h2>Face Match</h2>

      <div className="ickyc-face-match__content">
        {cropped ? (
          <ImagePreview mini src={cropped} alt="cropped" />
        ) : (
          <span className="ickyc-face-match__content__no-image">
            {IconManager.get(IconManager.names.TEMPLATE_IMAGE)}
          </span>
        )}

        <div className={statusContainerClasses}>
          {statusIcon}
          {procentage && <span>{procentage}%</span>}
        </div>
        {face ? (
          <ImagePreview mini src={face} alt="face" />
        ) : (
          <span className="ickyc-face-match__content__no-image">
            {IconManager.get(IconManager.names.TEMPLATE_IMAGE)}
          </span>
        )}
      </div>
    </div>
  );
}

FaceMatch.propTypes = {
  success: PropTypes.bool,
  cropped: PropTypes.string,
  face: PropTypes.string,
  pending: PropTypes.string,
  procentage: PropTypes.number,
};

FaceMatch.defaultProps = {
  success: false,
  cropped: '',
  face: '',
  pending: false,
  procentage: null,
};

export default FaceMatch;
