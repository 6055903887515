import api from 'api';
import DatePickerField from 'components/DatepickerField';
import InputField from 'components/InputField';
import Loader from 'components/Loader';
import FormModal from 'components/Modal/FormModal';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import ToggleField from 'components/ToggleField';
import { FORM_ERROR } from 'final-form';
import useGroupPermissions from 'hooks/useGroupPermissions';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router';
import LicenceAddons from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceAddons';
import ModulesSection from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/ModulesSection';
import ProductsSection from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/ProductsSection';
import ProductsSetupSection from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/ProductsSetupSection';
import SetupSection from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/SetupSection';
import toastr from 'toastr';
import authEnums from 'utilities/enums/authEnums';
import licenceEnums from 'utilities/enums/licenceEnums';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import DeleteButton from '../../../../../../../../../components/Buttons/DeleteButton';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import LicenceManagementService from '../../services/LicenceManagement.service';
import Billing from '../Billing';
import CustomUrlsRow from '../CustomUrlsRow';
import KYCServices from '../KYCServices';
import KYCServicePlan from '../KYCServicesPlan';
import OverageNumOfPortals from '../OverageNumOfPortals';
import SaasFee from '../SaasFee';
import UsersAndLimitsRow from '../UsersAndLimitsRow';
import utils from './utils';

const { calculateTermBasedOnEndDate, calculateLicenseEndDate } = utils;
const BusinessLicenceRow = ({
  index,
  namePreffix,
  className,
  licenceTypeOptions,
  onRemove,
  onControlsClick,
  onLoadingLicence,
  products,
}) => {
  const setSufix = setClassSuffix(`${className}__column`);
  const { mutators, change } = useForm();
  const { dirty } = useFormState();
  const { id: clientId } = useParams();
  const [fetchingLicence, setFetchingLicence] = useState(false);
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.LICENCE_MANAGER);

  const {
    input: { value: rowData },
  } = useField(namePreffix);

  const { licenceId, licenceStatus, startDate, type: licenceType, modules, id, webProductsIds } = rowData;

  const namePreffixDot = useMemo(() => `${namePreffix}.`, [namePreffix]);

  const {
    paymentDueCount,
    paymentDueType,
    term,
    termPeriod,
    saasFee,
    saasBillingCycle,
    kycServicePlan,
    kycBillingCycle,
    status: defaultStatus,
  } = rowData.defaultValues || {};

  const isRowDisabled = defaultStatus === '1'; // expired status licence is disabled
  const isLiceneceTerminated = defaultStatus === '3';

  const manageLicenceStatusPlaceholder = useMemo(() => {
    if (licenceStatus === licenceEnums.LICENCE_STATUS_TYPES.EXPIRED) {
      return 'Expired';
    }
    if (licenceStatus === licenceEnums.LICENCE_STATUS_TYPES.TERMINATED) {
      return 'Terminated';
    }
    return 'Choose';
  }, [licenceStatus]);

  const isCancelled = useMemo(() => licenceStatus === licenceEnums.LICENCE_STATUS_TYPES.CANCELED, [licenceStatus]);

  const {
    isOpen: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModalHandler();

  const removeLicence = useCallback(() => {
    if (rowData?.id) {
      onControlsClick(true);
      api.businessManager.licences
        .deleteClientLicence(rowData?.id, clientId)
        .then(() => {
          toastr.success('Succesfully deleted License');
          mutators.remove(`licences`, index);
          closeConfirmationModal();
        })
        .catch(err => {
          toastr.error('Error when deleteing License');
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return {
                [FORM_ERROR]: errData.message,
              };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else
            return {
              [FORM_ERROR]: 'Error occured',
            };
        })
        .finally(() => {
          onControlsClick(false);
        });
      onRemove({
        id: rowData?.id,
      });
    } else {
      onRemove();
      mutators.remove(`licences`, index);
      toastr.success('Succesfully deleted License');
      closeConfirmationModal();
    }
  }, [rowData.id, onControlsClick, clientId, onRemove, mutators, index, closeConfirmationModal]);

  const handleChangeStartDate = useCallback(
    startDate => {
      const termValue = term ? Number(term) : '';

      if (termValue === '' || !startDate) return;

      const endDate = calculateLicenseEndDate(startDate, termValue, termPeriod);
      change(`${namePreffixDot}endDate`, endDate);
    },
    [term, termPeriod, namePreffixDot],
  );

  const handleChangeEndDate = useCallback(
    endDate => {
      if (!endDate) return;

      const term = calculateTermBasedOnEndDate(startDate, endDate, termPeriod);
      change(`${namePreffixDot}defaultValues.term`, term);
    },
    [startDate, termPeriod, namePreffixDot],
  );

  const handleChangeTerm = useCallback(
    event => {
      const termFieldValue = event?.target?.value;
      const termValue = termFieldValue ? Number(termFieldValue) : '';

      if (termValue === '' || !startDate) return;

      const endDate = calculateLicenseEndDate(startDate, termValue, termPeriod);
      change(`${namePreffixDot}endDate`, endDate);
    },
    [startDate, termPeriod, namePreffixDot],
  );

  const handleChangeTermPeriod = useCallback(
    termPeriod => {
      const termValue = term ? Number(term) : '';

      if (termValue === '' || !startDate) return;

      const endDate = calculateLicenseEndDate(startDate, termValue, termPeriod);
      change(`${namePreffixDot}endDate`, endDate);
    },
    [term, startDate, namePreffixDot],
  );

  const handleOnClick = e => e.stopPropagation();

  // Term * Frequency * SaaS fee + (kyc services plan * term * frequency) + overage billed
  useEffect(() => {
    const calculateContractValue = () => {
      function getFrequency(billingCycle) {
        switch (billingCycle) {
          case '0':
            return 1; // Monthly
          case '1':
            return 3; // Quarterly
          default:
            return 12; // Annually & By Contract
        }
      }

      let contractValue = 0;
      const saasFrequency = getFrequency(saasBillingCycle);
      const kycPlanFrequency = getFrequency(kycBillingCycle);

      // // value by month
      let termValue = term;
      // value by day
      if (termPeriod === 0) termValue /= 30;

      // "ceil" becaus we need to add price of billing cycle as soon as it start
      contractValue =
        Math.ceil(termValue / kycPlanFrequency) * kycServicePlan + Math.ceil(termValue / saasFrequency) * saasFee;

      change(`${namePreffixDot}contractValue`, parseFloat(contractValue.toFixed(2)));
    };

    if (term && saasFee && saasBillingCycle && dirty) calculateContractValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, saasFee, saasBillingCycle, termPeriod, kycServicePlan]);

  useEffect(() => {
    const fetchSelectedLicenceInfo = id => {
      setFetchingLicence(true);
      onLoadingLicence(namePreffix);
      api.businessManager.licences
        .getLicence(id)
        .then(res => {
          const { data } = res;
          const { type } = data;

          if (type === licenceEnums.LICENCE_TYPES.WEBSITE) {
            const { id, ...websiteLicence } = LicenceManagementService.prepareWebsiteLicence(data);

            change(namePreffix, {
              ...rowData,
              ...websiteLicence,
              licenceStatus,
            });

            return;
          }

          const { defaultValues } = LicenceManagementService.prepareValuesForModal(data);
          const { supportingDocumentPackagesEnabled, riskScreeningEnabled } = data;

          change(namePreffix, {
            ...rowData,
            defaultValues: {
              ...defaultValues,
              paymentDueCount,
              paymentDueType,
              term,
            },
            licenceStatus,
            supportingDocumentPackagesEnabled,
            riskScreeningEnabled,
            type: licenceEnums.LICENCE_TYPES.CUSTOM,
          });
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return {
                [FORM_ERROR]: errData.message,
              };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else
            return {
              [FORM_ERROR]: 'Error occured',
            };
        })
        .finally(() => {
          setFetchingLicence(false);
          onLoadingLicence(namePreffix);
        });
    };

    if (dirty && licenceId) {
      fetchSelectedLicenceInfo(Number(licenceId));
    }
  }, [licenceId, change]);

  return (
    <CollapsibleTableRow
      className={className}
      row={
        <>
          {fetchingLicence && <Loader />}
          <td className={setSufix('__licenceType')} onClick={handleOnClick}>
            <Field
              name={`${namePreffixDot}licenceId`}
              options={licenceTypeOptions}
              component={SelectField}
              disabled={isRowDisabled || id}
              Trigger={<SelectTrigger placeholder="Choose" />}
              Content={<SelectFilterContent />}
              validate={validators.required(' ')}
              renderAsPortal
            />
          </td>
          <td className={setSufix('__startDate')} onClick={handleOnClick}>
            <Field
              component={DatePickerField}
              name={`${namePreffixDot}startDate`}
              disabled={isRowDisabled}
              onChange={handleChangeStartDate}
              validate={validators.composeValidators(
                validators.required(' '),
                validators.compareLicenceDates(index, 'Invalid Date range'),
                validators.checkActiveStartDate(index, "Start date for Active license can't be in the future"),
                validators.checkUpcomingStartDate(index, 'Start date for Upcoming license must be in the future'),
              )}
            />
          </td>
          <td className={setSufix('__endDate')} onClick={handleOnClick}>
            <Field
              component={DatePickerField}
              disabled={isRowDisabled || isLiceneceTerminated}
              name={`${namePreffixDot}endDate`}
              onChange={handleChangeEndDate}
              endOfDay
              validate={validators.composeValidators(
                validators.required(' '),
                validators.compareLicenceDates(index, 'Invalid Date range'),
                validators.checkActiveEndDate(index, "End date for Active license can't be in the past"),
              )}
            />
          </td>
          <td className={setSufix('__status')} onClick={handleOnClick}>
            <Field
              name={`${namePreffixDot}licenceStatus`}
              options={licenceEnums.STATUS_OPTIONS}
              disabled={isRowDisabled || isCancelled}
              component={SelectField}
              Trigger={<SelectTrigger placeholder={manageLicenceStatusPlaceholder} />}
              Content={<SelectFilterContent />}
              renderAsPortal
              validate={validators.composeValidators(
                validators.required(' '),
                validators.customLicenceStatusValidator(index, 'Only one active license is allowed'),
              )}
            />
          </td>
          <td className={setSufix('__term')} onClick={handleOnClick}>
            <Field
              name={`${namePreffixDot}defaultValues.term`}
              disabled={isRowDisabled}
              placeholder=". . ."
              type="number"
              component={InputField}
              onChange={handleChangeTerm}
              parse={value => (value ? Number(value) : '')}
              validate={validators.required(' ')}
            />
          </td>
          <td className={setSufix('__termPeriod')} onClick={handleOnClick}>
            <Field
              name={`${namePreffixDot}defaultValues.termPeriod`}
              options={licenceEnums.TERM_PERIOD_OPTIONS}
              component={SelectField}
              disabled={isRowDisabled}
              onChange={handleChangeTermPeriod}
              Trigger={<SelectTrigger placeholder="Period" />}
              Content={<SelectFilterContent />}
              renderAsPortal
              validate={validators.required(' ')}
            />
          </td>
          <td className={setSufix('__autoRenew')} onClick={handleOnClick}>
            <Field
              name={`${namePreffixDot}autoRenew`}
              component={ToggleField}
              disabled={isRowDisabled}
              showCustomText
              toggleOnText="Yes"
              toggleOffText="No"
            />
          </td>
          <td className={setSufix('__remove')} onClick={handleOnClick}>
            {groupPermissions?.delete && <DeleteButton onClick={() => openConfirmationModal()} />}
            <div>
              {isConfirmationModalOpen && (
                <FormModal
                  hideModal={closeConfirmationModal}
                  title="Remove License"
                  className="ickyc-confirmation-modal"
                  onSubmit={removeLicence}
                >
                  <span className="ickyc-confirmation-message">Are you sure you want to delete License?</span>
                </FormModal>
              )}
            </div>
          </td>
        </>
      }
    >
      {licenceType !== '' && (
        <div>
          <section className="ickyc-section ickyc-section--with-divider">
            <div className="ickyc-section__licence-type">
              <h3>License Type</h3>

              <p>{licenceType === licenceEnums.LICENCE_TYPES.CUSTOM ? 'Custom' : 'Website'}</p>
            </div>
            <div className="ickyc-section__payment-method">
              <h3>Payment Method</h3>

              <Field
                name={`${namePreffixDot}paymentMethod`}
                options={licenceEnums.PAYMENT_METHODS}
                component={SelectField}
                Trigger={<SelectTrigger placeholder="Choose" />}
                Content={<SelectFilterContent />}
                renderAsPortal
                validate={validators.required(' ')}
              />
            </div>
          </section>

          {licenceType === licenceEnums.LICENCE_TYPES.CUSTOM && (
            <>
              <Billing namePreffix={namePreffix} disabled={isRowDisabled} />
              <SaasFee namePreffix={namePreffix} disabled={isRowDisabled} />
              <KYCServicePlan namePreffix={namePreffix} disabled={isRowDisabled} />
              <OverageNumOfPortals namePreffix={namePreffix} disabled={isRowDisabled} />
              <CustomUrlsRow namePreffix={namePreffix} disabled={isRowDisabled} />
              <UsersAndLimitsRow namePreffix={namePreffix} disabled={isRowDisabled} index={index} />
              <KYCServices namePreffix={namePreffix} disabled={isRowDisabled} />
            </>
          )}

          {licenceType === licenceEnums.LICENCE_TYPES.WEBSITE &&
            (webProductsIds?.length > 0 ? (
              <>
                <ProductsSetupSection
                  hasDiscount
                  className="products-setup-section--client"
                  namePreffix={namePreffix}
                />
                <ProductsSection
                  className="products-section--client"
                  namePreffix={namePreffix}
                  products={products}
                  initialWebProductsIds={webProductsIds}
                />
              </>
            ) : (
              <>
                <SetupSection hasAddons namePreffix={namePreffix} />
                <ModulesSection divider namePreffix={namePreffix} initialModules={modules} />
                <LicenceAddons namePreffix={namePreffix} />
              </>
            ))}
        </div>
      )}
    </CollapsibleTableRow>
  );
};

BusinessLicenceRow.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  namePreffix: PropTypes.string.isRequired,
  licenceTypeOptions: PropTypes.array,
  onRemove: PropTypes.func,
  onControlsClick: PropTypes.func,
  onLoadingLicence: PropTypes.func,
};
BusinessLicenceRow.defaultProps = {
  className: '',
  licenceTypeOptions: [],
  onRemove: () => {},
  onControlsClick: () => {},
  onLoadingLicence: () => {},
};
export default BusinessLicenceRow;
