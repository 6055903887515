import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import LicenceItem from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceItem';
import LicenceModule from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceModule';
import validators from 'utilities/services/validators';

const AMLModule = ({ namePreffix, disabled, ...rest }) => {
  return (
    <LicenceModule
      title="AML"
      headeritems={
        <>
          <LicenceItem noButtons text="Cases" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.aml.casesLimit`}
              type="number"
              placeholder="Maximum number"
              validate={validators.requiredNumber}
              disabled={disabled}
            />
          </LicenceItem>
          <LicenceItem noButtons text="Risk Screening" disabled={disabled}>
            <Field
              component={InputField}
              name={`${namePreffix}.aml.riskScreeningProfilesLimit`}
              type="number"
              placeholder="Maximum number"
              validate={validators.requiredNumber}
              disabled={disabled}
            />
          </LicenceItem>
        </>
      }
      {...rest}
    >
      <Field
        component={ToggleField}
        name={`${namePreffix}.aml.ongoingMonitoringEnabled`}
        label="Ongoing Monitoring"
        disabled={disabled}
      />
    </LicenceModule>
  );
};

AMLModule.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};

AMLModule.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default AMLModule;
