/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import enums from 'utilities/enums';
import api from '../api';
import { setPersonalInfo } from '../store/actions/auth.action';
import {
  saveDefaultScreeningProfile,
  saveRiskLevels,
  saveUsersToAssign,
  setCountries,
  setCurrencies,
  setDocumentPackages,
  setNotifications,
} from '../store/actions/global.action';
import authEnums from '../utilities/enums/authEnums';
/**
 * App's listener for centralised API calls
 * @param {boolean} isPrivate - is user on the private, or the public page
 */

const useCentralisedApi = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPersonalInfo = async () => {
      setIsLoading(true);
      const promiseArray = [];
      const handlersArray = [];

      let data = {};

      try {
        const { data: personalInfo } = await api.clientUser.getPersonalInfo();
        data = { ...data, ...personalInfo };
        const { roles, accessByLicence } = personalInfo;

        if (
          !roles.some(
            role =>
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
          )
        ) {
          promiseArray.push(
            api.notifications.fetchNotifications({
              limit: 10,
              sortOrder: enums.SORTING.DESCEDING,
              sortCriteria: 'dateCreated',
            }),
          );
          handlersArray.push(setNotifications);
        }

        if (
          !roles.some(role => {
            return (
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER
            );
          })
        ) {
          promiseArray.push(api.kyc.getClientUsers());
          handlersArray.push(saveUsersToAssign);

          if (accessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.RISK_LEVELS)) {
            promiseArray.push(api.administration.getRiskLevels());
            handlersArray.push(saveRiskLevels);
          }

          if (accessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS)) {
            promiseArray.push(api.kyc.entityManagement.getSupportingPackages());
            handlersArray.push(setDocumentPackages);
          }

          if (accessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.RISK_SCREENING)) {
            api.kyc.caseManagement
              .getDefaultScreeningProfile()
              .then(({ data: screeningData }) => {
                dispatch(saveDefaultScreeningProfile(screeningData));
              })
              .catch(() => {
                dispatch(
                  saveDefaultScreeningProfile({
                    addCountry: true,
                    addYear: true,
                    entityType: 1,
                    fuzziness: 50,
                    searchTerms: [],
                    sources: [],
                  }),
                );
              });
          }
          if (accessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.NOTIFICATIONS)) {
            promiseArray.push(
              api.notifications.fetchNotifications({
                limit: 10,
                sortOrder: enums.SORTING.DESCEDING,
                sortCriteria: 'dateCreated',
              }),
            );
            handlersArray.push(setNotifications);
          }
        }
      } catch (err) {}

      promiseArray.push(api.utilities.getCountries());
      handlersArray.push(setCountries);
      promiseArray.push(api.utilities.getCurrencies());
      handlersArray.push(setCurrencies);

      Promise.all(promiseArray).then(responses => {
        responses.forEach((res, index) => {
          if (res.status === 200) {
            const f = handlersArray[index];
            dispatch(f(res.data));
          }
        });
      });
      dispatch(setPersonalInfo(data));
      setIsLoading(false);
    };

    fetchPersonalInfo();
  }, [dispatch]);

  return isLoading;
};

export default useCentralisedApi;
