import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputField from 'components/InputField';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import Tooltip from 'components/Tooltip';
import React, { useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import enums from 'utilities/enums';

const ScrapedAffiliatedEntityRow = ({ namePreffix, addAffiliation, entityTableName }) => {
  const {
    input: {
      value: { type, entityName, ...rest },
    },
  } = useField(namePreffix);

  const {
    input: { value: affiliatedEntities },
  } = useField(entityTableName);

  const isInAffiliationTable = useMemo(
    () =>
      affiliatedEntities?.some(entity =>
        entityName?.includes(type === '0' ? `${entity.name} ${entity.lastName}` : entity.name),
      ),
    [affiliatedEntities, entityName, type],
  );

  return (
    <div className="ickyc-link-affiliated-entities-form__affiliation-row">
      <div className="ickyc-link-affiliated-entities-form__affiliation-row__fields">
        <Field
          name={`${namePreffix}.entityName`}
          component={InputField}
          topLabeled
          label="Affiliated Entity Name"
          readOnly
        />
        <Field
          name={`${namePreffix}.type`}
          component={SelectField}
          label="Entity Type"
          options={enums.ENTITY_TYPES}
          Trigger={<SelectTrigger placeholder={'Select Entity Type'} />}
          Content={<SelectContent />}
          labelPosition="top"
          disabled={isInAffiliationTable}
          required
        />
      </div>
      {isInAffiliationTable || type === '' ? (
        <Tooltip
          trigger={
            <div>
              <PrimaryButton disabled onClick={() => addAffiliation(namePreffix)}>
                Accept Affiliated Entity
              </PrimaryButton>
            </div>
          }
          content={type !== '' ? 'This entity is already added' : 'Please select Entity Type'}
        />
      ) : (
        <PrimaryButton onClick={() => addAffiliation(namePreffix)}>Accept Affiliated Entity</PrimaryButton>
      )}
    </div>
  );
};

export default ScrapedAffiliatedEntityRow;
