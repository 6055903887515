import { CircularProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router';
import api from '../../../../api';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import InputField from '../../../../components/InputField';
import authEnums from '../../../../utilities/enums/authEnums';
import TokenStorage from '../../../../utilities/services/local-storage/TokenStorage';
import validators from '../../../../utilities/services/validators';

const LoginForm = ({ onForgotClick }) => {
  const history = useHistory();
  const handleSigninSubmit = credentials => {
    return api.auth
      .login(credentials)
      .then(async response => {
        const { token, refreshToken, twoFactorAuthentication, roles, requiredOnboardStages } = response.data;
        // // Saving token inside LS
        // // // Saving token inside redux
        if (requiredOnboardStages.includes('IDVERIFICATION')) {
          /// levo ili desno
          history.push(
            `/auth/basic-info-identity?verificationtoken=${token}&twofactor=${twoFactorAuthentication ===
              authEnums.TWO_FACTOR_AUTHENTICATION.EnabledWithoutAuthentificator}`,
          );
        } else if (requiredOnboardStages.includes('PERSONALDETAILS')) {
          /// levo ili desno
          history.push(
            `/auth/basic-info?verificationtoken=${token}&twofactor=${twoFactorAuthentication ===
              authEnums.TWO_FACTOR_AUTHENTICATION.EnabledWithoutAuthentificator}`,
          );
        } else if (requiredOnboardStages.includes('TWOFACTORAUTHENTICATION')) {
          history.push(`/auth/two-factor-authentication/enable-authenticator?token=${token}`);
        } else if (twoFactorAuthentication === authEnums.TWO_FACTOR_AUTHENTICATION.EnabledWithAuthentificator) {
          history.push(`/auth/two-factor-authentication/code-confirmation?token=${token}`);
        } else {
          TokenStorage.token = token;
          TokenStorage.refreshToken = refreshToken;
          if (
            roles?.some(role => {
              return (
                role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN ||
                role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
                role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER
              );
            })
          ) {
            history.push('/internal/business-manager');
          } else {
            history.push('/kyc/home-screen');
          }
        }
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.error };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        }
      });
  };
  const baseClass = 'ickyc-form';

  return (
    <FinalForm onSubmit={handleSigninSubmit}>
      {({ handleSubmit, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={baseClass}>
            <>
              <div className={`${baseClass}__fields`}>
                <Field
                  component={InputField}
                  name="username"
                  label="Email"
                  autocomplete="on"
                  validate={validators.composeValidators(
                    validators.required(),
                    validators.email('Invalid email address'),
                  )}
                />
                <Field
                  component={InputField}
                  name="password"
                  label="Password"
                  type="password"
                  data-lpignore="false"
                  validate={validators.required()}
                />

                <span className={`${baseClass}__fields__forgot-password`} onClick={onForgotClick}>
                  Forgot Password?
                </span>
                {submitError && <div className={`${baseClass}__fields__error`}>{submitError}</div>}
              </div>

              <div className={`${baseClass}__actions`}>
                <PrimaryButton type="submit" disabled={submitting}>
                  Sign In
                  {submitting && <CircularProgress />}
                </PrimaryButton>
              </div>
            </>
          </form>
        );
      }}
    </FinalForm>
  );
};

LoginForm.propTypes = {
  onForgotClick: PropTypes.func,
};
LoginForm.defaultProps = {
  onForgotClick: () => {},
};
export default LoginForm;
