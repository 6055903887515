import api from 'api';
import IComplyForm from 'components/Form/IComplyForm';
import ButtonHeading from 'components/Headings/ButtonHeading';
import Spinner from 'components/Spinner';
import FieldArrayTable from 'components/Tables/FieldArrayTable';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';
import useTable from 'hooks/useTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useParams } from 'react-router';
import toastr from 'toastr';
import licenceEnums from 'utilities/enums/licenceEnums';
import BusinessLicenceRow from './components/BusinessLicenceRow';
import LicenceManagementService from './services/LicenceManagement.service';
import './styles.scss';

const focusOnErrors = createDecorator();

const BusinessLicenseSection = () => {
  const { id } = useParams();
  const [licenceTypeOptions, setLicenceTypeOptions] = useState([]);
  const [deletingLicence, setDeletetingLicence] = useState(false);
  const [initialValues, setInitialValues] = useState({ licences: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLicence, setIsLoadingLicence] = useState(new Set());
  const [products, setProducts] = useState([]);

  const { id: clientId } = useParams();

  const {
    records,
    pagination,
    isLoading: isLoadingTableData,
    changeParams,
    afterAddRowFieldArray,
    afterRemoveRowFieldArray,
  } = useTable(api.businessManager.licences.getClientLicences, {
    clientId,
  });

  const onSaveChanges = async values => {
    const submitObj = LicenceManagementService.normalizeLicenceData(values);
    try {
      const { data } = await api.businessManager.licences.updateClientLicences(id, submitObj);
      if (values?.licences.length) {
        let tmpindex = 0;
        setInitialValues({
          licences: values?.licences.map(licc => {
            if (licc?.id) {
              return {
                ...licc,
                status: licenceEnums.LICENCE_STATUS_OPTIONS.EXISTING,
              };
            }
            return {
              ...licc,
              status: licenceEnums.LICENCE_STATUS_OPTIONS.EXISTING,
              // eslint-disable-next-line no-plusplus
              id: data[tmpindex++]?.id,
            };
          }),
        });
      }
      toastr.success('Licenses succesfully updated!');
    } catch (err) {
      toastr.error('Error while updating licenses!');
      if (err?.response) {
        const { status: resStatus, data: data_1 } = err.response;
        if (resStatus >= 400 && resStatus < 500) {
          return { [FORM_ERROR]: Array.isArray(data_1.message) ? data_1.message.join('') : data_1.message };
        }
        if (resStatus === 500) {
          return {
            [FORM_ERROR]: Array.isArray(data_1.message)
              ? data_1.message.join('')
              : data_1.message || 'Internal Server Error, Try Again Later',
          };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };

  const onLoadingLicence = useCallback(loadingLicenceName => {
    setIsLoadingLicence(prevLoadingLicence => {
      const updatedLoadingLicence = new Set(prevLoadingLicence);

      updatedLoadingLicence.has(loadingLicenceName)
        ? updatedLoadingLicence.delete(loadingLicenceName)
        : updatedLoadingLicence.add(loadingLicenceName);

      return updatedLoadingLicence;
    });
  }, []);

  useEffect(() => {
    const fetchLicenceOptions = () => {
      setIsLoading(true);
      api.businessManager.licences
        .getAllLicencesOptions()
        .then(({ data: responseData }) => {
          setLicenceTypeOptions(responseData.map(el => ({ id: el?.id, label: el?.name, value: el?.id })));
        })
        .catch(err => {
          if (err?.response) {
            const { status, data } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(data.message)
                  ? data.message.join('')
                  : data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchLicenceOptions();
  }, [id]);

  useEffect(() => {
    setInitialValues({
      licences: LicenceManagementService.parseLicenceData(records),
    });
  }, [records]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const {
          data: { data: products },
        } = await api.internal.webProducts.getWebProducts(true);

        setProducts(products);
      } catch (err) {
        toastr.error('Error occured while getting products');
      }
    };

    getProducts();
  }, []);

  const onRemoveHandler = () => {
    afterRemoveRowFieldArray();
  };

  const TableControls = () => {
    const {
      fields: { push },
    } = useFieldArray('licences');

    return (
      <ButtonHeading
        title={'Licences'}
        buttonCopies={[<>+ Add Licence</>]}
        clickHandlers={[
          () => {
            afterAddRowFieldArray();
            push({
              status: licenceEnums.LICENCE_STATUS_OPTIONS.NEW,
              billingDate: 1,
              defaultValues: { paymentDueCount: 15, paymentDueType: '0' },
            });
          },
        ]}
      />
    );
  };

  return (
    <div className="ickyc-licences-section">
      {isLoading ? (
        <Spinner />
      ) : (
        <IComplyForm
          mutators={{ ...arrayMutators }}
          onSubmit={onSaveChanges}
          initialValues={initialValues}
          showControls
          decorators={[focusOnErrors]}
          disableButtons={isLoadingLicence?.size > 0}
        >
          <div className="ickyc-licences-table">
            <FieldArrayTable
              withPagination
              pagination={pagination}
              fieldName="licences"
              tableControls={TableControls}
              tableRow={BusinessLicenceRow}
              headerRow={TableHeader}
              onRemove={onRemoveHandler}
              updating={isLoadingTableData || deletingLicence}
              onLoadingLicence={onLoadingLicence}
              handleParamsChange={changeParams}
              licenceTypeOptions={licenceTypeOptions}
              onControlsClick={flag => setDeletetingLicence(flag)}
              headerData={{
                items: [
                  { type: 'arrow' },
                  { type: 'licence', label: 'License' },
                  { type: 'startDate', label: 'Start Date' },
                  { type: 'endDate', label: 'End Date' },
                  { type: 'status', label: 'Status' },
                  { type: 'term', label: 'Term' },
                  { type: 'termPeriod' },
                  { type: 'autoRenew', label: 'Auto Renew' },
                  { type: 'remove' },
                ],
              }}
              noResultMessage="No Licences To Show"
              products={products}
            />
          </div>
        </IComplyForm>
      )}
    </div>
  );
};
export default BusinessLicenseSection;
