import DocumentIcon from 'assets/DocumentIcon';
import DocumentDropzoneField from 'components/DocumentsAndCustomQuestions/components/DocumentDropzoneField';
import TextareaField from 'components/TextareaField';
import React from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';
import utils from '../../utils';

export default ({ setSuffix }) => (
  <>
    <td className={setSuffix('__type')}>
      <DocumentIcon />
      <span>Document Upload</span>
    </td>
    <td className={setSuffix('__description')} onClick={utils.stopPropagation}>
      <Field
        label="Description"
        labelPosition="top"
        name="description"
        component={TextareaField}
        validate={validators.required()}
      />
    </td>
    <td className={setSuffix('__document-template')} onClick={utils.stopPropagation}>
      <Field name="document" component={DocumentDropzoneField} type="file" allowMultiple={false} />
    </td>
  </>
);
