import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import TagsContainer from '../../../../../../../components/TagButtonContainer';
import utilities from '../../../../../../../utilities';
import routesEnum from '../../../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import { CaseContext, MatchesFilterContext } from '../../../../../../../utilities/services/contexts';
import { AML_FILTERS } from '../../../../enums';
import HitStatusRowControls from '../../../HitStatusRowControls';

const MatchesTableRow = ({
  rowInfo: { id, checked, name, entityType, relevance, commentsCount, countries, matched, resultsFound },
  className,
  onCheckboxClick,
  onControlsClick,
}) => {
  const { complianceLogId: caseId, isCaseClosed } = useContext(CaseContext);
  const { selectedAMLFilters } = useContext(MatchesFilterContext);

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const handleCheckBoxClick = useCallback(() => {
    onCheckboxClick(id);
  }, [id, onCheckboxClick]);

  const handleChange = useCallback(
    value => {
      onControlsClick(id, value);
    },
    [id, onControlsClick],
  );

  const navigateToListings = useMemo(() => {
    return utilities.routeParams(routesEnum.kyc.CASE_MATCH_DETAILS, {
      complianceLogId: caseId,
      hitId: id,
    });
  }, [id, caseId]);

  const mappedMatches = useMemo(() => matched.map(match => ({ name: AML_FILTERS[match] })), [matched]);

  return (
    <tr key={id} className={`${className}__row`} on>
      <td className={setColumnSuffix('__checkbox')}>
        <input
          data-lpignore="true"
          type="checkbox"
          checked={checked}
          disabled={isCaseClosed}
          readOnly
          onChange={handleCheckBoxClick}
        />
      </td>

      <td className={setColumnSuffix('__name')}>
        <NavLink to={navigateToListings}>{name}</NavLink>
      </td>
      <td className={setColumnSuffix('__type')}>
        <NavLink to={navigateToListings}>{entityType}</NavLink>
      </td>

      <td className={setColumnSuffix('__relevance')}>
        <NavLink to={navigateToListings}>{relevance}</NavLink>
      </td>

      <td className={setColumnSuffix('__countries')}>
        <NavLink to={navigateToListings}>{countries}</NavLink>
      </td>

      <td className={setColumnSuffix('__riskTypeCount')}>
        <TagsContainer
          options={mappedMatches}
          red
          outline
          preview
          filtersToHighlite={selectedAMLFilters.map(val => AML_FILTERS[val] || '')}
        />
      </td>

      <td className={setColumnSuffix('__resultsFound')}>
        <NavLink to={navigateToListings}>{resultsFound}</NavLink>
      </td>
      <td className={setColumnSuffix('__comments')}>
        <NavLink to={navigateToListings}>
          {commentsCount > 0 && (
            <>
              <h3>{commentsCount}</h3>
              <ChatBubbleIcon />
            </>
          )}
        </NavLink>
      </td>
      <td className={setColumnSuffix('__controls')}>
        <HitStatusRowControls onClick={handleChange} isControlActive={!isCaseClosed} />
      </td>
    </tr>
  );
};

MatchesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool,
    alpha2Code: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
    entityType: PropTypes.string,
    relevance: PropTypes.string,
    countries: PropTypes.string,
    matched: PropTypes.string,
    messageNum: PropTypes.string,
    commentsCount: PropTypes.number,
  }),
  className: PropTypes.string.isRequired,
  onCheckboxClick: PropTypes.func,
  onControlsClick: PropTypes.func,
};

MatchesTableRow.defaultProps = {
  rowInfo: {
    alpha2Code: '',
    name: '',
    value: '',
    text: '',
    checked: false,
    commentsCount: 0,
  },
  onCheckboxClick: () => {},
  onControlsClick: () => {},
};

export default React.memo(MatchesTableRow);
