import CustomQuestionIcon from 'assets/CustomQuestionIcon';
import classNames from 'classnames';
import DeleteButton from 'components/Buttons/DeleteButton';
import CheckboxGroup from 'components/CheckboxGroup';
import Input from 'components/Input';
import FormModal from 'components/Modal/FormModal';
import RadioGroup from 'components/RadioGroup';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import React, { useMemo } from 'react';
import CollapsibleTableRow from 'scenes/Kyc/components/CollapsibleTableRow';
import {
  itemTypes,
  typeToProps,
} from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/components/PackageItemsFields/utils';
import setClassSuffix from 'utilities/services/ClassManager';
import './styles.scss';

const CustomQuestionTableRow = ({ className, index, rowInfo, onPackageItemDelete }) => {
  const { description, itemType, answers } = rowInfo;
  const { content, questionParameters } = answers[0];
  const contentObject = JSON.parse(content);
  const questionParametersObject = JSON.parse(questionParameters, undefined, content);
  const setSufix = setClassSuffix(`${className}__column`);
  const {
    open: openConfirmationMOdal,
    close: closeConfirmationModal,
    isOpen: isConfirmationModalOpen,
  } = useModalHandler();

  const classes = classNames('ickyc-document-package-table-row', className);

  const answerText = useMemo(() => {
    const formatters = {
      [itemTypes.CURRENCYFIELD]: ({ symbol, amount }) => symbol + amount,
      [itemTypes.TEXTFIELD]: ({ typedText }) => typedText,
      [itemTypes.NUMERICALFIELD]: ({ insertedNumber }) => insertedNumber,
      [itemTypes.PERCENTAGEFIELD]: ({ insertedNumber }) => `${insertedNumber}%`,
      [itemTypes.MULTIPLECHOICE]: ({ acceptedAnswers, otherAnswer }) => [...acceptedAnswers, otherAnswer].join(', '),
      [itemTypes.DISCRETECHOICE]: ({ acceptedAnswer, otherAnswer }) => otherAnswer || acceptedAnswer,
    };

    return formatters[itemType]?.(contentObject) ?? 'N/A';
  }, [itemType, contentObject]);

  const answerComponent = useMemo(() => {
    const typeToComponent = {
      [itemTypes.TEXTFIELD]: <div className="ickyc-document-package-table-row__answer-box">{answerText}</div>,
      [itemTypes.CURRENCYFIELD]: <div className="ickyc-document-package-table-row__answer-box">{answerText}</div>,
      [itemTypes.NUMERICALFIELD]: <div className="ickyc-document-package-table-row__answer-box">{answerText}</div>,
      [itemTypes.MULTIPLECHOICE]: (
        <div className="ickyc-document-package-table-row__answer-box">
          <CheckboxGroup {...typeToProps(itemType, questionParameters)} value={contentObject.acceptedAnswers} preview />
          <Input preview label="Other Answer" value={contentObject.otherAnswer} />
        </div>
      ),
      [itemTypes.PERCENTAGEFIELD]: <div className="ickyc-document-package-table-row__answer-box">{answerText}</div>,
      [itemTypes.DISCRETECHOICE]: (
        <div className="ickyc-document-package-table-row__answer-box">
          <RadioGroup {...typeToProps(itemType, questionParameters)} value={contentObject.acceptedAnswer} preview />
          <Input preview label="Other Answer:" value={contentObject.otherAnswer} />
        </div>
      ),
    };

    return typeToComponent[itemType];
  }, [questionParametersObject, contentObject, itemType]);

  return (
    <>
      <CollapsibleTableRow
        className={classes}
        row={
          <>
            <td className={setSufix('__index')}>
              <span>{index + 1}</span>
            </td>
            <td className={setSufix('__type')}>
              <span>{<CustomQuestionIcon />}</span>
            </td>
            <td className={setSufix('__name')}>
              <span>{description}</span>
            </td>
            <td className={setSufix('__submission')}>
              <Tooltip
                content={answerText}
                trigger={<span className={setSufix('__submission__answer')}>{answerText}</span>}
              />
            </td>
            <td className={setSufix('__remove')}>
              <span>
                <DeleteButton onClick={openConfirmationMOdal} />
              </span>
            </td>
          </>
        }
      >
        {answerComponent}
      </CollapsibleTableRow>
      {isConfirmationModalOpen && (
        <FormModal
          hideModal={closeConfirmationModal}
          title="Remove Package Item"
          className="ickyc-confirmation-modal"
          onSubmit={onPackageItemDelete}
        >
          <span className="ickyc-confirmation-message">Are you sure you want to delete this item ?</span>
        </FormModal>
      )}
    </>
  );
};
export default CustomQuestionTableRow;
