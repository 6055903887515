/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import Info from '../../../../../../../../../components/Info';
import Tag from '../../../../../../../../../components/Tag';
import ProtectedComponent from '../../../../../../../../../router/components/ProtectedComponent';
import authEnums from '../../../../../../../../../utilities/enums/authEnums';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../../../utilities/services/IconManager';
import { CaseContext } from '../../../../../../../../../utilities/services/contexts';
import AssignToUserDropdown from '../../../../../../../components/AssignToUserDropdown';
import CollapsibleTableRow from '../../../../../../../components/CollapsibleTableRow';
import HitStatusRowControls from '../../../../../HitStatusRowControls';

const AdverseMediaTableRow = props => {
  const {
    rowInfo: { checked, id, date, snippet, status, title, url, assignedUserId },
    onCheckboxClick,
    onControlsClick,
    className,
  } = props;
  const { complianceLogId, hitId } = useParams();
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const handleChange = useCallback(
    value => {
      onControlsClick(id, value);
    },
    [id, onControlsClick],
  );
  const { isCaseClosed } = useContext(CaseContext);

  const handleCheckboxChange = useCallback(
    event => {
      if (event.type !== 'change') {
        event.stopPropagation();
      }
      onCheckboxClick(id);
    },
    [id, onCheckboxClick],
  );

  const showDate = useMemo(() => {
    const d = DateManager.monDayYearLocal(date);
    return d !== 'Invalid Date' ? d : '';
  }, [date]);

  const preventBubbling = event => {
    event.stopPropagation();
  };

  return (
    <CollapsibleTableRow
      className={className}
      row={
        <>
          <td className={setColumnSuffix('__checkbox')}>
            <input
              data-lpignore="true"
              type="checkbox"
              disabled={isCaseClosed}
              checked={checked}
              readOnly
              onClick={handleCheckboxChange}
            />
          </td>
          <td className={setColumnSuffix('__status')}>
            <Tag name={status} outline red />
          </td>
          <td className={setColumnSuffix('__date')}>
            <span>{showDate}</span>
          </td>
          <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
            <td className={setColumnSuffix('__assignedTo')} onClick={preventBubbling}>
              <AssignToUserDropdown
                logId={complianceLogId}
                hitId={hitId}
                disabled={isCaseClosed}
                assignedUserId={assignedUserId}
                listingId={id}
                assignlisting
                renderAsPortal
              />
            </td>
          </ProtectedComponent>
          <td className={setColumnSuffix('__source')}>
            {url && (
              <a href={url} target="_blank">
                <span>{url}</span>
                {IconManager.get(IconManager.names.LINK)}
              </a>
            )}
          </td>
          <td className={setColumnSuffix('__controls')}>
            <HitStatusRowControls onClick={handleChange} isControlActive={!isCaseClosed} />
          </td>
        </>
      }
    >
      <div className="ickyc-listing-additinal-info">
        <Info label="title">{title}</Info>
        {snippet && <Info label="snippet">{snippet}</Info>}
      </div>
    </CollapsibleTableRow>
  );
};

AdverseMediaTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    checked: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    date: PropTypes.string,
    snippet: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        item1: PropTypes.string,
        item2: PropTypes.string,
      }),
    ),
    riskType: PropTypes.string,
    updated: PropTypes.string,
    url: PropTypes.string,
    assignedUserId: PropTypes.string,
  }),
  onCheckboxClick: PropTypes.func,
  onControlsClick: PropTypes.func,
  className: PropTypes.string,
};

AdverseMediaTableRow.defaultProps = {
  rowInfo: {},
  onCheckboxClick: () => {},
  onControlsClick: () => {},
  className: undefined,
};
export default AdverseMediaTableRow;
