const administration = {
  RESTRICTIONS: '/administration/compliance-settings/quick-setup-subrouter/restrictions',
  IDENTITY_VERIFICATION: '/administration/compliance-settings/quick-setup-subrouter/identity-verification',
  DOCUMENT_VERIFICATION: '/administration/compliance-settings/quick-setup-subrouter/document-verification',
  BIOMETRIC_AUTHENTICATION: '/administration/compliance-settings/quick-setup-subrouter/biometric-authentication',
  SUPPORTING_DOCUMENTS: '/administration/compliance-settings/supporting-documents',
  SUPPORTING_PACKAGE_DETAILS: '/administration/compliance-settings/supporting-documents/:id',
  RISK_SCREENING: '/administration/compliance-settings/risk-screening',
  RISK_SCORING: '/administration/compliance-settings/risk-scoring',
  RISK_LEVELS: '/administration/compliance-settings/risk-levels',
  USER_MANAGEMENT: '/administration/user-management',
  DATA_MANAGEMENT: '/administration/files-management/csv',
  REPORTS: '/administration/files-management/reports',
  WEB_HOOKS_MANAGMENT: '/administration/web-hooks',
  AUTOMATION: '/administration/automation',
  PORTAL_MANAGMENT: '/administration/portal-management',
  PORTAL_DETAILS_NATURAL: '/administration/portal-management/natural-person-portal/:id',
  PORTAL_DETAILS_LEGAL: '/administration/portal-management/legal-entity-portal/:id',
  API_KEYS_MANAGMENT: '/administration/api-keys',
  COMPLIANCE_SETTINGS: '/administration/compliance-settings',
  QUICK_SETTINGS_SUBROUTER: '/administration/compliance-settings/quick-setup-subrouter',
  EMAILS: '/administration/settings/emails',
  ACCOUNT: '/administration/account',
  DASHBOARD: '/administration/dashboard',
};
const kyc = {
  CASE_MANAGEMENT: '/kyc/case-management',
  MY_WORK: '/kyc/my-work',
  CASE_DETAIL: '/kyc/case-management/:id',
  ENTITY_MANAGEMENT: '/kyc/entities',
  LEGAL_ENTITY: '/kyc/entities/legal-entity/:id',
  NATURAL_PERSON: '/kyc/entities/natural-person/:id',
  CASE_MATCH_DETAILS: '/kyc/case-management/:complianceLogId/matches/:hitId',
  HOME_SCREEN: '/kyc/home-screen',
};
const accountSettings = {
  PERSONAL_SETTINGS: '/account-settings/personal-settings',
};

const internal = {
  BUSINESS_MANAGER: '/internal/business-manager',
  BUSINESS_PROFILE: '/internal/business-manager/:id',
  ICOMPLY_TEAM: '/internal/icomply-team',
  LICENCE_MANAGMENT: '/internal/license-management',
  LEGAL_ENTITY_PORTAL_PROFILE: '/internal/business-manager/:clientId/legal-entity-portal/:id',
  NATURAL_PERSON_PORTAL_PROFILE: '/internal/business-manager/:clientId/natural-person-portal/:id',
  EMAIL_TEMPLATES: '/internal/email-templates',
  EMAIL_TEMPLATE_EDITOR: '/internal/email-templates/:id',
  WEB_PRICING: '/internal/web-pricing',
};

const pages = {
  PREFFACTO: 'prefacto',
  KYC: 'kyc',
  ADMINISTRATION: 'administration',
  ACCOUNT_SETTINGS: 'account-settings',
  COMPLIANCE_SETTINGS: 'compliance-settings',
  FILES_MANAGEMENT: 'files-management',
  INTERNAL: 'internal',
  ACCOUNT: 'account',
};
export default {
  pages,
  administration,
  kyc,
  accountSettings,
  internal,
};
