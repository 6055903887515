import api from 'api';
import IComplyForm from 'components/Form/IComplyForm';
import ModalTabHeader from 'components/Headers/ModalTabHeader';
import InputField from 'components/InputField';
import Loader from 'components/Loader';
import Section from 'components/Section';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import ActiveProductsTab from './components/ActiveProductsTab';
import './styles.scss';
import utils from './utils';

const WebProductsSection = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [oneTimeSetupFee, setOneTimeSetupFee] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getOneTimeSetupFee = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.internal.webProducts.getOneTimeSetupFee();
        setOneTimeSetupFee({ oneTimeSetupFee: data });
      } catch (err) {
        toastr.error('Error occured while getting One-Time Setup Fee');
      } finally {
        setIsLoading(false);
      }
    };

    getOneTimeSetupFee();
  }, []);

  const handleSetupFeeSubmit = async value => {
    try {
      const normalizedValue = { oneTimeSetupFee: Number(value?.oneTimeSetupFee) || 0 };

      await api.internal.webProducts.setOneTimeSetupFee(normalizedValue);

      toastr.success('Succesfully changed One-Time Setup Fee');
    } catch (err) {
      toastr.error('Error occured while setting new One-Time Setup Fee');
    }
  };

  return (
    <>
      <IComplyForm
        className="setup-fee-form"
        onSubmit={handleSetupFeeSubmit}
        initialValues={oneTimeSetupFee}
        showControls
      >
        <Section title="Web Products">
          {isLoading && <Loader />}
          <div className="setup-fee-form__setup-fee">
            <span>One-Time Setup Fee</span>
            <Field component={InputField} name="oneTimeSetupFee" type="number" />
          </div>
        </Section>
      </IComplyForm>
      <IComplyForm className="web-products-form" onSubmit={() => {}}>
        <ModalTabHeader onClick={value => setActiveTab(value)} options={utils.productStatusTabs} />
        <ActiveProductsTab isActive={activeTab} />
      </IComplyForm>
    </>
  );
};

export default WebProductsSection;
