import classNames from 'classnames';
import IconButton from 'components/Buttons/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import IconManager from 'utilities/services/IconManager';

import './styles.scss';

const LicenceItem = props => {
  const { className, text, children, noButtons, handleChange, fieldName, disabled } = props;

  const [isChecked, setIsChecked] = useState();

  const {
    input: { value: fieldValue },
  } = useField(fieldName);

  const classes = classNames(className, 'licence-item', {
    'licence-item--disabled': disabled,
  });

  const handleClick = useCallback(() => {
    setIsChecked(prev => !prev);
    if (handleChange) {
      handleChange(fieldName, !isChecked);
    }
  }, [handleChange, fieldName, isChecked]);

  useEffect(() => {
    if (fieldName && fieldValue !== '') {
      setIsChecked(fieldValue);
    }
  }, [fieldValue, fieldName]);

  const textComponent = useMemo(() => {
    if (!text) return null;
    if (typeof text === 'string') return <p>{text}</p>;
    return text;
  }, [text]);

  return (
    <div className={classes}>
      {!noButtons && (
        <IconButton onClick={handleClick} transparent>
          {IconManager.get(isChecked ? IconManager.names.CHECK_CIRCULAR : IconManager.names.PLUS_CIRCULAR)}
        </IconButton>
      )}
      <div className="licence-item__text">{textComponent}</div>
      {(isChecked || noButtons) && children && (
        <>
          {!noButtons && (
            <IconButton onClick={handleClick} transparent>
              {IconManager.get(IconManager.names.MINUS_CIRCULAR)}
            </IconButton>
          )}
          <div className="licence-item__content">{children}</div>
        </>
      )}
    </div>
  );
};

LicenceItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  noButtons: PropTypes.bool,
  handleChange: PropTypes.func,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default LicenceItem;
