import { CircularProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import api from '../../../api';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import InputField from '../../../components/InputField';
import validators from '../../../utilities/services/validators';

const ForgotPassowrdFrom = ({ onCancel }) => {
  const handleForgotPasswordSubmit = async credentials => {
    return api.auth
      .forgotPassword({ email: credentials.toEmail })
      .then(() => {
        return true;
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };
  const baseClass = 'ickyc-form';

  return (
    <FinalForm onSubmit={handleForgotPasswordSubmit}>
      {({ handleSubmit, hasSubmitErrors, submitSucceeded, submitting, dirtySinceLastSubmit, submitError }) => {
        return (
          <form onSubmit={handleSubmit} className={baseClass}>
            {submitSucceeded && !hasSubmitErrors ? (
              <div className={`${baseClass}__message`}>
                <h2>No problem.</h2>
                <p>
                  If the address you have entered is associated with an account, we've sent and email that will help you
                  reset the password.
                </p>
              </div>
            ) : (
              <>
                <div className={`${baseClass}__fields`}>
                  <h4>Forgot Password?</h4>
                  <p>Enter your email to send a recovery link.</p>
                  <Field
                    component={InputField}
                    name="toEmail"
                    label="Email"
                    type="text"
                    data-lpignore="false"
                    validate={validators.composeValidators(
                      validators.required(),
                      validators.email('Invalid email address'),
                    )}
                  />
                  {!dirtySinceLastSubmit && submitError && (
                    <div className={`${baseClass}__fields__error`}>{submitError}</div>
                  )}
                </div>

                <div className={`${baseClass}__actions ${baseClass}__actions--double`}>
                  <PrimaryButton onClick={onCancel}>Cancel</PrimaryButton>
                  <PrimaryButton type="submit" disabled={submitting}>
                    Reset Password
                    {submitting && <CircularProgress />}
                  </PrimaryButton>
                </div>
              </>
            )}
          </form>
        );
      }}
    </FinalForm>
  );
};
ForgotPassowrdFrom.propTypes = {
  onCancel: PropTypes.func,
};
ForgotPassowrdFrom.defaultProps = {
  onCancel: () => {},
};

export default ForgotPassowrdFrom;
