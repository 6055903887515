import { Box } from '@material-ui/core';
import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Loader from 'components/Loader';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import setClassSuffix from 'utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from 'utilities/services/contexts';
import errorMessage from '../ErrorMessage';
import ThirdPartyHeader from '../ThirdPartyHeader';
import './styles.scss';

const ThirdPartyDeleteDocument = ({ data, onDataFetch, onRemove }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [issuesCount, setIssuesCount] = useState(1);
  const { entityId } = useContext(EntityContext);
  const { issues } = useContext(HistoryRecordContext);

  const { thirdPartySuggestion, eventHistoryId } = data;
  const { thirdPartyInfo } = thirdPartySuggestion;

  const classBase = 'ickyc-third-party-delete-document';
  const setSuffix = setClassSuffix(classBase);
  const classes = classNames(classBase, {
    [setSuffix('__issues')]: issuesCount,
  });

  useEffect(() => {
    onDataFetch({ ...data, thirdPartySuggestion: { ...thirdPartySuggestion, isDelete: true } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccept = async () => {
    setIsLoading(true);
    return api.kyc.entityManagement
      .deleteDocumentHistoryRecordsSoft(entityId, eventHistoryId)
      .then(() => {
        onRemove(eventHistoryId);
        toastr.success(`History Record has been removed`);
        setError();
      })
      .catch(err => {
        return setError(errorMessage(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReject = async () => {
    setIsLoading(true);
    const dataToSubmit = { status: 'ignored', operation: 'edit' };

    return api.kyc.entityManagement.legalEntity
      .updateSupportingDocument(entityId, eventHistoryId, dataToSubmit)
      .then(response => {
        onDataFetch({
          ...data,
          issues: response?.data?.issues,
          thirdPartySuggestion: {
            ...thirdPartySuggestion,
            suggestionStatus: 'ignored',
            isDelete: false,
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
          eventHistoryId,
          data: {
            issues: issues - 1,
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.DOCUMENTS,
          issuesChange: -1,
        });
        toastr.success(`Suggestion has been rejected.`);
        setIssuesCount(0);
        setError();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return setError(errorData.error || `${errorData?.message?.slice(0, 80)}...`);
          }
          if (status === 500) {
            return setError('Internal Server Error, Try Again Later');
          }
        } else return setError('Error occured');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes}>
      <ThirdPartyHeader className={classBase} info={thirdPartyInfo} issuesCount={issuesCount} actionType="Suggested" />
      <div className={setSuffix('__info')}>
        <span>{thirdPartyInfo?.role} has suggested deleting this supporting document.</span>
      </div>
      {error && <Box className={setSuffix('__submissionError')}>{error}</Box>}
      {issuesCount ? (
        <div className={setSuffix('__buttons')}>
          {isLoading && <Loader />}
          <PrimaryButton variant="red" disabled={isLoading} onClick={() => handleReject()}>
            Ignore Suggestion
          </PrimaryButton>
          <PrimaryButton type="submit" disabled={isLoading} onClick={() => handleAccept()}>
            Delete Document
          </PrimaryButton>
        </div>
      ) : (
        <div className={setSuffix('__status-message')}>
          <span className={setSuffix('__status-message--ignored')}>Delete rejected</span>
        </div>
      )}
    </div>
  );
};

ThirdPartyDeleteDocument.propTypes = {
  data: PropTypes.object,
  onDataFetch: PropTypes.func,
  onRemove: PropTypes.func,
};
ThirdPartyDeleteDocument.defaultProps = {
  data: {},
  onDataFetch: () => {},
  onRemove: () => {},
};

export default ThirdPartyDeleteDocument;
