import api from 'api';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import InputField from 'components/InputField';
import AdvancedWorkflowModule from 'components/LicenceModules/AdvancedWorkflowModule';
import CoreModule from 'components/LicenceModules/CoreModule';
import DueDiligenceModule from 'components/LicenceModules/DueDiligenceModule';
import OnboardingModule from 'components/LicenceModules/OnboardingModule';
import TechnicalModule from 'components/LicenceModules/TechnicalModule';
import Select from 'components/Select';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, useField } from 'react-final-form';
import LicenceItem from 'scenes/Internal/LicenceManager/componenets/AddLicenceModal/components/LicenceItem';
import toastr from 'toastr';
import utils from '../../utils';

import './styles.scss';

const IDDProduct = ({ className, namePreffix, isActive, disabled, noStatusSelect, noCoreModule }) => {
  const [status, setStatus] = useState(isActive ? 'Active' : 'Archived');

  const classes = classNames(className, 'idd-product', {
    'idd-product--disabled': disabled,
  });

  const productPreffix = namePreffix ? `${namePreffix}.iddWebProduct` : 'iddWebProduct';

  const {
    input: { value: feePerPortal },
  } = useField(`${productPreffix}.recurringMaintenanceFeeAnnually`);

  const {
    input: {
      value: { npPortalsLimit, lePortalsLimit },
    },
  } = useField(`${productPreffix}.onboarding`);

  const recurringMaintenanceTotal = feePerPortal * (npPortalsLimit + lePortalsLimit);

  const handleOnChange = async value => {
    setStatus(value);

    if (value === 'Archived') {
      try {
        await api.internal.webProducts.archiveWebProduct(namePreffix);
        toastr.success('Succesfully archived product');
      } catch (err) {
        toastr.error('Error occured while changing product status');
      }
    }
  };

  return (
    <Accordion
      className={classes}
      title={
        <div className="idd-product__title">
          <span>Identity Due Diligence</span>

          {!noStatusSelect && (
            <div onClick={e => e.stopPropagation()}>
              <Select
                Trigger={<SelectTrigger />}
                Content={<SelectContent />}
                onChange={handleOnChange}
                options={utils.STATUS_OPTIONS}
                value={status}
                disabled={status === 'Archived'}
                autoClose
              />
            </div>
          )}
        </div>
      }
      accordionOpen
    >
      <LicenceItem text="SaaS Fee" className="idd-product__setup-fee" noButtons disabled>
        <Field
          disabled
          topLabeled
          component={InputField}
          name={`${namePreffix}.saasFeeMonthly`}
          type="number"
          label="Monthly"
          suffix="$"
        />
        <Field
          disabled
          topLabeled
          component={InputField}
          name={`${namePreffix}.saasFeeAnnually`}
          type="number"
          label="Annually"
          suffix="$"
        />
      </LicenceItem>

      <LicenceItem text="Recurring Maintenance Fee" className="idd-product__setup-fee" noButtons disabled>
        <Field
          disabled
          topLabeled
          component={InputField}
          name={`${productPreffix}.recurringMaintenanceFeeAnnually`}
          type="number"
          label="Annually"
          suffix="$"
        />
        <span className="idd-product__setup-fee__total">{`/per portal (total: \$${recurringMaintenanceTotal})`}</span>
      </LicenceItem>

      {!noCoreModule && <CoreModule namePreffix={productPreffix} disabled={disabled} />}
      <DueDiligenceModule namePreffix={productPreffix} disabled={disabled} />
      <OnboardingModule namePreffix={productPreffix} disabled={disabled} />
      <AdvancedWorkflowModule namePreffix={productPreffix} disabled={disabled} />
      <TechnicalModule namePreffix={productPreffix} disabled={disabled} />
    </Accordion>
  );
};

IDDProduct.propTypes = {
  classNames: PropTypes.string,
  namePreffix: PropTypes.string,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  noStatusSelect: PropTypes.bool,
  noCoreModule: PropTypes.bool,
};

IDDProduct.defaultProps = {
  classNames: '',
  namePreffix: '',
  isActive: true,
  disabled: false,
  noStatusSelect: false,
  noCoreModule: false,
};

export default IDDProduct;
