import classNames from 'classnames';
import Accordion from 'components/Accordion';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThirdPartyConfirmation from 'scenes/Kyc/EntityManagement/LegalEntityProfile/components/ThirdPartyConfirmation';
import IssueList from 'scenes/Kyc/EntityManagement/components/IssueList';
import { DocumentDataContext } from 'utilities/services/contexts';
import DocumentsPackageTable from '../DocumentsPackageTable';
import AdditionalItemModal from './components/AdditionalItemModal';
import './styles.scss';

function DocumentPackage() {
  const { documentRecord, setDocumentRecord, onRemove } = useContext(DocumentDataContext);

  const {
    close: closeAdditionalItemModal,
    isOpen: isOpenAdditionalItemModal,
    open: openAdditionalItemModal,
  } = useModalHandler();

  const classes = classNames({
    'ickyc-document-package': true,
    'ickyc-document-package--preview': true,
  });
  return (
    <Accordion title="Document Package" accordionOpen className={classes} onClick={e => e.stopPropagation()}>
      <IssueList list={documentRecord?.issues} />

      <DocumentsPackageTable items={documentRecord?.items} />

      <PrimaryButton onClick={openAdditionalItemModal}>+ Add Additional Item</PrimaryButton>

      {documentRecord?.thirdPartySuggestion && (
        <ThirdPartyConfirmation
          data={documentRecord}
          onDataFetch={setDocumentRecord}
          onRemove={onRemove}
          isSupportingDocument
        />
      )}

      {isOpenAdditionalItemModal && (
        <AdditionalItemModal
          packageId={documentRecord?.supportingDocumentPackageId}
          existingItems={documentRecord?.items}
          hideModal={closeAdditionalItemModal}
          onClick={e => e.stopPropagation()}
        />
      )}
    </Accordion>
  );
}
DocumentPackage.propTypes = {
  documentPackage: PropTypes.shape({}),
};
DocumentPackage.defaultProps = {
  documentPackage: {},
};

export default DocumentPackage;
