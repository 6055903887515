import classNames from 'classnames';
import InputField from 'components/InputField';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import licenceEnums from 'utilities/enums/licenceEnums';
import validators from 'utilities/services/validators';
import LicenceItem from '../LicenceItem';
import utils from './utils';

import './styles.scss';

const { defaultAddonsValues, initialAddonsList } = utils;

const LicenceAddons = ({ namePreffix, divider }) => {
  const [addonsList, setAddonsList] = useState(initialAddonsList);
  const { change } = useForm();

  const {
    input: {
      value: { addOns, setupAndMaintenance },
    },
  } = useField(namePreffix);

  const fieldPreffix = namePreffix ? `${namePreffix}.addOns` : 'addOns';

  const classes = classNames('addons-section', {
    'addons-section--with-divider': divider,
  });

  const handleChange = useCallback(
    (fieldName, value) => {
      const addonName = fieldName?.split('addOns.')[1]?.split('.')[0];

      setAddonsList(prevAddonsList => ({
        ...prevAddonsList,
        [addonName]: {
          ...prevAddonsList[addonName],
          isOpen: !prevAddonsList[addonName]?.isOpen,
        },
      }));

      if (!value) {
        const disabledAddonName = fieldName?.split('.enabled')[0];
        change(disabledAddonName, defaultAddonsValues[addonName]);
      } else {
        change(fieldName, value);
      }

      if (!setupAndMaintenance?.addonsFeeEnabled) {
        change(`${namePreffix}.setupAndMaintenance.addonsFeeEnabled`, true);
      }
    },
    [change, namePreffix, setupAndMaintenance?.addonsFeeEnabled],
  );

  useEffect(() => {
    if (!setupAndMaintenance?.addonsFeeEnabled) {
      setAddonsList(initialAddonsList);

      change(`${namePreffix}.addOns`, defaultAddonsValues);
    }
  }, [change, namePreffix, setupAndMaintenance?.addonsFeeEnabled]);

  useEffect(() => {
    if (!addOns || !setupAndMaintenance?.addonsFeeEnabled) return;

    let addonsSum = Object.values(addOns).reduce(
      (acc, addon) => acc + Number(addon?.fee) * (addon?.period === '1' ? 1 : 12),
      0,
    );

    if (setupAndMaintenance?.saasFeePeriod === '0') {
      addonsSum /= 12;
    }

    change(`${namePreffix}.setupAndMaintenance.addonsFee`, addonsSum.toFixed(2));
  }, [change, namePreffix, setupAndMaintenance?.addonsFeeEnabled, setupAndMaintenance?.saasFeePeriod, addOns]);

  useEffect(() => {
    if (addOns) {
      setAddonsList(prevAddonsList =>
        Object.keys(prevAddonsList).reduce((acc, addonName) => {
          const enabled = !!addOns[addonName]?.enabled;
          return {
            ...acc,
            [addonName]: {
              ...prevAddonsList[addonName],
              isOpen: enabled,
            },
          };
        }, {}),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={classes}>
      <div className="addons-section__headser">
        <h3>Add-Ons</h3>
      </div>

      <div className="addons-section__content">
        {Object.values(addonsList)
          .map(({ text, fieldName, placeholder, isOpen }) => (
            <LicenceItem
              key={fieldName}
              text={text}
              handleChange={handleChange}
              fieldName={`${fieldPreffix}.${fieldName}.enabled`}
              isOpen={isOpen}
            >
              {placeholder && (
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.${fieldName}.limit`}
                  type="number"
                  placeholder={placeholder}
                  validate={validators.requiredNumber}
                />
              )}
              <Field
                component={InputField}
                name={`${fieldPreffix}.${fieldName}.fee`}
                type="number"
                preffix="$"
                placeholder=". . ."
                validate={validators.requiredNumber}
              />
              <Field
                component={SelectField}
                name={`${fieldPreffix}.${fieldName}.period`}
                Content={<SelectContent />}
                options={licenceEnums.BILLING_CYCLE_REDUCED_OPTIONS}
                Trigger={<SelectTrigger placeholder="Billing Cycle" />}
                validate={validators.required(' ')}
              />
            </LicenceItem>
          ))
          .sort((licenceA, licenceB) => {
            if (licenceA.props.isOpen && !licenceB.props.isOpen) {
              return -1;
            }
            if (!licenceA.props.isOpen && licenceB.props.isOpen) {
              return 1;
            }
            return licenceA.props.index - licenceB.props.index;
          })}
      </div>
    </section>
  );
};

LicenceAddons.propTypes = {
  namePreffix: PropTypes.string,
  divider: PropTypes.bool,
};

LicenceAddons.defaultProps = {
  namePreffix: '',
  divider: false,
};

export default LicenceAddons;
