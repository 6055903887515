import classNames from 'classnames';
import AMLModule from 'components/LicenceModules/AMLModule';
import AdvancedWorkflowModule from 'components/LicenceModules/AdvancedWorkflowModule';
import CoreModule from 'components/LicenceModules/CoreModule';
import DueDiligenceModule from 'components/LicenceModules/DueDiligenceModule';
import OnboardingModule from 'components/LicenceModules/OnboardingModule';
import TechnicalModule from 'components/LicenceModules/TechnicalModule';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import PlusComponent from '../PlusComponent';

import './styles.scss';

const modules = [
  { name: 'Core', valueName: 'core', toggled: true },
  { name: 'Onboarding', valueName: 'onboarding', toggled: false },
  { name: 'Due Diligence', valueName: 'dueDiligence', toggled: false },
  { name: 'Advanced Workflow', valueName: 'advancedWorkflow', toggled: false },
  { name: 'AML', valueName: 'aml', toggled: false },
  { name: 'Technical', valueName: 'technical', toggled: false },
];

const defaultModules = {
  dueDiligence: {
    supportingDocumentPackagesEnabled: false,
    riskLevelsEnabled: false,
  },
  aml: {
    ongoingMonitoringEnabled: false,
  },
  onboarding: {
    npCustomUrlsEnabled: false,
    leCustomUrlsEnabled: false,
    portalManagementEnabled: false,
    emailSettingsEnabled: false,
  },
  advancedWorkflow: {
    automationsEnabled: false,
    riskScoringEnabled: false,
    quickSetupSettingsEnabled: false,
  },
  technical: {
    webhooksEnabled: false,
    apiEnabled: false,
  },
};

const ModulesSection = ({ initialModules, namePreffix, divider }) => {
  const [showModules, setShowModules] = useState(modules);

  const { change } = useForm();

  const fieldPreffix = namePreffix ? `${namePreffix}.modules` : 'modules';

  const classes = classNames('module-section', {
    'module-section--with-divider': divider,
  });

  const handleShowModule = useCallback(
    name => {
      const module = showModules.find(module => module.name === name);

      change(`${fieldPreffix}.${module.valueName}`, module.toggled ? undefined : defaultModules[module.valueName]);

      setShowModules(prevModules => {
        return prevModules.map(module => {
          if (module.name === name) {
            return { ...module, toggled: !module.toggled };
          } else {
            return module;
          }
        });
      });
    },
    [change, showModules, fieldPreffix],
  );

  useEffect(() => {
    setShowModules(prevModules => {
      return prevModules.map(module => {
        const initialModule = initialModules?.[module.valueName];
        if (initialModule) {
          return {
            ...module,
            toggled: true,
          };
        }
        return module;
      });
    });
  }, [initialModules]);

  return (
    <section className={classes}>
      <div className="module-section__header">
        <h3>Modules</h3>
      </div>

      <div className="module-section__content">
        {showModules.map(({ name, toggled }) => (
          <PlusComponent key={name} isChecked={toggled} text={name} handleShow={handleShowModule} />
        ))}
      </div>

      {showModules.find(module => module.name === 'Core').toggled && (
        <CoreModule namePreffix={fieldPreffix} key="core-module" />
      )}

      {showModules.find(module => module.name === 'Due Diligence').toggled && (
        <DueDiligenceModule namePreffix={fieldPreffix} handleHide={() => handleShowModule('Due Diligence')} />
      )}

      {showModules.find(module => module.name === 'AML').toggled && (
        <AMLModule namePreffix={fieldPreffix} handleHide={() => handleShowModule('AML')} />
      )}

      {showModules.find(module => module.name === 'Onboarding').toggled && (
        <OnboardingModule namePreffix={fieldPreffix} handleHide={() => handleShowModule('Onboarding')} />
      )}

      {showModules.find(module => module.name === 'Advanced Workflow').toggled && (
        <AdvancedWorkflowModule namePreffix={fieldPreffix} handleHide={() => handleShowModule('Advanced Workflow')} />
      )}

      {showModules.find(module => module.name === 'Technical').toggled && (
        <TechnicalModule namePreffix={fieldPreffix} handleHide={() => handleShowModule('Technical')} />
      )}
    </section>
  );
};

ModulesSection.propTypes = {
  namePreffix: PropTypes.string,
  divider: PropTypes.bool,
};

ModulesSection.defaultProps = {
  namePreffix: '',
  divider: false,
};

export default ModulesSection;
