/**
 *  Wrapping content component
 * @param {*} children - nested elements
 * @param {string} title
 * @param {function} text - returns JSX, it contains text
 */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../Loader';
import './styles.scss';

const Section = ({ children, title, text, divider, className, isUpdating, headerAdditionalContent }) => {
  const classes = classNames({
    'ickyc-section': true,
    'ickyc-section--with-divider': divider,
    [className]: className,
  });
  return (
    <section className={classes}>
      {title && (
        <div className="ickyc-section__header">
          <h3>{title} </h3>
          {headerAdditionalContent}
        </div>
      )}
      {text && <div className="ickyc-section__subheader">{text}</div>}
      <div className="ickyc-section__content">
        {isUpdating && <Loader />}
        {children}
      </div>
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  text: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  headerAdditionalContent: PropTypes.object,

  divider: PropTypes.bool,
  isUpdating: PropTypes.bool,
  className: PropTypes.string,
};

Section.defaultProps = {
  title: '',
  text: null,
  divider: false,
  className: undefined,
  isUpdating: false,
  headerAdditionalContent: undefined,
};
export default Section;
