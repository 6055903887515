import IconManager from './services/IconManager';
import validators from './services/validators';

const routeParams = (route, params) => {
  return route.replace(
    RegExp(
      Object.keys(params)
        .map(key => `:${key}`)
        .join('|'),
      'g',
    ),
    replace => {
      const [, ...rest] = replace;
      return params[rest.join('')];
    },
  );
};
const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const transformListings = (listings = []) => {
  return listings.map(listing => {
    const handler = (acc, { item1, item2 }) => {
      return { ...acc, [item1]: item2 };
    };
    const initialValue = {};
    const additionaListingItems = listing?.listItems.reduce(handler, initialValue);
    return {
      source: listing.url,
      listName: listing.listName,
      startDate: listing.startDate,
      endDate: listing.endDate,
      ...additionaListingItems,
    };
  });
};
const createObject = (...pathes) => {
  return pathes.reduce(function(obj, path) {
    // eslint-disable-next-line no-shadow
    // eslint-disable-next-line no-return-assign
    path.split('.').reduce((o, key) => (o[key] = obj[key] || {}), obj);
    return obj;
  }, {});
};
function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const findArrayValueChanges = (startArray, endArray) =>
  endArray
    .filter(end => {
      const start = startArray.find(sa => sa.id === end.id);
      return !start || JSON.stringify(end.value) !== JSON.stringify(start.value);
    })
    .map(({ id, value }) => ({ id, value }));

const loadScript = (scriptId, scriptLink) =>
  new Promise((resolve, reject) => {
    const existingscript = document.getElementById(scriptId);
    if (!existingscript) {
      const script = document.createElement('script');
      script.setAttribute('id', scriptId);
      script.setAttribute('type', 'text/javascript');
      script.addEventListener('load', () => {
        if (resolve) {
          resolve();
        }
      });
      script.addEventListener('error', e => {
        if (reject) {
          reject(e);
        }
      });
      script.src = scriptLink;
      const node = document.getElementsByTagName('script')[0];
      node.parentNode.insertBefore(script, node);
    } else if (resolve) {
      resolve();
    }
  });
/**
 *
 * @param {Object} quadruple
 */
function transformCoords(quadruple) {
  if (!quadruple) return null;
  return Object.keys(quadruple).reduce((acc, key) => {
    if (quadruple[key].x) {
      acc[`${key}X`] = quadruple[key].x;
      acc[`${key}Y`] = quadruple[key].y;
    } else {
      acc[key] = quadruple[key];
    }
    return acc;
  }, {});
}
const insertSpaceBeforeCapitalLetter = s => s.replace(/([A-Z])/g, ' $1').trim();

const downloadFile = (data, type, name) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', name);
  document.body.appendChild(link); // Required for FF
  link.click();
};

const preventEventBubling = e => {
  e.stopPropagation();
  e.preventDefault();
};

const copyToClipboard = apiKey => {
  const el = document.createElement('textarea');
  el.value = apiKey;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const normalizeUrlName = s => {
  if (typeof s !== 'string') return '';
  return (s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)).replace(' ', '').replace('url', ' URL');
};
const convertPermissionsToObject = listOfPermissions => {
  const permissions = {
    none: true,
    view: false,
    edit: false,
    create: false,
    delete: false,
    requestClientUpdate: false,
  };
  const pom = listOfPermissions.toString();
  if (pom.includes('None')) permissions.none = true;
  if (pom.includes('View')) permissions.view = true;
  if (pom.includes('Edit')) permissions.edit = true;
  if (pom.includes('Create')) permissions.create = true;
  if (pom.includes('Delete')) permissions.delete = true;
  if (pom.includes('Request Client Update')) permissions.requestClientUpdate = true;
  return permissions;
};

const transformFromArrayOfObjectsToArrayOfStrings = arrayOfObjects => {
  return arrayOfObjects.map(el => el.label);
};

const getIconBasedOnType = entityType => {
  if (entityType === 0) return IconManager.get(IconManager.names.LEGAL_ENTITY);
  if (entityType === 1) return IconManager.get(IconManager.names.NATURAL_PERSON);
  return IconManager.get(IconManager.names.CASE);
};
const formatDecimalByIndianLocalization = num => {
  return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
};
const formatDecimalByAmericanLocalization = num => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(num);
};

const handleOnClickEvent = e => {
  e.stopPropagation();
};

const removeNullProperties = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null) acc[key] = obj[key];
    if (typeof acc[key] === 'object') {
      acc[key] = removeNullProperties(acc[key]);
    }
    return acc;
  }, {});
};

const errorHandler = err => {
  const { status } = err;

  console.error(err);

  if (status && (status === 405 || status >= 500)) {
    throw new Error(err);
  }
};
const accessObjectNestedValuesByStringPath = (obj, path) =>
  path
    // eslint-disable-next-line no-useless-escape
    .replace(/\[([^\[\]]*)\]/g, '.$1.')
    .split('.')
    .filter(t => t !== '')
    .reduce((prev, cur) => prev && prev[cur], obj);

const parseLetters = value => value.replace(validators.LETTERS_REGEX, '');

const parseName = value => {
  const indexOfFirstLetter = value.search(/\p{Letter}/u);

  if (indexOfFirstLetter !== -1) return value.slice(indexOfFirstLetter).replace(validators.PERSONAL_NAME_REGEX, '');
  return '';
};

const extractTextAndNumberWithinBrackets = value => {
  const regex = /(\w+)\[(\d+)]/;
  const match = regex.exec(value);

  return match?.length > 2 ? [match[1], parseInt(match[2])] : null;
};

const getObjectValue = (obj, path) => {
  const pathArray = path.split('.');
  const key = pathArray[0];
  const arrayProps = extractTextAndNumberWithinBrackets(key);

  if (arrayProps) {
    const [propName, propIndex] = arrayProps;

    if (pathArray.length === 1) return obj?.[propName]?.[propIndex];

    if (obj?.[propName] && typeof obj[propName] === 'object')
      return getObjectValue(obj[propName]?.[propIndex], pathArray.slice(1).join('.'));

    return undefined;
  }

  if (pathArray.length === 1) return obj?.[key];

  if (obj?.[key] && typeof obj[key] === 'object') return getObjectValue(obj[key], pathArray.slice(1).join('.'));

  return undefined;
};

const getTouchedAndErrorFields = (touched, errors) => {
  const touchedFields = Object.entries(touched).reduce(
    (acc, [filedName, isTouched]) => (isTouched ? [...acc, filedName] : acc),
    [],
  );

  return touchedFields.filter(touchedFieldName => getObjectValue(errors, touchedFieldName));
};

export default {
  getParameterByName,
  downloadFile,
  parseLetters,
  parseName,
  routeParams,
  toBase64,
  transformListings,
  createObject,
  findArrayValueChanges,
  loadScript,
  transformCoords,
  insertSpaceBeforeCapitalLetter,
  preventEventBubling,
  copyToClipboard,
  normalizeUrlName,
  convertPermissionsToObject,
  transformFromArrayOfObjectsToArrayOfStrings,
  getIconBasedOnType,
  formatDecimalByIndianLocalization,
  formatDecimalByAmericanLocalization,
  removeNullProperties,
  handleOnClickEvent,
  errorHandler,
  accessObjectNestedValuesByStringPath,
  getTouchedAndErrorFields,
};
