/* eslint-disable jsx-a11y/no-autofocus */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { Field } from 'react-final-form';
import { SelectContext } from '../..';
import TagButton from '../../../Buttons/TagButton';
import InputField from '../../../InputField';
import './styles.scss';

const SelectInputTrigger = ({
  TriggerComponent = undefined,
  placeholder,
  autoFocus,
  disableToggleView,
  inputFieldName,
  wrapWithField = false,
}) => {
  const selectedContext = useContext(SelectContext);
  const {
    value,
    multipleSelection,
    preview,
    toggleView,
    filterProps,
    supportKeyboardNavigation,
    removeFromMultipleSelection,
  } = selectedContext;

  const renderValues = useCallback(() => {
    if (!multipleSelection) {
      return TriggerComponent ? <TriggerComponent {...value} /> : value?.label;
    }
    return value.map(val => (
      <TagButton
        text={TriggerComponent ? <TriggerComponent {...val} /> : val?.label}
        key={val.id}
        preview={preview}
        onIconClick={e => {
          removeFromMultipleSelection(val, e);
        }}
      />
    ));
  }, [multipleSelection, value, TriggerComponent, preview, removeFromMultipleSelection]);

  const baseClass = 'icyc-select-input-trigger';

  const noValue = useMemo(() => {
    if (multipleSelection && value.length !== 0) {
      return true;
    }
    if (JSON.stringify(value) === '{}') {
      return true;
    }
  }, [value, multipleSelection]);

  const contentClasses = classNames([
    `${baseClass}__content`,
    `${baseClass}__content--${multipleSelection ? 'multiple' : 'single'}`,
  ]);

  return (
    <div className={baseClass} onClick={!disableToggleView && toggleView}>
      {multipleSelection && noValue && <div className={contentClasses}>{renderValues()}</div>}
      {wrapWithField ? (
        <Field
          component={InputField}
          name={inputFieldName}
          autoFocus={autoFocus}
          onKeyDown={supportKeyboardNavigation}
          placeholder={!preview && placeholder}
        />
      ) : (
        <input
          data-lpignore="true"
          {...filterProps.inputProps}
          autoFocus={autoFocus}
          onKeyDown={supportKeyboardNavigation}
          placeholder={!preview && placeholder}
        />
      )}
    </div>
  );
};

SelectInputTrigger.propTypes = {
  placeholder: PropTypes.string,
  TriggerComponent: PropTypes.any,
  autoFocus: PropTypes.bool,
  disableToggleView: PropTypes.bool,
  inputFieldName: PropTypes.string,
  wrapWithField: PropTypes.bool,
};
SelectInputTrigger.defaultProps = {
  placeholder: '',
  disableToggleView: false,
  autoFocus: false,
  TriggerComponent: undefined,
  inputFieldName: '',
  wrapWithField: false,
};
export default SelectInputTrigger;
